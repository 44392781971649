export default {
  'activity.活动': 'Atividade',
  'activity.立即参与': 'Participe Agora',
  'activity.活动时间': 'Tempo da Atividade',
  'activity.活动未开启': 'Atividade Não Iniciada',

  'activity.activity1.标题': 'Oferta Especial para Novos Usuários',
  'activity.activity1.描述': 'Até',
  'activity.activity1.全部': 'Oferecido',
  'activity.activity1.活动描述': 'Recarregue e ganhe até {rate}%',

  'activity.activity2.活动描述': 'Torne-se um agente! Ganhe milhões por mês!',

  'activity.activity3.标题': 'Check-in Diário',
  'activity.activity3.描述': 'Receba o que você sacar',
  'activity.activity3.活动描述': 'Presentes contínuos para check-in diário',

  'activity.activity4.标题': 'Seja VIP e Ganhe Grandes Prêmios',
  'activity.activity4.活动描述': 'VIPs recebem grandes recompensas de milhões',

  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': 'Moedas Vinculadas ao Banco',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': 'Moedas de Registro',
  'activity.coinname.12006': 'Moedas TG',
  'activity.coinname.12007': 'Moedas de Conquista',
  'activity.coinname.13001': 'Novo Recrutamento de Agente',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': 'Sorteio',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': 'Moedas de Check-in',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': 'Moedas de Evento de Fuka',
  'activity.coinname.90001': 'Moedas de Check-in Gratuito',
  'activity.coinname.90002': 'Moedas de Bônus de Recarga',
  'activity.coinname.90003': 'Recompensas do Jackpot',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': 'Oferta Especial para Novos Usuários',
  'activity.coinname.20001': 'Retorno de Usuários Antigos',
  'activity.coinname.20002': 'Recompensa de Atualização de Versão',
  'activity.coinname.13002': 'Comissão da Agência',
  'activity.coinname.13003': 'moedas de reembolso VIP',
  'activity.coinname.chips': 'Saldo',
  'activity.coinname.rechargeChips': 'Saldo de Recarga',
}
