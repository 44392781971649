export default {
    'login.banner.text1': 'Chào mừng bạn gia nhập',
    'login.banner.text2': '7WIN.GAME',
    'login.banner.text3': 'Đăng nhập để bắt đầu hành trình chơi Game của bạn',

    'login.login': 'Đăng nhập',
    'login.telnumber': 'Số điện thoại',
    'login.password': 'Mật khẩu',
    'login.button': 'Đăng nhập',
    'login.forgot': 'Quên mật khẩu?',
    'login.register.text': 'Chưa có tài khoản?',
    'login.register': 'Đăng ký ngay',
    'login.toast.请输入正确的手机号码': 'Nhập đúng số điện thoại',
    'login.toast.请输入密码': 'Nhập mật khẩu',
    'login.toast.密码不正确或者用户不存在': 'Mật khẩu không chính xác hoặc người dùng không tồn tại',

    'register.register': 'Đăng ký',
    'register.telnumber': 'Số điện thoại',
    'register.password': 'Mật khẩu',
    'register.confirmpassword': 'Xác nhận mật khẩu',
    'register.agree': 'Tôi đồng ý',
    'register.agreement': 'Thỏa thuận người dùng',
    'register.sendcode': 'Gửi mã xác minh',
    'register.haveaccount': ' Đã có tài khoản?',
    'register.login': 'Đăng nhập ngay',
    'register.code': 'Mã xác minh',
    'register.nocode': 'chưa nhận được mã xác minh',
    'register.signin': 'Đăng nhập',
    'register.toast.验证码已发送': 'Mã xác minh đã gửi rồi',
    'register.toast.请同意用户协议': 'Hãy đồng ý với thỏa thuận người dùng',
    'register.toast.请填写完整信息': 'Hãy điền đầy đủ thông tin',
    'register.toast.请输入正确的手机号': 'Nhập đúng số điện thoại',
    'register.toast.密码不能少于6位': 'Mật khẩu không được ít hơn 6 ký tự',
    'register.toast.两次密码不一致': 'Hai mật khẩu không nhất quán',

    'find.forgot': 'Quên mật khẩu',
    'find.telnumber': 'Số điện thoại',
    'find.sendcode': 'Gửi mã xác minh',
    'find.haveaccount': ' Đã có tài khoản?',
    'find.login': 'Đăng nhập ngay',
    'find.password': 'Mật khẩu',
    'find.confirmpassword': 'Xác minh mật khẩu',
    'find.resetpassword': 'Đặt lại mật khẩu',
    'find.code': 'Mã xác minh',
    'find.nocode': 'Chưa nhận được mã xác minh',
    'find.toast.请输入正确的手机号码': 'Nhập đúng số điện thoại',
    'find.toast.验证码已发送': 'Mã xác minh đã gửi rồi',
    'find.toast.该号码未注册': 'Số điện thoại này chưa đăng ký',
    'find.toast.频繁发送短信': 'Gửi tin nhắn nhiều lần ',
    'find.toast.密码不能少于6位': 'Mật khẩu không được ít hơn 6 ký tự',
    'find.toast.两次密码不一致': 'Hai mật khẩu không nhất quán',
    'find.toast.请输入验证码': 'Nhập mã xác minh',
    'find.toast.密码重置成功': 'Đặt lại mật khẩu thành công',
    'find.toast.验证码不正确': 'Mã xác minh không chính xác',

    'agreement.title': 'Thỏa Thuận Người Dùng',
    'agreement.text': `
        Định Nghĩa
        7WIN.GAME được gọi là 'chúng tôi' hoặc 'là chúng tôi'
        Người chơi được gọi là "bạn" hoặc 'Người chơi'
        'Trang web' nghĩa là người chơi 7WIN.GAME khai thác thông qua máy tính để bàn, thiết bị di động hoặc các nền tảng khác
        1. Tổng Quan
        1.1. Thỏa thuận người dùng này áp dụng cho việc sử dụng trò chơi có thể truy cập thông qua 7WIN.GAME.
        1.2. Thỏa thuận người dùng này có hiệu lực ngay sau khi bạn hoàn tất quá trình đăng ký, bao gồm việc đánh dấu vào chấp nhận Thỏa Thuận Người Dùng này và tạo tài khoản thành công. Bằng cách sử dụng bất kỳ phần nào của Trang web sau khi tạo tài khoản, bạn đồng ý với Thỏa Thuận Người Dùng này.
        1.3. Bạn phải đọc kỹ toàn bộ Thỏa thuận người dùng này trước khi tạo tài khoản. Nếu bạn không đồng ý với bất kỳ điều khoản nào của Thỏa thuận người dùng này, bạn không được tạo tài khoản hoặc tiếp tục sử dụng Trang web.
        1.4. Chúng tôi có quyền sửa đổi Thỏa Thuận Người Dùng này bất cứ lúc nào và không cần thông báo trước. Nếu chúng tôi thực hiện những sửa đổi như vậy, chúng tôi có thể thực hiện các bước thích hợp để thu hút sự chú ý của bạn về những thay đổi đó (chẳng hạn như qua email hoặc đặt thông báo ở vị trí nổi bật trên Trang web, cùng với Thỏa Thuận Người Dùng được sửa đổi) nhưng đó sẽ là quyết định riêng của bạn trong việc kiểm tra mọi sửa đổi, cập nhật và/hoặc sửa đổi. Việc bạn tiếp tục sử dụng các dịch vụ và Trang web 7WIN.GAME sau bất kỳ sửa đổi nào đối với Thỏa Thuận Người Dùng sẽ được coi là sự chấp nhận và đồng ý của bạn bị ràng buộc bởi những sửa đổi, cập nhật và/hoặc sửa đổi đó.
        1.5. Thỏa thuận người dùng này có thể được xuất bản bằng nhiều ngôn ngữ nhằm mục đích cung cấp thông tin và giúp người chơi dễ dàng truy cập. Phiên bản tiếng Anh là cơ sở pháp lý duy nhất cho mối quan hệ giữa bạn và chúng tôi và trong trường hợp có bất kỳ sự khác biệt nào về bản dịch dưới bất kỳ hình thức nào thì phiên bản tiếng Anh của Thỏa thuận người dùng này sẽ được ưu tiên áp dụng.
        2. Tuyên Bố Ràng Buộc
        2.1. Bằng việc đồng ý bị ràng buộc bởi Thỏa thuận người dùng này, bạn cũng đồng ý bị ràng buộc bởi 7WIN.GAME Quy tắc và Chính sách quyền riêng tư được kết hợp bằng cách tham chiếu vào Thỏa Thuận Người Dùng này. Trong trường hợp có bất kỳ sự mâu thuẫn nào, Thỏa Thuận Người Dùng này sẽ được ưu tiên áp dụng. Bạn tuyên bố và đảm bảo rằng:
        2.1.1. Bạn trên (a) 18 tuổi hoặc (b) độ tuổi hợp pháp khác hoặc độ tuổi trưởng thành được xác định bởi bất kỳ luật nào áp dụng cho bạn, bất kỳ độ tuổi nào lớn hơn;
        2.1.2. Bạn có đầy đủ năng lực để ký kết thỏa thuận ràng buộc về mặt pháp lý với chúng tôi và bạn không bị hạn chế bởi bất kỳ hình thức năng lực pháp lý hạn chế nào;
        2.1.3. Tất cả thông tin mà bạn cung cấp cho chúng tôi trong thời hạn hiệu lực của thỏa thuận này là đúng, đầy đủ, chính xác và bạn phải thông báo ngay cho chúng tôi về bất kỳ thay đổi nào đối với thông tin đó;
        2.1.4. Bạn hoàn toàn chịu trách nhiệm báo cáo và hạch toán mọi khoản thuế áp dụng cho Bạn theo luật liên quan đối với mọi khoản tiền thắng cược mà Bạn nhận được từ chúng tôi;
        2.1.5. Bạn hiểu rằng khi sử dụng dịch vụ của chúng tôi, bạn có nguy cơ mất tiền gửi vào Tài khoản Thành viên của mình và chấp nhận rằng bạn hoàn toàn chịu trách nhiệm về bất kỳ tổn thất nào như vậy;
        2.1.6. Bạn được phép sử dụng các dịch vụ sòng bạc trực tuyến trong khu vực pháp lý nơi bạn sinh sống;
        2.1.7. Liên quan đến việc gửi và rút tiền vào và từ Tài khoản Thành viên của bạn, bạn chỉ được sử dụng Tiền điện tử hợp lệ và thuộc về bạn một cách hợp pháp;
        2.1.8. Bạn hiểu rằng giá trị của Tiền điện tử có thể thay đổi đáng kể tùy thuộc vào giá trị thị trường;
        2.1.9. Phần mềm máy tính, đồ họa máy tính, Trang web và giao diện người dùng mà chúng tôi cung cấp cho bạn thuộc sở hữu của 7WIN.GAME hoặc các cộng sự của nó và được bảo vệ bởi luật bản quyền. Bạn chỉ có thể sử dụng phần mềm cho mục đích sử dụng cá nhân, giải trí của riêng mình theo tất cả các quy tắc, Thỏa thuận người dùng mà chúng tôi đã thiết lập và phù hợp với tất cả các luật, quy tắc và quy định hiện hành;
        2.1.10. Bạn hiểu rằng Tiền điện tử không được coi là tiền tệ hợp pháp hoặc tiền đấu thầu và do đó trên Trang web, chúng được coi là tiền ảo không có giá trị nội tại.
        2.1.11. Bạn khẳng định rằng bạn không phải là cán bộ, giám đốc, nhân viên, nhà tư vấn hoặc đại lý của 7WIN.GAME hoặc làm việc cho bất kỳ công ty nào liên quan đến 7WIN.GAME, hoặc người thân hoặc vợ/chồng của bất kỳ ai nói trên;
        2.1.12. Bạn không được chẩn đoán hoặc phân loại là người nghiện cờ bạc hoặc có vấn đề. Chúng tôi không chịu trách nhiệm nếu vấn đề cờ bạc như vậy phát sinh trong khi sử dụng dịch vụ của chúng tôi nhưng sẽ cố gắng cung cấp hỗ trợ liên quan. Chúng tôi có quyền thực hiện thời gian tạm dừng nếu chúng tôi tin rằng những hành động đó sẽ mang lại lợi ích.
        2.1.13. Bạn chấp nhận và thừa nhận rằng chúng tôi có quyền phát hiện và ngăn chặn việc sử dụng các kỹ thuật bị cấm, bao gồm nhưng không giới hạn ở phát hiện giao dịch gian lận, đăng ký và đăng ký tự động, kỹ thuật chơi trò chơi và chụp ảnh màn hình. Các bước này có thể bao gồm nhưng không giới hạn ở việc kiểm tra thuộc tính thiết bị của Người chơi, phát hiện vị trí địa lý và che giấu IP, giao dịch và phân tích blockchain;
        2.1.14. Bạn chấp nhận quyền của chúng tôi trong việc chấm dứt và/hoặc thay đổi bất kỳ trò chơi hoặc sự kiện nào được cung cấp trên Trang web cũng như từ chối và/hoặc giới hạn việc đặt cược.
        2.1.15. Bạn chấp nhận rằng chúng tôi có quyền cấm/chặn nhiều tài khoản và tự do kiểm soát tài sản trong các tài khoản đó.
        2.1.16. Bạn biết về những lỗi có thể xảy ra hoặc sự chưa hoàn thiện trong phần mềm, bạn đồng ý không trục lợi từ chúng. Hơn nữa, bạn đồng ý báo cáo ngay lập tức mọi lỗi hoặc sự không đầy đủ cho 7WIN.GAME. Nếu bạn không thực hiện các nghĩa vụ nêu trong điều khoản này, 7WIN.GAME có quyền được bồi thường toàn bộ mọi chi phí liên quan đến lỗi hoặc sự không đầy đủ, bao gồm mọi chi phí phát sinh liên quan đến lỗi/sự không đầy đủ tương ứng và thông báo không thành công của người dùng.
        2.1.17. Bạn biết rằng 7WIN.GAME có quyền thực hiện quy trình xác minh “KYC” (Biết khách hàng của bạn). Quyền truy cập vào tài khoản người dùng của bạn có thể bị chặn hoặc đóng nếu chúng tôi xác định rằng bạn đã cung cấp thông tin sai lệch hoặc gây hiểu nhầm.
        2.2. Chúng tôi có quyền tuyên bố cược vô hiệu một phần hoặc toàn bộ nếu 7WIN.GAME, theo quyết định riêng của mình, cho rằng hiển nhiên đối với bất kỳ trường hợp nào sau đây đã xảy ra:
        2.2.1. Bạn hoặc những người liên quan đến bạn có thể ảnh hưởng trực tiếp hoặc gián tiếp đến kết quả của một sự kiện để đạt được lợi ích bất hợp pháp.
        2.2.2. Bạn và/hoặc những người liên quan đến bạn đang trực tiếp hoặc gián tiếp né tránh các quy định của 7WIN.GAME.
        2.2.3. Kết quả của một sự kiện đã bị ảnh hưởng trực tiếp hoặc gián tiếp bởi hoạt động tội phạm.
        2.2.4. Các cược đã được đặt mà lẽ ra không được chấp nhận, nhưng lại được chấp nhận trong thời gian trang web bị ảnh hưởng bởi sự cố kỹ thuật.
        2.2.5. Do lỗi, chẳng hạn như sai sót, lỗ hổng bảo mật, lỗi kỹ thuật, trường hợp bất khả kháng hoặc lý do khác, cược đã được đưa ra, đặt và/hoặc được chấp nhận do lỗi này.
        2.2.6. Nếu phí gửi tiền của người chơi quá thấp và bị blockchain hoặc trang web tương tự gắn cờ là “không đủ phí để chuyển tiếp” thì 7WIN.GAME có quyền tịch thu số tiền thắng cược nếu 7WIN.GAME theo quyết định riêng của họ cho rằng giao dịch và hành vi của người chơi là gian lận trong thiên nhiên.
        3. Lãnh Thổ Hạn Chế
        3.1. Các lãnh thổ bị liệt vào danh sách đen: Trung Quốc, Hà Lan, Quần đảo Caribbean thuộc Hà Lan, Hungary, Úc, Ontario (Canada), Curacao, Pháp, Hoa Kỳ và/hoặc bất kỳ khu vực nào khác bị hạn chế bởi luật pháp quốc gia hoặc tiểu bang. Lưu ý rằng nghiêm cấm chơi trên các trò chơi 7WIN.GAME ở các quốc gia nằm trong danh sách đen được đề cập ở trên. Dữ liệu cá nhân của bạn nhằm mục đích thực hiện nhiệm vụ của họ và cung cấp cho bạn sự hỗ trợ và dịch vụ tốt nhất có thể. Bạn đồng ý với những tiết lộ như vậy.
        4. Quy tắc cá cược chung
        4.1. Việc đặt cược chỉ có thể được đặt bởi chủ tài khoản đã đăng ký.
        4.2. Đặt cược chỉ có thể được đặt qua internet.
        4.3. Bạn chỉ có thể đặt cược nếu bạn có đủ số dư trong tài khoản với 7WIN.GAME.
        4.4. Việc đặt cược, sau khi kết thúc, sẽ được điều chỉnh bởi phiên bản Thỏa thuận người dùng hợp lệ và có sẵn trên Trang web tại thời điểm đặt cược được chấp nhận.
        4.5. Bất kỳ khoản thanh toán nào của cược thắng sẽ được ghi có vào tài khoản của bạn, bao gồm tiền đặt cược nhân với tỷ lệ cược được đặt.
        4.6. 7WIN.GAME có quyền điều chỉnh khoản thanh toán đặt cược được ghi có vào tài khoản 7WIN.GAME nếu 7WIN.GAME theo quyết định riêng của mình xác định rằng khoản thanh toán đó đã được ghi có do nhầm lẫn.
        4.7. Đặt cược đã được đặt và chấp nhận, bạn không thể sửa đổi, thu hồi hoặc hủy bỏ.
        4.8. Danh sách tất cả các cược, trạng thái và chi tiết của chúng có sẵn cho bạn trên Trang web.
        4.9. Khi bạn đặt cược, bạn thừa nhận rằng bạn đã đọc và hiểu đầy đủ tất cả Thỏa thuận người dùng này liên quan đến đặt cược như đã nêu trên Trang web.
        4.10. 7WIN.GAME quản lý tài khoản của bạn và tính toán số tiền khả dụng, số tiền chờ xử lý, số tiền cá cược cũng như số tiền thắng cược. Trừ khi được chứng minh khác, số tiền này được coi là cuối cùng và được coi là chính xác.
        4.11. Bạn hoàn toàn chịu trách nhiệm về số tiền cược đã đặt.
        4.12. Tiền thắng sẽ được trả vào tài khoản của bạn sau khi kết quả cuối cùng được xác nhận.
        5. Tiền Thưởng và Khuyến Mãi
        5.1. 7WIN.GAME có quyền hủy bất kỳ chương trình khuyến mãi, tiền thưởng hoặc tiền thưởng nào (bao gồm nhưng không giới hạn ở phần thưởng nạp tiền, mời bạn bè thưởng tiền thưởng và chương trình khách hàng thân thiết) có hiệu lực ngay lập tức nếu chúng tôi tin rằng phần thưởng đã được thiết lập không chính xác hoặc đang bị bị lạm dụng và nếu khoản tiền thưởng nói trên đã được thanh toán, chúng tôi có quyền từ chối mọi yêu cầu Rút tiền và khấu trừ số tiền đó khỏi tài khoản của bạn. Việc tiền thưởng có được coi là thiết lập không chính xác hoặc bị lạm dụng hay không sẽ chỉ được xác định bởi 7WIN.GAME.
        5.2. Nếu bạn sử dụng Tiền thưởng gửi tiền, việc rút tiền gửi ban đầu của bạn sẽ không được chấp nhận trước khi bạn đạt được các yêu cầu được quy định trong Thỏa thuận người dùng về Tiền thưởng gửi tiền.
        5.3. Khi bất kỳ điều khoản nào của ưu đãi hoặc khuyến mãi bị vi phạm hoặc có bất kỳ bằng chứng nào về một loạt cược do khách hàng hoặc nhóm khách hàng đặt do tiền thưởng tiền gửi, thanh toán nâng cao, cược miễn phí, cược không rủi ro hoặc bất kỳ ưu đãi khuyến mại nào khác mang lại lợi nhuận được đảm bảo cho khách hàng bất kể kết quả như thế nào, dù là riêng lẻ hay là một phần của nhóm, 7WIN.GAME có quyền lấy lại yếu tố tiền thưởng của các ưu đãi đó và theo quyết định tuyệt đối của họ hoặc giải quyết các cược theo tỷ lệ chính xác, làm mất hiệu lực tiền thưởng cược miễn phí và cược không có rủi ro hoặc vô hiệu bất kỳ cược nào được tài trợ bởi tiền thưởng tiền gửi. Ngoài ra, 7WIN.GAME có quyền thu phí quản lý đối với khách hàng lên đến giá trị tiền thưởng tiền gửi, tiền thưởng đặt cược miễn phí, đặt cược không rủi ro hoặc khoản thanh toán bổ sung để trang trải chi phí hành chính. Chúng tôi còn có quyền yêu cầu bất kỳ khách hàng nào cung cấp đầy đủ tài liệu để chúng tôi hài lòng theo quyết định riêng của chúng tôi về danh tính của khách hàng trước khi chúng tôi ghi có bất kỳ phần thưởng, đặt cược miễn phí, đặt cược không rủi ro hoặc ưu đãi nào vào tài khoản của họ.
        5.4. Tất cả ưu đãi 7WIN.GAME đều dành cho người chơi giải trí và 7WIN.GAME có thể tùy ý giới hạn khả năng đủ điều kiện của khách hàng để tham gia tất cả hoặc một phần của bất kỳ chương trình khuyến mãi nào.
        5.5. 7WIN.GAME có quyền sửa đổi, hủy bỏ, thu hồi hoặc từ chối bất kỳ chương trình khuyến mãi nào theo quyết định riêng của mình.
        5.6. Tiền thưởng chỉ có thể được nhận một lần cho mỗi người/tài khoản, gia đình, hộ gia đình, địa chỉ, địa chỉ email, địa chỉ IP và môi trường nơi máy tính được chia sẻ (trường đại học, hội huynh đệ, trường học, thư viện công cộng, nơi làm việc, v. v.). Nhà điều hành có quyền đóng tài khoản của bạn và tịch thu mọi khoản tiền hiện có nếu tìm thấy bằng chứng lạm dụng/lừa đảo.
        5.7. Bạn thừa nhận và hiểu rằng Thỏa thuận người dùng riêng biệt tồn tại liên quan đến các chương trình khuyến mãi, tiền thưởng và ưu đãi đặc biệt, đồng thời bổ sung cho Thỏa thuận người dùng này. Thỏa thuận người dùng này được quy định trong trang nội dung tương ứng trên trang web này hoặc được cung cấp cho cá nhân bạn, tùy từng trường hợp. Trong trường hợp có mâu thuẫn giữa các quy định của chương trình khuyến mãi, tiền thưởng và ưu đãi đặc biệt đó với các quy định của Thỏa thuận người dùng này thì các quy định về khuyến mãi, tiền thưởng và ưu đãi đặc biệt đó sẽ được ưu tiên áp dụng.
        5.8. Chúng tôi có thể yêu cầu bạn đặt cược một số tiền nhất định trong số tiền gửi của mình trước khi bạn có thể đặt cược bằng bất kỳ khoản tiền miễn phí/tiền thưởng nào mà chúng tôi ghi có vào tài khoản của bạn.
        5.9. Bạn chấp nhận rằng một số chương trình khuyến mãi nhất định có thể phải tuân theo các hạn chế và/hoặc yêu cầu Rút tiền cần phải được đáp ứng trước khi có thể rút số tiền được ghi có theo chương trình khuyến mãi. Các điều khoản như vậy sẽ được công bố hợp lệ và được cung cấp như một phần của chương trình khuyến mãi. Nếu bạn chọn Rút tiền trước khi đáp ứng các yêu cầu đặt cược hiện hành, chúng tôi sẽ khấu trừ toàn bộ số tiền thưởng cũng như mọi khoản tiền thắng liên quan đến việc sử dụng số tiền thưởng trước khi phê duyệt bất kỳ khoản Rút tiền nào.
        6. Hỗ Trợ Trực Tuyến
        6.1. Là một phần trong việc bạn sử dụng Trang web, chúng tôi có thể cung cấp cho bạn tiện ích trò chuyện trực tiếp do chúng tôi kiểm duyệt và chịu sự kiểm soát. Chúng tôi có quyền xem lại cuộc trò chuyện và lưu giữ hồ sơ tất cả các tuyên bố được đưa ra tại cơ sở. Việc sử dụng tiện ích trò chuyện của bạn phải nhằm mục đích giải trí và giao lưu.
        6.2. Chúng tôi có quyền xóa chức năng phòng trò chuyện hoặc chấm dứt ngay Tài khoản Thành viên của bạn và hoàn trả số dư tài khoản của bạn nếu bạn:
        (a) đưa ra bất kỳ tuyên bố nào mang tính khiêu dâm hoặc xúc phạm nặng nề, bao gồm các biểu hiện cố chấp, phân biệt chủng tộc, hận thù hoặc thô tục;
        (b) đưa ra những tuyên bố lăng mạ, phỉ báng hoặc quấy rối hoặc xúc phạm;
        (c) sử dụng tiện ích trò chuyện để quảng cáo, quảng bá hoặc liên quan đến bất kỳ tổ chức trực tuyến nào khác;
        (d) đưa ra các tuyên bố về 7WIN.GAME hoặc bất kỳ trang Internet nào khác được kết nối với Trang web là không đúng sự thật và/hoặc độc hại và/hoặc gây tổn hại cho 7WIN.GAME;
        (e) sử dụng tiện ích trò chuyện để thông đồng, tham gia vào hành vi trái pháp luật hoặc khuyến khích hành vi mà chúng tôi cho là không phù hợp. Mọi cuộc trò chuyện đáng ngờ sẽ được báo cáo cho cơ quan có thẩm quyền.
        6.3. Hỗ Trợ Trực Tuyến được sử dụng như một hình thức liên lạc giữa chúng tôi và bạn và không được sao chép hoặc chia sẻ với bất kỳ diễn đàn hoặc bên thứ ba nào.
        7. Giới Hạn Trách Nhiệm Pháp Lý
        7.1. Bạn vào Trang web và tự chịu rủi ro khi tham gia Trò chơi. Trang web và Trò chơi được cung cấp mà không có bất kỳ sự bảo đảm nào, dù rõ ràng hay ngụ ý.
        7.2. Không ảnh hưởng đến tính tổng quát của điều khoản trước đó, chúng tôi, các giám đốc, nhân viên, đối tác, nhà cung cấp dịch vụ của chúng tôi.
        7.3. Không đảm bảo rằng phần mềm, Trò chơi và Trang web phù hợp với mục đích của chúng.
        7.4. Không đảm bảo rằng phần mềm, Trò chơi và Trang web không có lỗi.
        7.5. Không đảm bảo rằng phần mềm, Trò chơi và Trang web sẽ có thể truy cập được mà không bị gián đoạn.
        7.6. Sẽ không chịu trách nhiệm về bất kỳ tổn thất, chi phí, phí tổn hoặc thiệt hại nào, dù trực tiếp, gián tiếp, đặc biệt, do hậu quả, ngẫu nhiên hay cách khác, phát sinh liên quan đến việc bạn sử dụng Trang web hoặc việc bạn tham gia Trò chơi.
        7.7. Bạn hiểu và thừa nhận rằng, nếu có trục trặc trong Trò chơi hoặc khả năng tương tác của nó thì mọi cược được thực hiện trong trường hợp trục trặc đó sẽ bị vô hiệu. Số tiền thu được từ một Trò chơi bị trục trặc sẽ bị coi là vô hiệu, cũng như mọi vòng trò chơi tiếp theo có số tiền nói trên, bất kể Trò chơi nào được chơi bằng số tiền đó.
        7.8. Theo đây, bạn đồng ý bồi thường đầy đủ và giữ cho chúng tôi, giám đốc, nhân viên, đối tác và nhà cung cấp dịch vụ của chúng tôi không bị tổn hại về mọi chi phí, chi phí, tổn thất, thiệt hại, khiếu nại và trách nhiệm pháp lý có thể phát sinh liên quan đến việc bạn sử dụng Trang web hoặc tham gia vào trò chơi.
        7.9. Trong phạm vi được luật pháp cho phép, trách nhiệm pháp lý tối đa của chúng tôi phát sinh từ hoặc liên quan đến việc bạn sử dụng Trang web, bất kể nguyên nhân của hành động (dù là trong hợp đồng, vi phạm bảo hành hay cách khác), sẽ không vượt quá €100.
        8. Vi Phạm, Hình Phạt và Chấm dứt
        8.1. Nếu bạn vi phạm bất kỳ điều khoản nào trong Thỏa thuận người dùng này hoặc chúng tôi có cơ sở hợp lý để nghi ngờ rằng bạn đã vi phạm chúng, chúng tôi có quyền không mở, đình chỉ hoặc đóng Tài khoản thành viên của bạn hoặc giữ lại khoản thanh toán tiền thắng cược của bạn và áp dụng điều đó tài trợ cho bất kỳ thiệt hại nào do bạn gây ra.
        8.2. Bạn thừa nhận rằng 7WIN.GAME sẽ là người đưa ra quyết định cuối cùng về việc bạn có vi phạm các quy tắc, điều khoản hoặc điều kiện của 7WIN.GAME theo cách dẫn đến việc bạn bị đình chỉ hoặc bị cấm vĩnh viễn tham gia vào trang web của chúng tôi hay không.
        9. Tự Loại Trừ
        9.1. Bằng cách yêu cầu một khoảng thời gian tự loại trừ, bạn đồng ý tuân theo các điều khoản và điều kiện dưới đây, sẽ có hiệu lực kể từ thời điểm Hỗ Trợ Viên triển khai khoảng thời gian tự loại trừ đã chọn.
        9.2. Bạn có thể tự loại trừ trong khoảng thời gian 1, 3, 6, 12 tháng hoặc vĩnh viễn. Yêu cầu tự loại trừ phải được thực hiện thông qua Hỗ Trợ Trực Tuyến.
        9.3. Khi bạn đã tự loại trừ, bạn sẽ không thể truy cập tài khoản của mình hoặc rút tiền trong thời gian này.
        9.4. Nếu bạn đã loại trừ tài khoản của mình trong khi bạn có các cược đang chờ trong tài khoản của mình, các cược đã đặt sẽ vẫn có hiệu lực và thanh toán theo kết quả chính thức.
        9.5. Khi thời gian tự loại trừ đã hết, bạn có thể rút tiền thắng cược từ các cược đủ điều kiện. 7WIN.GAME không hủy bỏ hoặc vô hiệu hóa bất kỳ cược nào đã đặt trước khi việc tự loại trừ bị ảnh hưởng.
        9.6. Một khi bạn đã tự loại trừ, bạn sẽ không thể thay đổi hoặc sửa đổi khoản thời gian thành một khoản ngắn hơn hoặc hủy bỏ quyền tự loại trừ của bạn cho đến khi khoản thời gian bạn đã chọn để tự loại trừ trôi qua.
        9.7. Xin vui lòng liên hệ với bộ phận dịch vụ khách hàng của chúng tôi nếu bạn muốn kéo dài thời gian tự loại trừ của mình.
        9.8. Khi thời gian tự loại trừ của bạn đã trôi qua, bạn có thể khôi phục tài khoản bằng liên hệ với trong game CSKH của 7WIN.GAME.
        9.9. Bằng cách tự loại trừ, bạn đồng ý rằng:
        oa) Bạn sẽ không tạo tài khoản khác trong thời gian này.
        ob) Bạn sẽ không gửi tiền hoặc cố gắng gửi tiền vào tài khoản 7WIN.GAME.
        oc) Bạn sẽ không đặt cược trên trang web trong thời gian này.
        od) Đây là hành động tự nguyện do chính bạn đề xướng và 7WIN.GAME sẽ không chịu trách nhiệm về bất kỳ tổn thất nào mà bạn có thể phải chịu trong thời gian tự loại trừ dưới bất kỳ hình thức nào.


        Chính Sách Bảo Mật
        Bằng cách này, bạn thừa nhận và chấp nhận rằng nếu chúng tôi thấy cần thiết, chúng tôi có thể thu thập và sử dụng dữ liệu cá nhân của bạn để cho phép bạn truy cập và sử dụng Trang web cũng như cho phép bạn tham gia Trò chơi.
        Chúng tôi xác nhận rằng khi thu thập thông tin cá nhân của bạn như đã nêu trong điều khoản trước đó, chúng tôi bị ràng buộc bởi Đạo luật bảo vệ dữ liệu. Chúng tôi sẽ bảo vệ thông tin cá nhân của bạn và tôn trọng quyền riêng tư của bạn theo các thông lệ kinh doanh tốt nhất và luật hiện hành.
        Chúng tôi sẽ sử dụng dữ liệu cá nhân của bạn để cho phép bạn tham gia Trò chơi và thực hiện các hoạt động liên quan đến việc bạn tham gia Trò chơi. Chúng tôi cũng có thể sử dụng dữ liệu cá nhân của bạn để thông báo cho bạn về những thay đổi, dịch vụ mới và chương trình khuyến mãi mà chúng tôi cho rằng bạn có thể thấy thú vị. Nếu bạn không muốn nhận thư tiếp thị trực tiếp như vậy, bạn có thể chọn không tham gia dịch vụ.
        Dữ liệu cá nhân của bạn sẽ không được tiết lộ cho bên thứ ba, trừ khi pháp luật yêu cầu. Vì 7WIN.GAME đối tác kinh doanh hoặc nhà cung cấp hoặc nhà cung cấp dịch vụ có thể chịu trách nhiệm về một số phần nhất định trong chức năng hoặc hoạt động chung của Trang web nên dữ liệu cá nhân có thể được tiết lộ cho họ. Nhân viên của 7WIN.GAME có quyền truy cập vào dữ liệu cá nhân của bạn nhằm mục đích thực hiện nhiệm vụ của họ và cung cấp cho bạn sự hỗ trợ và dịch vụ tốt nhất có thể. Bạn đồng ý với những tiết lộ như vậy.
        Chúng tôi sẽ giữ tất cả thông tin được cung cấp dưới dạng dữ liệu cá nhân. Bạn có quyền truy cập dữ liệu cá nhân do chúng tôi nắm giữ về bạn. Sẽ không có dữ liệu nào bị hủy trừ khi luật pháp yêu cầu hoặc trừ khi thông tin được lưu giữ không còn cần thiết để lưu giữ vì mục đích của mối quan hệ.
        Để giúp việc truy cập Trang web của bạn thân thiện hơn với người dùng, để theo dõi các lượt truy cập vào Trang web và cải thiện dịch vụ, chúng tôi thu thập một phần thông tin nhỏ được gửi từ trình duyệt của bạn, được gọi là cookie. Bạn có thể, nếu muốn, tắt chế độ thu thập cookie. Tuy nhiên, bạn phải lưu ý rằng việc tắt cookie có thể hạn chế nghiêm trọng hoặc cản trở hoàn toàn việc bạn sử dụng Trang web.
        Chính Sách Cookies
        1. Cookie là gì?
        Cookie là một phần thông tin dưới dạng một tệp văn bản rất nhỏ được đặt trên máy tính của người dùng internet. Nó được tạo bởi một máy chủ trang web (về cơ bản là máy tính vận hành trang web) và có thể được máy chủ đó sử dụng bất cứ khi nào người dùng truy cập trang web. Cookie có thể được coi là thẻ nhận dạng của người dùng internet, thẻ này cho trang web biết khi người dùng quay lại. Cookie không thể gây hại cho máy tính của bạn và chúng tôi không lưu trữ bất kỳ thông tin nhận dạng cá nhân nào về bạn trên bất kỳ cookie nào của chúng tôi.
        2.Tại sao chúng tôi sử dụng cookie trên 7WIN.GAME?
        7WIN.GAME sử dụng hai loại cookie: cookie do chúng tôi đặt và cookie do bên thứ ba đặt (tức là các trang web hoặc dịch vụ khác). Cookie 7WIN.GAME cho phép chúng tôi duy trì trạng thái đăng nhập vào tài khoản của bạn trong suốt quá trình bạn truy cập và điều chỉnh thông tin hiển thị trên trang web theo sở thích của bạn.
        3.Chúng tôi sử dụng cookie nào trên 7WIN.GAME?
        Dưới đây là danh sách các cookie chính do 7WIN.GAME đặt và mục đích sử dụng của từng cookie:
        _fp - lưu trữ dấu vân tay của trình duyệt. Tuổi thọ: mãi mãi.
        _t - lưu trữ dấu thời gian khi người dùng truy cập trang web lần đầu trong phiên duyệt hiện tại. Cần thiết cho thống kê lượt truy cập duy nhất. Tuổi thọ: phiên duyệt.
        _r - lưu trữ liên kết giới thiệu http cho phiên duyệt hiện tại. Cần thiết để theo dõi các nguồn lưu lượng truy cập bên ngoài. Tuổi thọ: phiên duyệt.
        _c - lưu trữ mã định danh của chương trình đại lý. Cần thiết cho thống kê đại lý. Tuổi thọ: mãi mãi.
        Cookie do bên thứ ba đặt cho tên miền wildcard: *.7WIN.GAME
        Google phân tích: _ga, _gat, _gid
        Zendesk: __ zlcmid
        Cloudflare: __ cfuid
        Xin lưu ý rằng một số trình duyệt (ví dụ: Chrome trên mac) giữ cho các quy trình nền chạy ngay cả khi không có tab nào được mở do phiên này cookie có thể được đặt giữa các phiên.
        Ngoài ra còn có các cookie do tập lệnh của bên thứ ba đặt cho tên miền của họ.
        4. Làm cách nào tôi có thể quản lý cookie của mình trên 7WIN.GAME?
        Nếu bạn muốn ngừng chấp nhận cookie, bạn có thể làm như vậy thông qua tùy chọn Thiếp Lập Quyền Riêng Tư trong trình duyệt của mình.
        5.Chính Sách Bảo Vệ Dữ Liệu Cá Nhân
        Sứ mệnh của 7WIN.GAME là giữ an toàn cho Dữ Liệu của bạn và vì vấn đề này, chúng tôi bảo vệ dữ liệu của bạn theo nhiều cách khác nhau. Chúng tôi cung cấp cho khách hàng các tiêu chuẩn bảo mật cao, chẳng hạn như mã hóa dữ liệu chuyển động qua mạng công cộng, mã hóa dữ liệu trong cơ sở dữ liệu, tiêu chuẩn thanh tra, giảm nhẹ Từ Chối Dịch Vụ Phân Tán và Trò chuyện trực tuyến luôn sẵn sàng.
        6.Chính Sách Bảo Vệ Máy Chủ
        Tất cả các máy chủ đều được mã hóa đầy đủ;
        Tất cả các bản sao lưu đều có mã hóa;
        Tường lửa, Truy cập VPN;
        Chỉ được phép truy cập vào máy chủ qua VPN;
        Tất cả các dịch vụ http/s đều hoạt động trên Cloudflare;
        Kết nối với các điểm giao qua VPN;
        Đường hầm chuyển tiếp cổng SSH;
        Các dịch vụ chỉ được phép qua VPN;
        Máy chủ có tường lửa và chỉ cho phép cổng SSH;
        Cảnh báo về các dịch vụ quan trọng.
        Thông Báo Vi Phạm Dữ Liệu
        Khi 7WIN.GAME được biết về hành vi vi phạm dữ liệu cá nhân, chúng tôi sẽ thông báo cho những người dùng có liên quan theo khung thời gian của GDPR.
        7.Chuyển Dữ Liệu Quốc Tế
        Chúng tôi chỉ tiết lộ dữ liệu cá nhân cho các bên thứ ba khi cần thiết để cung cấp dịch vụ chất lượng cao hoặc để đáp ứng các yêu cầu hợp pháp từ cơ quan chức năng.
        Chúng tôi chia sẻ dữ liệu sau với hệ thống của bên thứ ba:
        Zendesk Inc. – thông tin tên đăng nhập và email sẽ được chuyển nếu người dùng gửi tin nhắn đến trò chuyện trực tiếp hoặc gửi email đến hộp thư hỗ trợ.
        Mặc dù chúng tôi cố gắng làm tốt nhất có thể nhưng vấn đề đôi lúc vẫn xảy ra. Đội ngũ của chúng tôi sẽ làm mọi thứ có thể để giải quyết vấn đề của bạn càng sớm càng tốt. Để hỗ trợ bạn nhanh hơn, Bạn có thể tham gia cùng chúng tôi bằng cách nhấp vào nút bên trên để vào nhóm telegram.
        Nếu xảy ra lỗi, vui lòng cung cấp thông tin sau:
        oTên Đăng Nhập
        oNgày và giờ xảy ra sự cố
        Mã Trò Chơi hoặc tên bàn chơi, nếu có
        Ảnh chụp màn hình lỗi, nếu có
        Chúng tôi thực sự đánh giá cao sự giúp đỡ của bạn và thông báo lỗi bạn cung cấp vì sự báo cáo thông tin của bạn có thể giúp chúng tôi cải thiện.
        Thu Thập và Sử Dụng Dữ Liệu Cá Nhân của Bạn
        Phân Loại Dữ Liệu Thu Thập
        Dữ Liệu Cá Nhân
        Trong khi sử dụng Dịch vụ của chúng tôi, Chúng tôi có thể yêu cầu Bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định có thể được sử dụng để liên hệ hoặc nhận dạng Bạn. Thông tin nhận dạng cá nhân có thể bao gồm nhưng không giới hạn ở:
        Địa chỉ Email
        Họ và Tên
        Dữ Liệu Sử Dụng
        Dữ Liệu Sử Dụng
        Dữ liệu sử dụng được thu thập tự động khi sử dụng Dịch vụ.
        Dữ liệu sử dụng có thể bao gồm các thông tin như địa chỉ Giao Thức Internet của Thiết bị của bạn (ví dụ: địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang Dịch Vụ của chúng tôi mà Bạn truy cập, ngày và giờ Bạn truy cập, thời gian dành cho các trang đó, thiết bị duy nhất định danh và dữ liệu chẩn đoán khác.
        Khi Bạn truy cập Dịch Vụ bằng hoặc thông qua thiết bị di động, Chúng tôi có thể tự động thu thập một số thông tin nhất định, bao gồm nhưng không giới hạn ở loại thiết bị di động Bạn sử dụng, ID duy nhất của thiết bị di động của Bạn, địa chỉ IP của thiết bị di động của Bạn, hệ điều hành điện thoại di động của Bạn, loại trình duyệt Internet điện thoại di động Bạn sử dụng, số nhận dạng thiết bị duy nhất và dữ liệu chẩn đoán khác.
        Chúng tôi cũng có thể thu thập thông tin mà trình duyệt của Bạn gửi bất cứ khi nào Bạn truy cập Dịch Vụ của chúng tôi hoặc khi Bạn truy cập Dịch Vụ bằng hoặc thông qua thiết bị di động.
        Thông tin từ Dịch Vụ Mạng Xã Hội của Bên Thứ Ba
        7WIN.GAME cho phép Bạn tạo tài khoản và đăng nhập để sử dụng Dịch Vụ thông qua các Dịch Vụ Mạng Xã Hội của Bên Thứ Ba sau:
        Google
        Facebook
        Telegram
        Metamask
        8. Web 3.0
        Nếu Bạn quyết định đăng ký thông qua hoặc cấp cho chúng tôi quyền truy cập vào Dịch vụ Mạng xã hội của bên thứ ba, chúng tôi có thể thu thập dữ liệu cá nhân đã được liên kết với tài khoản Dịch vụ mạng xã hội bên thứ ba của bạn, chẳng hạn như tên, địa chỉ email của bạn, hoạt động của bạn hoặc Danh sách liên hệ của bạn được liên kết với tài khoản đó.
        Bạn cũng có thể có tùy chọn chia sẻ thông tin bổ sung với 7WIN.GAME thông qua tài khoản Dịch vụ mạng xã hội bên thứ ba của bạn. Nếu Bạn chọn cung cấp thông tin và Dữ liệu Cá nhân như vậy, trong quá trình đăng ký hoặc bằng cách khác, Bạn sẽ cấp cho 7WIN.GAME quyền sử dụng, chia sẻ và lưu trữ thông tin đó theo cách phù hợp với Chính Sách Bảo Mật này.
        Xóa Dữ Liệu Cá Nhân
        Bạn có thể yêu cầu xóa dữ liệu cá nhân của mình nếu 7WIN.GAME không còn lý do pháp lý để tiếp tục xử lý hoặc lưu trữ dữ liệu đó. Xin lưu ý rằng quyền này không được đảm bảo - theo nghĩa là 7WIN.GAME không có khả năng tuân thủ yêu cầu của bạn nếu yêu cầu đó phải tuân theo nghĩa vụ pháp lý về việc lưu trữ dữ liệu của bạn. Bạn có thể yêu cầu xóa dữ liệu cá nhân của mình bằng cách liên hệ với CSKH của 7WIN.GAME.
        Đăng Ký và Đăng Nhập
        Bạn phải ít nhất đủ 18 tuổi để đăng ký. Nếu bạn muốn thêm địa chỉ email của mình, xin vui lòng đảm bảo địa chỉ email bạn đã nhập là chính xác để sau này có thể sử dụng cho việc xác thực KYC tài khoản.
        Bạn có thể đăng nhập bất cứ lúc nào. Để tăng cường bảo mật, chúng tôi khuyên bạn nên thêm Xác thực 2 bước. Để biết thêm về trình xác thực Google authenticator.
        Nếu bạn cần thay đổi email đã đăng ký của mình, chúng tôi rất tiếc, nhưng chúng tôi không thể thay đổi thông tin này. Nếu bạn vẫn muốn thay đổi tên đăng nhập và/hoặc email đã đăng ký, chúng tôi khuyên bạn nên đóng tài khoản hiện tại và đăng ký một tài khoản mới.


        Chính Sách Bảo Mật 7WIN.GAME
        Chính Sách Bảo Mật của 7WIN.GAME Nhằm mô tả các dịch vụ 7WIN.GAME (như được định nghĩa trong Điều Khoản Sử Dụng của 7WIN.GAME) và các biện pháp bảo mật của chúng tôi hiển thị hoặc tham chiếu các nguyên tắc.
        Chính Sách Bảo Mật này quy định cách 7WIN.GAME sẽ quản lý dữ liệu cá nhân của bạn mà chúng tôi thu thập do bạn là thành viên của 7WIN.GAME, việc bạn sử dụng các trang web của chúng tôi có tại https://7WIN.GAME và bất kỳ trang hoặc trang web nào dưới thương hiệu 7WIN.GAME bao gồm điện thoại di động hoặc các ứng dụng máy tính bảng bất kỳ do chúng tôi sở hữu và/hoặc vận hành hoặc bất cứ khi nào chúng tôi giao dịch với bạn. Chúng tôi cam kết đảm bảo rằng thông tin cá nhân mà chúng tôi thu thập về bạn được bảo vệ và sử dụng, lưu trữ và tiết lộ theo Đạo luật quyền riêng tư hiện hành và Chính Sách Bảo Mật này.
        Dữ Liệu Cá Nhân Thu Thập từ bạn
        Khi bạn đăng ký tín dụng thương mại, kích hoạt dịch vụ của chúng tôi, đăng ký dịch vụ trên trang web 7WIN.GAME, kết nối với các dịch vụ của chúng tôi, liên hệ với chúng tôi (bao gồm cả mạng xã hội), tham gia khảo sát trực tuyến hoặc tương tác với 7WIN.GAME, chúng tôi có thể thu thập nhiều thông tin khác nhau, bao gồm:
        1.Thông Tin Tài Khoản. Tên đăng nhập 7WIN.GAME của bạn và các chi tiết tài khoản có liên quan, bao gồm địa chỉ email, thiết bị đã đăng ký, trạng thái tài khoản và tuổi.
        2. Thông Tin Thiết Bị. Dữ liệu mà từ đó thiết bị của bạn có thể được nhận dạng, chẳng hạn như số sê-ri thiết bị hoặc về thiết bị của bạn, chẳng hạn như loại trình duyệt.
        3. Thông Tin Liên Hệ. Dữ liệu như tên, địa chỉ email, địa chỉ thực, số điện thoại hoặc thông tin liên hệ khác.
        4. Thông Tin Thanh Toán. Dữ liệu về địa chỉ thanh toán và phương thức thanh toán của bạn, chẳng hạn như chi tiết ngân hàng, tín dụng, ghi nợ hoặc thông tin thẻ thanh toán khác.
        5. Thông Tin Phòng Chống Gian Lận. Dữ liệu dùng để giúp xác định và ngăn chặn gian lận, bao gồm độ tin cậy của thiết bị và thông tin KYC.
        6. Dữ Liệu Sử Dụng. Dữ liệu về hoạt động của bạn và việc sử dụng các dịch vụ của chúng tôi, chẳng hạn như các lần ra mắt dịch vụ, bao gồm cả lịch sử duyệt web; tương tác sản phẩm; dữ liệu sự cố, hiệu suất và dữ liệu chẩn đoán khác; và dữ liệu sử dụng khác.
        7. Dữ Liệu Giấy Tờ Tùy Thân. Ở một số khu vực pháp lý nhất định, chúng tôi có thể yêu cầu giấy tờ tùy thân do chính quyền cấp trong một số trường hợp hạn chế, kể cả khi kích hoạt dịch vụ của chúng tôi cho bạn, nhằm mục đích gia hạn tín dụng thương mại, quản lý đặt chỗ hoặc theo yêu cầu của pháp luật.
        8. Thông tin khác bạn cung cấp cho chúng tôi. Các chi tiết như nội dung liên lạc của bạn với Apple, bao gồm các tương tác với bộ phận hỗ trợ khách hàng và liên hệ thông qua các kênh mạng xã hội.
        Trong một số trường hợp, 7WIN.GAME có thể thu thập thông tin cá nhân "nhạy cảm". (bao gồm thông tin về chủng tộc và nguồn gốc quốc gia, quan điểm chính trị, tín ngưỡng tôn giáo và các tín ngưỡng khác có tính chất tương tự, công đoàn.
        tư cách thành viên và thông tin về đời sống tình dục hoặc khuynh hướng tình dục), hoặc thông tin sức khỏe và khi được pháp luật cho phép liên quan đến, cáo buộc hoặc chứng minh thông tin tội phạm.
        Sử Dụng Dữ Liệu Cá Nhân
        Chúng tôi có thể kết hợp và sử dụng thông tin chúng tôi lưu giữ về bạn (bao gồm thông tin nhận được trong và ngoài Dịch Vụ của chúng tôi) để hiểu cách bạn sử dụng và tương tác với Dịch Vụ của chúng tôi cũng như để hiểu bạn được kết nối và quan tâm đến ai trong những thứ cần cung cấp, cá nhân hóa và cải thiện dịch vụ của chúng tôi. Chúng tôi cũng có thể sử dụng thông tin chúng tôi có về bạn trên toàn cầu cho các mục đích sau:
        1. Cung cấp, duy trì, cải thiện và phát triển chức năng, nội dung và dịch vụ liên quan.
        2. Phân tích nội dung của bạn và các thông tin khác.
        3. Thực hiện các yêu cầu của bạn và sử dụng khi được bạn cho phép.
        4. Giúp kết nối các nhà quảng cáo và nhà điều hành ứng dụng/trang web để cung cấp các quảng cáo có liên quan trong ứng dụng và trang web của họ.
        5. Kết hợp và phân phát quảng cáo tiếp thị có mục tiêu (bất kể thiết bị hoặc việc sử dụng dịch vụ của chúng tôi) và cung cấp quảng cáo tiếp thị có mục tiêu dựa trên hoạt động thiết bị, sở thích ước tính và thông tin mục tiêu của bạn.
        6. Liên hệ với bạn về thông tin liên quan đến tài khoản hoặc gửi tin nhắn tiếp thị theo mong muốn của bạn.
        7. Liên kết các hoạt động của bạn trên các dịch vụ khác nhau của chúng tôi và các thiết bị khác nhau của bạn, đồng thời liên kết tất cả các tài khoản bạn có thể sử dụng trên các dịch vụ 7WIN.GAME khác nhau. Chúng tôi có thể liên kết hoạt động và tài khoản dưới một tên đăng nhập.
        8. Tiến hành hoặc hỗ trợ các hoạt động xúc tiến tiếp thị.
        9. Tiến hành nghiên cứu và hỗ trợ đổi mới.
        10. Cung cấp phân tích và báo cáo về việc sử dụng cũng như xu hướng dịch vụ và quảng cáo của chúng tôi cho các bên đối ngoại (bao gồm đối tác, nhà điều hành ứng dụng/trang web, nhà quảng cáo, ứng dụng, bên thứ ba và công chúng), bao gồm hiển thị và báo cáo cho đối tác, hiệu suất quảng cáo và thông tin trải nghiệm người dùng. Những phân tích và báo cáo này có thể bao gồm thông tin tổng hợp hoặc ẩn danh.
        11. Cung cấp dịch vụ, quảng cáo, kết quả tìm kiếm và nội dung khác phù hợp với mục tiêu thiết lập của bạn dựa trên thông tin mục tiêu của bạn.
        12. Kết hợp thông tin chúng tôi có về bạn với thông tin chúng tôi thu được từ các đối tác kinh doanh hoặc các công ty khác (chẳng hạn như hoạt động của bạn trên các trang web và ứng dụng khác).
        13. Phát hiện và ngăn chặn gian lận, lạm dụng hoặc hoạt động bất hợp pháp.
        Chia Sẻ và Tiết Lộ Dữ Liệu Cá Nhân
        1. Tiết lộ cho Bên Kiểm Soát Dữ Liệu Khác
        Để đạt được các mục đích trên, công ty chúng tôi sẽ chia sẻ thông tin cá nhân của bạn trong các công ty thuộc cùng chuỗi 7WIN.GAME.
        Chúng tôi cũng sẽ tiết lộ thông tin cá nhân của bạn cho những người kiểm soát dữ liệu bên thứ ba khác khi có sự đồng ý của bạn (trong trường hợp luật hiện hành yêu cầu) hoặc để cung cấp bất kỳ dịch vụ nào bạn yêu cầu (chẳng hạn như tích hợp bên thứ ba). Dịch vụ cũng có thể được sử dụng bởi những người kiểm soát dữ liệu của bên thứ ba để thu thập và xử lý thông tin cá nhân của bạn. Nếu bạn sử dụng địa chỉ email được liên kết với miền doanh nghiệp để truy cập các dịch vụ của 7WIN.GAME, công ty chúng tôi có thể cung cấp thông tin cá nhân của bạn cho doanh nghiệp đó.
        2. Tiết lộ thông tin nhằm ngăn chặn gian lận và cân nhắc về an ninh
        Chúng tôi sẽ tiết lộ thông tin cá nhân cho các công ty giúp chúng tôi điều hành hoạt động kinh doanh của mình nhằm phát hiện, ngăn chặn hoặc giải quyết các hành vi gian lận, lừa dối, hoạt động bất hợp pháp, lạm dụng dịch vụ 7WIN.GAME và các vấn đề bảo mật hoặc kỹ thuật.
        Ngoài ra, nếu Công ty tin tưởng một cách thiện chí rằng việc truy cập, sử dụng, bảo toàn hoặc tiết lộ thông tin là cần thiết một cách hợp lý để phát hiện, ngăn chặn hoặc tránh các hoạt động gian lận, lừa đảo hoặc bất hợp pháp, lạm dụng dịch vụ và phần mềm, hoặc các vấn đề về bảo mật hoặc kỹ thuật, hoặc là cần thiết một cách hợp lý, theo các quy định và sự cho phép của pháp luật, để bảo vệ quyền, tài sản hoặc sự an toàn của nhân viên, người dùng 7WIN.GAME, trẻ em hoặc cộng đồng, công ty chúng tôi sẽ tiết lộ thông tin cá nhân cho các công ty, tổ chức, cơ quan chính phủ hoặc cá nhân khác ngoài chúng tôi.
        3. Tiết lộ cho bên xử lý dữ liệu
        Vì các mục đích được xác định ở trên, chúng tôi cũng sẽ tiết lộ thông tin cá nhân của bạn cho các công ty thay mặt chúng tôi xử lý thông tin cá nhân để hỗ trợ chúng tôi thực hiện hoạt động kinh doanh của mình. Các công ty này bao gồm các nhà cung cấp dịch vụ hỗ trợ khách hàng (bao gồm các nhà cung cấp ghi hoặc lưu trữ thông tin liên lạc), nhà cung cấp công nghệ phân tích (bao gồm cả đối tác tái hiện phiên), những người ghi lại và phân tích các tương tác của bạn với trang web của chúng tôi để giúp chúng tôi cải thiện trải nghiệm người dùng), dịch vụ xử lý thanh toán, giám sát gian lận và ngăn chặn, phát hiện và bảo vệ chống lại hoạt động gian lận hoặc bất hợp pháp hoặc lạm dụng các dịch vụ, email, phương tiện truyền thông xã hội cũng như các nền tảng tiếp thị và nhà cung cấp dịch vụ cũng như dịch vụ lưu trữ khác của chúng tôi. Chúng tôi đã ký kết thỏa thuận với các công ty này theo yêu cầu của luật hiện hành và yêu cầu các công ty này bảo vệ thông tin cá nhân của bạn theo các Nguyên tắc bảo mật này.
        4. Dữ liệu khác được tiết lộ
        Chúng tôi cũng có thể tiết lộ thông tin cá nhân của bạn trong các trường hợp sau:
        ● Khi bạn đồng ý tiết lộ;
        ● Nếu chúng tôi tin tưởng một cách thiện chí rằng chúng tôi được yêu cầu cung cấp thông tin theo yêu cầu của trát đòi hầu tòa, lệnh của tòa án hoặc luật hoặc thủ tục pháp lý hiện hành khác hoặc để ứng phó với trường hợp khẩn cấp liên quan đến nguy cơ tử vong hoặc thương tích cơ thể nghiêm trọng;
        ● Nếu Công ty sát nhập hoặc được một công ty khác mua lại, bán trang web, ứng dụng hoặc đơn vị kinh doanh 7WIN.GAME hoặc nếu tất cả hoặc phần lớn tài sản của Công ty được một công ty khác mua lại, thông tin của bạn có thể được tiết lộ cho người mua tiềm năng, cơ quan quản lý của Công ty. Cố vấn công ty và cố vấn của bất kỳ người mua tiềm năng nào và sẽ là một trong những tài sản được chuyển sang quyền sở hữu mới.
        ● Bảo Vệ Dữ Liệu Cá Nhân
        7WIN.GAME sử dụng các biện pháp bảo vệ hành chính, kỹ thuật và vật lý để bảo vệ dữ liệu cá nhân của bạn, có tính đến bản chất của dữ liệu cá nhân và quá trình xử lý cũng như các mối đe dọa đặt ra. 7WIN.GAME không ngừng nỗ lực cải thiện các biện pháp bảo vệ này nhằm giúp bảo mật dữ liệu cá nhân của bạn.
        ● Liên kết Bên Ngoài từ Trang web 7WIN.GAME
        Trang web 7WIN.GAME có thể cung cấp liên kết Internet tới các trang web khác. Bạn cũng có thể nhấp vào để vào các trang web khác thông qua các liên kết được cung cấp bởi trang web của chúng tôi. Tuy nhiên, chính sách bảo mật trang web của chúng tôi không áp dụng cho trang web được liên kết. Bạn phải tham khảo chính sách bảo mật của trang web được liên kết.
        ● Cookies
        7WIN.GAME có thể thiết lập và truy cập cookie trên máy tính của bạn khi bạn truy cập https://7WIN.GAME và bất kỳ trang hoặc trang web nào dưới thương hiệu 7WIN.GAME'. Cookie được sử dụng để cung cấp cho hệ thống của chúng tôi những thông tin cơ bản nhằm cung cấp các dịch vụ mà bạn yêu cầu. Cookie có thể bị xóa bất cứ lúc nào từ cài đặt trình duyệt internet của bạn.
        ● Những Thay Đổi về Chính Sách Bảo Mật của chúng tôi
        Chúng tôi có thể thực hiện các thay đổi đối với Chính Sách Bảo Mật của mình trong tương lai, tuy nhiên, phiên bản mới nhất của chính sách sẽ chi phối việc chúng tôi xử lý dữ liệu cá nhân của bạn và sẽ luôn sẵn sàng dành cho bạn. Nếu chúng tôi thực hiện một thay đổi quan trọng đối với chính sách này, theo quyết định riêng của chúng tôi, thì chúng tôi sẽ thông báo cho bạn bằng bản cập nhật hoặc email, nếu có thể. Bằng cách tiếp tục truy cập hoặc sử dụng dịch vụ của chúng tôi, bạn đồng ý bị ràng buộc với các điều khoản trong Chính sách quyền riêng tư của chúng tôi.
        7WIN.GAME cam kết bảo vệ sự riêng tư của thông tin cá nhân trực tuyến của bạn. Nếu bạn có bất kỳ câu hỏi hoặc nhận xét nào về việc chúng tôi quản lý thông tin cá nhân của bạn, vui lòng liên hệ với CSKH trong game. Nếu có bất kỳ câu hỏi nào về tuyên bố về quyền riêng tư này, bạn cũng có thể báo cáo cho chúng tôi qua CSKH trong game. 
        Chúng tôi trực tuyến 24 giờ một ngày để giải quyết vấn đề của bạn. Chúng tôi có thể chấp nhận câu hỏi của bạn theo quy định của pháp luật. Chúng tôi có thể từ chối các yêu cầu không hợp lý hoặc nằm ngoài các yêu cầu pháp lý, bao gồm các yêu cầu có tính phi thực tế cao, yêu cầu nỗ lực kỹ thuật không cân xứng hoặc có thể khiến chúng tôi gặp rủi ro hoạt động như dung túng gian lận.
    `,

    'login.tip.账号在其它地方被登录': 'Tài khoản được đăng nhập ở nơi khác',
};