import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from 'vue-router';
import i18n from '@/locale/index';
import { getCountry } from "@/api/user";
import { getConfig,saveConfig,handleChannel,getJsUrl } from '@/utils/function';
import store from '@/store';



const mstore:any = store;


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'index'
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
          title: 'home'
        }
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/activity/index.vue'),
        meta: {
          title: 'activity'
        }
      },
      {
        path: '/activity_detail',
        name: 'activity_detail',
        component: () => import('../views/activity/ActivityDetail.vue'),
        meta: {
          title: 'activity_detail'
        }
      },
      {
        path: '/acting',
        name: 'acting',
        component: () => import('../views/acting/index.vue'),
        meta: {
          title: 'acting'
        }
      },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/mine/index.vue'),
        meta: {
          title: 'mine'
        }
      },
      {
        path: '/vip',
        name: 'vip',
        component: () => import('../views/mine/vip_details.vue'),
        meta: {
          title: 'vip'
        }
      },
      {
        path: '/email',
        name: 'email',
        component: () => import('../views/mine/email.vue'),
        meta: {
          title: 'email'
        }
      },
    ]
  },
  // ------------  ----------------
  {
    path: '/register_gift',
    name: 'register_gift',
    component: () => import('../views/registerGift/index.vue'),
    meta: {
      title: 'register_gift'
    }
  },
  {
    path: '/daily_check_in',
    name: 'daily_check_in',
    component: () => import('../views/dailyCheckIn/index.vue'),
    meta: {
      title: 'daily_check_in'
    }
  },
  {
    path: '/wallt',
    name: 'wallt',
    component: () => import('../views/wallt/index.vue'),
    meta: {
      title: 'wallt'
    }
  },
  {
    path: '/transaction_records',
    name: 'transaction_records',
    component: () => import('../views/wallt/TransactionRecords.vue'),
    meta: {
      title: 'transaction_records'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/login/agreement.vue'),
    meta: {
      title: 'agreement'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: '/find',
    name: 'find',
    component: () => import('../views/login/find.vue'),
    meta: {
      title: 'find'
    }
  },
  {
    path: '/bet_record',
    name: 'bet_record',
    component: () => import('../views/mine/bet_record.vue'),
    meta: {
      title: 'bet record'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

router.beforeEach(async(to: any, from: any) => {
  // document.title = to.meta.title ? to.meta.title : '';

  
  handleChannel();


  // 获取当前IP国家
  if(!getConfig()){
    await onGetCurrentIPCountry();
  };

  if(getJsUrl(location.href)?.fid){
    sessionStorage.setItem('fid',getJsUrl(location.href).fid);
  };

  let router_names = ['home','login','register','find','agreement'];
  if(!router_names.includes(to.name)){
    if(!mstore.state.user?.userinfo?.userInfo?.phonenumber){
      return {
        name: 'login',
        query: { m_redirect: to.name }
      }
    }
  }

  return true;
})

// 获取当前访问者国家
const onGetCurrentIPCountry = async() => {
  await getCountry().then((res:any)=>{
    saveConfig(res.data.data.country);
  });
}



export default router
