export default {
  'daily_checkin.popup.标题': 'Check-in Diário',
  'daily_checkin.popup.描述': 'O quanto você sacar, você receberá',
  'daily_checkin.popup.按钮': 'Resultado do Sorteio',

  'daily_checkin.标题': 'Check-in Diário',
  'daily_checkin.今日奖励': 'Recompensa de Hoje',
  'daily_checkin.领取': 'Resgatar',
  'daily_checkin.免费': 'Grátis',
  'daily_checkin.免费描述': 'Receba moedas de check-in grátis no dia seguinte ao atingir o volume de {amount} hoje',
  'daily_checkin.第一天': 'DIA1',
  'daily_checkin.第二天': 'DIA2',
  'daily_checkin.第三天': 'DIA3',
  'daily_checkin.第四天': 'DIA4',
  'daily_checkin.第五天': 'DIA5',
  'daily_checkin.第六天': 'DIA6',
  'daily_checkin.第七天': 'DIA7',
  'daily_checkin.充值': 'Recarregar',
  'daily_checkin.动态描述': 'Nenhum requisito de volume para saques',
  'daily_checkin.选择充值方式': 'Escolha o Método de Recarregamento',
  'daily_checkin.下订单失败': 'Pedido Falhou',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': 'Falha no pedido, tente um pagamento de grande valor ou outro canal de pagamento',
  'daily_checkin.正在维护': 'Em Manutenção',
  'daily_checkin.领取成功': 'Resgate Bem-Sucedido'
}
