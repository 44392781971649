export default {
  "mine.退出登录": "Sair",
  "mine.用户ID": "ID do Usuário",
  "mine.XP到下一个等级": "XP para o Próximo Nível", 
  "mine.vip": "VIP",
  "mine.充值": "Recarga",
  "mine.下注": "Aposta",

  "mine.menus.充值": "Recarga",
  "mine.menus.提现": "Saque",
  "mine.menus.账户明细": "Detalhes da Conta",
  "mine.menus.代理": "Agente",
  "mine.menus.每日签到": "Check-in Diário",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "Registro de Apostas",
  "mine.menus.邮件通知": "Notificações por Email",
  "mine.menus.注册送": "Bônus de Registro",
  "mine.menus.tip.活动未开启": "Evento não iniciado",

  "mine.userdetail.用户ID": "ID do Usuário",
  "mine.userdetail.总输赢": "Total de Ganhos/Perdas",
  "mine.userdetail.总下注": "Total de Apostas",
  "mine.userdetail.仅计算当月数据": "Somente dados deste mês",
  "mine.userdetail.最喜欢玩的3款游戏": "3 Jogos Favoritos",
  "mine.userdetail.用户信息": "Informações do Usuário",
  "mine.userdetail.绑定手机": "Vincular Telefone",
  "mine.userdetail.绑定邮箱": "Vincular Email",
  "mine.userdetail.更换": "Trocar",
  "mine.userdetail.绑定": "Vincular",

  "mine.replacename.用户名修改": "Alterar Nome de Usuário",
  "mine.replacename.用户名": "Nome de Usuário",
  "mine.replacename.请输入用户名": "Por favor, insira o nome de usuário",
  "mine.replacename.确认": "Confirmar",
  "mine.replacename.tip.请输入用户名": "Por favor, insira o nome de usuário",

  "mine.replaceemail.绑定邮箱": "Vincular Email",
  "mine.replaceemail.邮箱": "Email",
  "mine.replaceemail.请输入邮箱": "Por favor, insira o email",
  "mine.replaceemail.确认": "Confirmar",
  "mine.replaceemail.tip.请输入邮箱": "Por favor, insira o email",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "Por favor, insira um endereço de email válido",

  "mine.betrecord.下注记录": "Registro de Apostas",
  "mine.betrecord.最多展示前100条下注详情": "Exibindo os primeiros 100 registros de apostas",
  "mine.betrecord.暂无下注记录": "Nenhum registro de apostas",
  "mine.betrecord.下注号": "Número da Aposta",
  "mine.betrecord.时间": "Tempo",
  "mine.betrecord.下注金额": "Valor da Aposta",
  "mine.betrecord.输赢": "Ganhos/Perdas",
  
  "mine.email.邮件通知": "Notificações por Email",
  "mine.email.已领取": "Já Resgatado",
  "mine.email.未领取": "Não Resgatado",
  "mine.email.收起": "Recolher",
  "mine.email.展开": "Expandir",
  "mine.email.暂无邮件": "Nenhum email",
  "mine.email.type.VIP返利提取成功": "Reembolso VIP",
  "mine.email.value.VIP返利提取成功": "Reembolso de {amount} recebido com sucesso",
  "mine.email.type.幸运签到": "Check-in Diário",
  "mine.email.value.幸运签到": "Moedas de bônus de {amount} foram creditadas",
  "mine.email.type.NEWCOMER SPECIAL": "Bônus de Registro",
  "mine.email.value.NEWCOMER SPECIAL": "Obrigado por participar da promoção de boas-vindas! O valor de {amount} foi bloqueado! Faça apostas com o saldo recarregado para desbloquear! Não há requisitos de turnover para saques! Veja mais detalhes no evento.",
  "mine.email.type.提款成功": "Saque Bem-Sucedido",
  "mine.email.value.提款成功": "Parabéns, você retirou com sucesso {amount}. Se o status de retirada for bem sucedido, mas você não recebeu o pagamento, o banco geralmente transfere os fundos dentro de 24 horas. Se você não recebeu o pagamento após 24 horas, entre no jogo e entre em contato com o serviço ao cliente!",
  "mine.email.type.提款失败": "Saque Falhou",
  "mine.email.value.提款失败": "Desculpe, seu saque falhou. Isso pode ser devido a um erro no número do cartão ou manutenção do canal. Entre em contato com o suporte.",
  "mine.email.type.提现扣除活动赠送的金币": "Saque com Dedução de Moedas Bônus",
  "mine.email.value.提现扣除活动赠送的金币": "Saque com dedução de moedas bônus: {amount}",
  "mine.email.tip.领取成功": "Resgatado com sucesso",

  "mine.accountdetail.账户明细": "Detalhes da Conta",
  "mine.accountdetail.可提现": "Disponível para Saque",
  "mine.accountdetail.金额": "Valor",
  "mine.accountdetail.充值金币": "Moedas Recarregadas",
  "mine.accountdetail.签到金币": "Moedas de Check-in",
  "mine.accountdetail.充值金详情": "Detalhes das Moedas Recarregadas",
  "mine.accountdetail.签到金详情": "Detalhes das Moedas de Check-in",
  "mine.accountdetail.提现": "Saque",
  "mine.accountdetail.流水": "Turnover",
  "mine.accountdetail.余额": "Saldo",
  "mine.accountdetail.无流水要求": "Sem requisitos de turnover",
  "mine.accountdetail.流水倍数": "Turnover * {rate}",
  "mine.accountdetail.加载中": "Carregando",
  "mine.accountdetail.desc.13014": "Moedas não podem ser sacadas. Após completar 0,1 vez o turnover, você pode participar do evento e sacar!",

  "mine.vip.VIP详情": "Detalhes VIP",
  "mine.vip.成为VIP您可获得以下奖励": "Torne-se VIP para receber as seguintes recompensas",
  "mine.vip.VIP奖金": "Bônus VIP",
  "mine.vip.提现福利": "Benefícios",
  "mine.vip.下注返利": "Reembolso de Apostas",
  "mine.vip.XP到下一个等级": "XP para o Próximo Nível", 
  "mine.vip.充值": "Recarga",
  "mine.vip.提现": "Saque",
  "mine.vip.下注": "Aposta",
  "mine.vip.成为VIP可获取更多福利": "Torne-se VIP para receber mais benefícios!",
  "mine.vip.完成以下任意任务即可成为VIP": "Complete uma das tarefas abaixo para se tornar VIP",
  "mine.vip.充值xx以上": "Recarregue acima de {amount}",
  "mine.vip.完成一次充值+一次提现": "Complete uma recarga + um saque",
  "mine.vip.代理收入达到xx以上": "Receita de agente acima de {amount}",
  "mine.vip.维持当前VIP要求":"Requisitos para manter o status VIP",
  "mine.vip.下注金额超过xx":" Apostas acima de {symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "VIP atual recebe os seguintes benefícios",
  "mine.vip.升级奖金": "Bônus de Nível",
  "mine.vip.可获得xx": "Receber {symbol}{amount}",
  "mine.vip.领取": "Resgatar",
  "mine.vip.已领取": "Já Resgatado",
  "mine.vip.本周充值返利": "Reembolso Semanal de Recarga",
  "mine.vip.最小充值": "Recarga mínima",
  "mine.vip.去充值": "Ir para Recarga",
  "mine.vip.提现返利": "Benefícios", 
  "mine.vip.每日最高可提现": "Limite diário de saque",
  "mine.vip.游戏下注返利": "Reembolso de Apostas em Jogos",
  "mine.vip.slot": "Slots",
  "mine.vip.棋牌": "Jogos de Cartas",
  "mine.vip.视讯": "Cassino Ao Vivo",
  "mine.vip.体育": "Esportes",
}
