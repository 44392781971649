import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app",
  id: "app"
}

import { useStore } from 'vuex';
import { computed,onMounted, onUnmounted } from 'vue'
import { LightVars, DarkVars } from './config/CssVar';
import { onLogin } from "@/utils/loginFunc";
import { webSocketInit } from "@/utils/imHandle";
import { getGameListCode,getUnLoginGameList } from "@/utils/handleGameList";
import { getCountry } from "@/api/user";
import { getConfig,saveConfig,base64DecodeUnicode,isLogin } from '@/utils/function';
import { useRoute, useRouter } from 'vue-router';
import GlobalNotice from '@/components/Notice/Notice.vue';
import { getJsUrl } from '@/utils/function';
import { apkInit } from '@/utils/reportToAndroid';




export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();
const store = useStore();

// Vant格式化主题值
const formatTheme = computed(() => {
  return store.state.setting.isDark ? 'dark' : ''
});

onMounted(() => {
  // 监听页面显示隐藏
  document.addEventListener('visibilitychange', onHandleVisibility);

  // apk上报初始化
  apkInit();

  // 设置主题
  const html = document.documentElement;
  const vars = store.state.setting.isDark ? DarkVars : LightVars;
  Object.entries(vars).forEach(([ key, value ]) => {
    html.style.setProperty(key,value)
  });

  // 重置玩游戏地址
  store.commit('game/setPlayGameUrl');
  // 判断是否在apk环境
  onIsApk();
  // 首次或刷新指定进入HOME
  onRedirectHome()

  if(window.performance.navigation.type === 1) {  // 页面刷新进入
    console.log('页面刷新进入');
  }else{  // 页面首次进入
    console.log('页面首次进入');
  };

  // 获取当前IP国家 获取游戏列表
  if(!getConfig()){
    getCountry().then((res:any)=>{
      saveConfig(res.data.data.country);
      if(isLogin()){
        onLogin();
        getGameListCode();
      }else{
        getUnLoginGameList();
      }
    });
  }else{
    if(isLogin()){
      onLogin();
      getGameListCode();
    }else{
      getUnLoginGameList();
    }
  }
  
});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', onHandleVisibility);
});



// 页面显示隐藏操作
const onHandleVisibility = () => {
  if (document.visibilityState === 'hidden') {
    console.log('页面隐藏');
  }else{
    console.log('页面显示');
    webSocketInit();
  }
}

// 首次或刷新指定进入HOME
const onRedirectHome = () =>{
  let querys = {};
  if(getJsUrl(location.href)){
    querys = getJsUrl(location.href);
  };

  router.push({
    name: 'home',
    query: {
      ...querys
    }
  });
}

// 判断是否在apk环境
const onIsApk = () => {
    let viewer:any = window.navigator.userAgent.toLowerCase();	
    if(viewer.match(/webview7win/i) != 'webview7win'){
      store.commit('user/setIsShowDownApk',true); // 不是在apk环境就显示下载apk条
    }
}


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_van_config_provider = _resolveComponent("van-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_config_provider, { theme: formatTheme.value }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["theme"]),
    _createVNode(GlobalNotice)
  ]))
}
}

})