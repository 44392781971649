// @ts-nocheck



import pako from 'pako';
import { LobbyNumberUtils } from "./LobbyNumberUtils";
import LobbySingleton from "./LobbySingleton";
var CompressType = {
    ["NONE"] : 0,
    ["FLATE"] : 1,
    ["ZLIB"] : 2,
    ["GZIP"] : 3,
    ["LZW"] : 4
};


export let LobbyCompressUtil = /** @class */ (function () {
    
    function LobbyCompressUtil() {
    }
    /**
     * 根据类型初始化压缩库 暂时不用传参数
     */
    LobbyCompressUtil.init = function (type, onLoaded, thisObj) {
        if (type === void 0) { type = CompressType.NONE; }
        if (onLoaded)
            onLoaded.call(thisObj);
    };
    /**
     * 压缩方法
     * @param data {string} 需要压缩的数据
     * @param compressType {string} 压缩类型
     */
    LobbyCompressUtil.compressByType = function (data, compressType) {
        switch (compressType) {
            case CompressType.GZIP:
                return pako.deflate(data);
            case CompressType.FLATE:
                return pako.deflate(data, { raw: true });
        }
    };
    /**
     * 解压方法
     * @param data {string} 需要解压的数据
     * @param compressType {string} 解压类型
     */
    LobbyCompressUtil.uncompressByType = function (data, compressType) {
        switch (compressType) {
            case CompressType.GZIP:
                return pako.inflate(data, { to: 'string' });
            case CompressType.FLATE:
                return pako.inflate(data, { to: 'string', raw: true });
        }
    };
    LobbyCompressUtil.stringToUint = function (st) {
        var str = encodeURIComponent(st), charList = str.split(''), uintArray = [];
        for (var i = 0; i < charList.length; i++) {
            uintArray.push(charList[i].charCodeAt(0));
        }
        return new Uint8Array(uintArray);
    };
    LobbyCompressUtil.uintToString = function (uintArray) {
        var encodedString = String.fromCharCode.apply(null, uintArray), decodedString = decodeURIComponent(encodedString);
        return decodedString;
    };
    LobbyCompressUtil.char2buf = function (str) {
        var out = new ArrayBuffer(str.length * 2);
        var u16a = new Uint16Array(out);
        var strs = str.split("");
        for (var i = 0; i < strs.length; i++) {
            u16a[i] = strs[i].charCodeAt();
        }
        return out;
    };
    LobbyCompressUtil.buf2char = function (buf) {
        var out = "";
        var u16a = new Uint16Array(buf);
        var single;
        for (var i = 0; i < u16a.length; i++) {
            single = u16a[i].toString(16);
            while (single.length < 4)
                single = "0".concat(single);
            out += "\\u" + single;
        }
        return eval("'" + out + "'");
    };
    // function uintToString(uintArray) {
    //     var encodedString = String.fromCharCode.apply(null, uintArray),
    //         decodedString = decodeURIComponent(escape(atob(encodedString)));
    //     return decodedString;
    // }
    LobbyCompressUtil.Utf8ArrayToStr = function (array) {
        var out, i, len, c;
        var char2, char3;
        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12:
                case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    };
    LobbyCompressUtil.stringToArray = function (str, array) {
        for (var i = 0; i < str.length; ++i) {
            array[i] = str.charCodeAt(i) & 0xFF;
        }
        return array;
    };
    LobbyCompressUtil.arrayLikeToArray = function (arrayFrom, arrayTo) {
        for (var i = 0; i < arrayFrom.length; i++) {
            arrayTo[i] = arrayFrom[i];
        }
        return arrayTo;
    };
    LobbyCompressUtil.stringToCodePoints = function (string) {
        /** @type {Array.<number>} */
        var cps = [];
        // Based on http://www.w3.org/TR/WebIDL/#idl-DOMString
        var i = 0, n = string.length;
        while (i < string.length) {
            var c = string.charCodeAt(i);
            if (!LobbyNumberUtils.inRange(c, 0xD800, 0xDFFF)) {
                cps.push(c);
            }
            else if (LobbyNumberUtils.inRange(c, 0xDC00, 0xDFFF)) {
                cps.push(0xFFFD);
            }
            else {
                if (i == n - 1) {
                    cps.push(0xFFFD);
                }
                else {
                    var d = string.charCodeAt(i + 1);
                    if (LobbyNumberUtils.inRange(d, 0xDC00, 0xDFFF)) {
                        var a = c & 0x3FF;
                        var b = d & 0x3FF;
                        i += 1;
                        cps.push(0x10000 + (a << 10) + b);
                    }
                    else {
                        cps.push(0xFFFD);
                    }
                }
            }
            i += 1;
        }
        return cps;
    };
    /**
     * @private
     * UTF-8 Encoding/Decoding
     */
    LobbyCompressUtil.encodeUTF8 = function (str) {
        var pos = 0;
        var codePoints = this.stringToCodePoints(str);
        var outputBytes = [];
        while (codePoints.length > pos) {
            var code_point = codePoints[pos++];
            if (LobbyNumberUtils.inRange(code_point, 0xD800, 0xDFFF)) {
                console.error("encode error:" + code_point);
            }
            else if (LobbyNumberUtils.inRange(code_point, 0x0000, 0x007f)) {
                outputBytes.push(code_point);
            }
            else {
                var count, offset;
                if (LobbyNumberUtils.inRange(code_point, 0x0080, 0x07FF)) {
                    count = 1;
                    offset = 0xC0;
                }
                else if (LobbyNumberUtils.inRange(code_point, 0x0800, 0xFFFF)) {
                    count = 2;
                    offset = 0xE0;
                }
                else if (LobbyNumberUtils.inRange(code_point, 0x10000, 0x10FFFF)) {
                    count = 3;
                    offset = 0xF0;
                }
                outputBytes.push(LobbyNumberUtils.div(code_point, Math.pow(64, count)) + offset);
                while (count > 0) {
                    var temp = LobbyNumberUtils.div(code_point, Math.pow(64, count - 1));
                    outputBytes.push(0x80 + (temp % 64));
                    count -= 1;
                }
            }
        }
        return new Uint8Array(outputBytes);
    };
    LobbyCompressUtil.base64encode = function (input) {
        try {
            this.b64pad;
        }
        catch (e) {
            this.b64pad = '';
        }
        var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        var output = "";
        try {
            input = encodeURIComponent(input);
        }
        catch (e) {
            console.error("error " + e.toString());
        }
        var len = input.length;
        for (var i = 0; i < len; i += 3) {
            var triplet = (input.charCodeAt(i) << 16)
                | (i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0)
                | (i + 2 < len ? input.charCodeAt(i + 2) : 0);
            for (var j = 0; j < 4; j++) {
                if (i * 8 + j * 6 > input.length * 8)
                    output += this.b64pad;
                else
                    output += tab.charAt((triplet >>> 6 * (3 - j)) & 0x3F);
            }
        }
        return output;
    };
    LobbyCompressUtil.base64decode = function (str) {
        var base64DecodeChars = new Array(-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1);
        var c1, c2, c3, c4;
        var i, len, out;
        len = str.length;
        i = 0;
        out = "";
        while (i < len) {
            /* c1 */
            do {
                c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
            } while (i < len && c1 == -1);
            if (c1 == -1)
                break;
            /* c2 */
            do {
                c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
            } while (i < len && c2 == -1);
            if (c2 == -1)
                break;
            out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));
            /* c3 */
            do {
                c3 = str.charCodeAt(i++) & 0xff;
                if (c3 == 61)
                    return out;
                c3 = base64DecodeChars[c3];
            } while (i < len && c3 == -1);
            if (c3 == -1)
                break;
            out += String.fromCharCode(((c2 & 0x0F) << 4) | ((c3 & 0x3C) >> 2));
            /* c4 */
            do {
                c4 = str.charCodeAt(i++) & 0xff;
                if (c4 == 61)
                    return out;
                c4 = base64DecodeChars[c4];
            } while (i < len && c4 == -1);
            if (c4 == -1)
                break;
            out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
        }
        out = decodeURIComponent(out);
        return out;
    };
    LobbyCompressUtil.compressStr = ["", "flate", "zlib", "gzip", "lzw"];
    LobbyCompressUtil.b64pad = "";
    return LobbyCompressUtil;
}());