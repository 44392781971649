export default {
  'acting.代理': 'ตัวแทน',
  'acting.详情': 'รายละเอียด',
  'acting.奖励': 'รางวัล',
  'acting.佣金': 'ค่าคอมมิชชั่น',
  'acting.规则': 'กฎ',
  
  'acting.generalboard.邀请朋友来获取': 'เชิญเพื่อนเพื่อรับ',
  'acting.generalboard.人': 'คน',
  'acting.generalboard.邀请奖励': 'รางวัลการเชิญ',
  'acting.generalboard.高达': 'สูงสุด',
  'acting.generalboard.佣金奖励': 'รางวัลค่าคอมมิชชั่น',
  'acting.generalboard.描述': 'รับ {amount} สำหรับเพื่อนแต่ละคนที่คุณเชิญ พร้อมค่าคอมมิชชั่นสูงสุดถึง 90% จากการเดิมพันของพวกเขา เพลิดเพลินกับค่าคอมมิชชั่นอย่างต่อเนื่อง ไม่ว่าจะชนะหรือแพ้ เริ่มทำเงินได้เลย!',
  'acting.generalboard.邀请链接': 'ลิงก์เชิญ',
  'acting.generalboard.分享到社交平台': 'แชร์ไปยังแพลตฟอร์มโซเชียล',
  'acting.generalboard.拉新表现': 'ประสิทธิภาพการโปรโมต',
  'acting.generalboard.今日拉新': 'โปรโมตวันนี้',
  'acting.generalboard.本周拉新': 'โปรโมตสัปดาห์นี้',
  'acting.generalboard.直属拉新表现': 'ประสิทธิภาพการโปรโมตโดยตรง',
  'acting.generalboard.非直属拉新表现': 'ประสิทธิภาพการโปรโมตทางอ้อม',
  'acting.generalboard.下级详情': 'รายละเอียดผู้ใต้บังคับบัญชา',
  'acting.generalboard.下级总人数': 'จำนวนผู้ใต้บังคับบัญชาทั้งหมด',
  'acting.generalboard.直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาตรง',
  'acting.generalboard.非直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาทางอ้อม',
  'acting.generalboard.总奖励': 'รางวัลทั้งหมด',
  'acting.generalboard.总佣金': 'ค่าคอมมิชชั่นทั้งหมด',
  'acting.generalboard.分享描述': 'เป็นตัวแทน รับรายได้หลักล้านต่อเดือน! รางวัลเชิญ {amount} ค่าคอมมิชชั่นสูงสุดถึง 90%!',
  'acting.generalboard.tip.复制成功': 'คัดลอกสำเร็จ',

  'acting.reward.邀请里程碑': 'ก้าวสำคัญของการเชิญ',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'เชิญเพื่อนครบตามกำหนดเพื่อรับรางวัลที่สอดคล้องกัน',
  'acting.reward.邀请': 'เชิญ',
  'acting.reward.人': 'คน',
  'acting.reward.领取': 'รับ',
  'acting.reward.直属下级人数': 'จำนวนผู้ใต้บังคับบัญชาตรง',
  'acting.reward.邀请奖励': 'รางวัลการเชิญ',
  'acting.reward.直属下级绑定成功可获得': 'รับรางวัลเมื่อผู้ใต้บังคับบัญชาตรงผูกสำเร็จ',
  'acting.reward.今日邀请': 'เชิญวันนี้',
  'acting.reward.总邀请': 'เชิญทั้งหมด',
  'acting.reward.今日奖励': 'รางวัลวันนี้',
  'acting.reward.邀请总奖励': 'รางวัลการเชิญทั้งหมด',
  'acting.reward.今日邀请人数': 'จำนวนเชิญวันนี้',
  'acting.reward.邀请总人数': 'จำนวนเชิญทั้งหมด',
  'acting.reward.用户ID': 'รหัสผู้ใช้',
  'acting.reward.昵称': 'ชื่อเล่น',
  'acting.reward.奖励': 'รางวัล',
  'acting.reward.展示前50条信息': 'แสดงข้อมูล 50 รายการแรก',
  'acting.reward.暂无记录': 'ไม่มีบันทึก',
  'acting.reward.领取成功': 'รับสำเร็จ',

  'acting.commission.本周佣金信息': 'ข้อมูลค่าคอมมิชชั่นประจำสัปดาห์นี้',
  'acting.commission.下级总充值': 'ยอดการเติมเงินรวมของผู้ใต้บังคับบัญชา',
  'acting.commission.直属下级佣金': 'ค่าคอมมิชชั่นผู้ใต้บังคับบัญชาตรง',
  'acting.commission.非直属下级佣金': 'ค่าคอมมิชชั่นผู้ใต้บังคับบัญชาทางอ้อม',
  'acting.commission.未领取佣金': 'ค่าคอมมิชชั่นที่ยังไม่ได้รับ',
  'acting.commission.领取佣金': 'รับค่าคอมมิชชั่น',
  'acting.commission.领取记录': 'ประวัติการรับ',
  'acting.commission.佣金总和': 'ค่าคอมมิชชั่นทั้งหมด',
  'acting.commission.领取时间': 'เวลาการรับ',
  'acting.commission.直属佣金': 'ค่าคอมมิชชั่นตรง',
  'acting.commission.非直属佣金': 'ค่าคอมมิชชั่นทางอ้อม',
  'acting.commission.总和': 'ยอดรวม',
  'acting.commission.展示前50条信息': 'แสดงข้อมูล 50 รายการแรก',
  'acting.commission.暂无记录': 'ไม่มีบันทึก',
  'acting.commission.领取成功': 'รับสำเร็จ',
}