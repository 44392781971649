import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'

import setting from './modules/setting'; // 设置模块
import user from './modules/user';
import game from './modules/game';



export default createStore({
  modules: {
    setting,
    user,
    game
  },
  plugins:[
      createPersistedState({
          storage:window.localStorage
      })
  ]
})
