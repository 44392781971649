export default {
    'notice.充值成功': 'เติมเงินสำเร็จ',
    'notice.领取奖励成功': 'รับรางวัลสำเร็จ',
    'notice.您未参与才活动': 'ไม่ได้เข้าร่วมกิจกรรมนี้',
    'notice.已经领取过了': 'รับไปแล้ว',
    'notice.提款申请成功，请等待': 'คำขอถอนเงินสำเร็จ กรุณารอ',
    'notice.密码错误': 'รหัสผ่านไม่ถูกต้อง',
    'notice.验证码已发送': 'ส่งรหัสยืนยันแล้ว',
    'notice.提款失败': 'การถอนเงินล้มเหลว',
    'notice.验证码有误或已过期': 'รหัสยืนยันไม่ถูกต้องหรือหมดอายุ',
    'notice.该手机号已使用，请更换': 'หมายเลขโทรศัพท์นี้ถูกใช้งานแล้ว กรุณาเปลี่ยน',
    'notice.您当前账号已绑定过手机号': 'บัญชีปัจจุบันของคุณได้เชื่อมกับหมายเลขโทรศัพท์แล้ว',
    'notice.渠道维护请重新选择': 'ช่องทางกำลังบำรุงรักษา กรุณาเลือกใหม่',
    'notice.此充值方式不能参与活动': 'วิธีการเติมเงินนี้ไม่สามารถเข้าร่วมกิจกรรมได้',
    'notice.小于此渠道最低充值金额': 'น้อยกว่าจำนวนเงินเติมขั้นต่ำของช่องทางนี้',
    'notice.中间件配置不存在禁止登录': 'การตั้งค่ามิดเดิลแวร์ไม่ถูกต้อง ห้ามเข้าสู่ระบบ',
    'notice.正在维护': 'ช่องทาง {channel} กำลังบำรุงรักษา',
    'notice.领取新版注册送金币': 'รับ {amount} เหรียญสำเร็จ',
    'notice.领取新版注册送金币最后一次领取': 'รับ {amount} เหรียญสำเร็จ คุณได้รับรางวัลทั้งหมดแล้ว กิจกรรมจะปิดลง',
    'notice.appKey不一致禁止登录': 'AppKey ไม่ตรงกัน ห้ามเข้าสู่ระบบ',
    'notice.账号在其它地方被登录': 'บัญชีถูกเข้าสู่ระบบจากที่อื่น',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': 'ขออภัยอย่างยิ่ง คำขอถอนเงินของคุณล้มเหลว อาจเกิดจากหมายเลขบัตรไม่ถูกต้องหรือช่องทางกำลังบำรุงรักษา กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อยืนยัน',
    'notice.提款金额不能大于': 'จำนวนเงินถอนต้องไม่เกิน {amount}',
    'notice.游戏暂未开放': 'เกมยังไม่เปิดให้บริการ',
    'notice.账号被其它设备登录': 'ระบบตรวจพบว่าบัญชีของคุณอาจเข้าสู่ระบบบนอุปกรณ์หลายเครื่อง! หากคุณไม่ทราบสิ่งนี้โปรดเปลี่ยนรหัสผ่านบัญชีของคุณในเวลาที่เหมาะสม!' ,
    'notice.该银行卡号绑定在其他账号中': 'หมายเลขบัตรธนาคารนี้ผูกติดกับเลขบัญชีอื่น'
};
