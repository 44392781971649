export default {
  'acting.代理': 'Affiliate',
  'acting.详情': 'Details',
  'acting.奖励': 'Reward',
  'acting.佣金': 'Commission',
  'acting.规则': 'Rules',
  
  'acting.generalboard.邀请朋友来获取': 'Invite friends to get',
  'acting.generalboard.人': 'People',
  'acting.generalboard.邀请奖励': 'Invitation Reward',
  'acting.generalboard.高达': 'Up to',
  'acting.generalboard.佣金奖励': 'Commission Reward',
  'acting.generalboard.描述': 'Get {amount} for each friend you invite, plus a up to 90% commission on their wagers. Enjoy consistent commissions, whether they win or lose. Start earning now!',
  'acting.generalboard.邀请链接': 'Invitation Link',
  'acting.generalboard.分享到社交平台': 'Share to Social Media',
  'acting.generalboard.拉新表现': 'Promotion Performance',
  'acting.generalboard.今日拉新': 'Promotions Today',
  'acting.generalboard.本周拉新': 'Promotions This Week',
  'acting.generalboard.直属拉新表现': 'Direct Performance',
  'acting.generalboard.非直属拉新表现': 'Indirect Performance',
  'acting.generalboard.下级详情': 'Subordinate Details',
  'acting.generalboard.下级总人数': 'Total Number of Subordinates',
  'acting.generalboard.直属下级人数': 'Number of Direct Subordinates',
  'acting.generalboard.非直属下级人数': 'Number of Indirect Subordinates',
  'acting.generalboard.总奖励': 'Total Rewards',
  'acting.generalboard.总佣金': 'Total Commissions',
  'acting.generalboard.分享描述': 'Become an agent, earn millions a month! Invitation reward {amount}, commission up to 90%!',
  'acting.generalboard.tip.复制成功': 'Copy Successful',

  'acting.reward.邀请里程碑': 'Invitation Milestone',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Reach the required number of invited friends to receive rewards',
  'acting.reward.邀请': 'Invite',
  'acting.reward.人': 'People',
  'acting.reward.领取': 'Claim',
  'acting.reward.直属下级人数': 'Number of Direct Subordinates',
  'acting.reward.邀请奖励': 'Invitation Reward',
  'acting.reward.直属下级绑定成功可获得': 'Earn rewards when direct subordinates are successfully bound',
  'acting.reward.今日邀请': 'Invitations Today',
  'acting.reward.总邀请': 'Total Invitations',
  'acting.reward.今日奖励': "Today Reward",
  'acting.reward.邀请总奖励': 'Total Invitation Rewards',
  'acting.reward.今日邀请人数': 'Number of Invitations Today',
  'acting.reward.邀请总人数': 'Total Number of Invitations',
  'acting.reward.用户ID': 'User ID',
  'acting.reward.昵称': 'Nickname',
  'acting.reward.奖励': 'Reward',
  'acting.reward.展示前50条信息': 'Displaying the first 50 entries',
  'acting.reward.暂无记录': 'No Records',
  'acting.reward.领取成功': 'Claim Successful',

  'acting.commission.本周佣金信息': 'This Week Commission',
  'acting.commission.下级总充值': 'Total Subordinate Recharges',
  'acting.commission.直属下级佣金': 'Direct Subordinate Commissions',
  'acting.commission.非直属下级佣金': 'Indirect Subordinate Commissions',
  'acting.commission.未领取佣金': 'Unclaimed Commissions',
  'acting.commission.领取佣金': 'Claim Commissions',
  'acting.commission.领取记录': 'Claim Records',
  'acting.commission.佣金总和': 'Total Commission',
  'acting.commission.领取时间': 'Claim Time',
  'acting.commission.直属佣金': 'Direct Commission',
  'acting.commission.非直属佣金': 'Indirect Commission',
  'acting.commission.总和': 'Total',
  'acting.commission.展示前50条信息': 'Displaying the first 50 entries',
  'acting.commission.暂无记录': 'No Records',
  'acting.commission.领取成功': 'Claim Successful',
}