export default {
  'home.minbanner.activity1.标题': 'โปรโมชั่นพิเศษสำหรับผู้มาใหม่',
  'home.minbanner.activity1.描述': 'สูงสุด',
  'home.minbanner.activity1.全部': 'มอบให้',
  'home.minbanner.activity3.标题': 'เช็คอินรายวัน',
  'home.minbanner.activity3.描述': 'จับได้เท่าไหร่ รับเท่านั้น',
  'home.minbanner.activity4.标题': 'เป็น VIP รับรางวัลใหญ่',

  'home.game.lobby': 'ทั้งหมด',
  'home.game.hot': 'ยอดนิยม',
  'home.game.slots': 'สล็อต',
  'home.game.BY': 'ตกปลา',
  'home.game.casino': 'เกมโป๊กเกอร์',
  'home.game.SX': 'คาสิโนสด',
  'home.game.TY': 'กีฬา',
  'home.game.NEW': 'ใหม่',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': 'ดูทั้งหมด',

  'home.exitdownpopup.提示': 'แจ้งเตือน',
  'home.exitdownpopup.内容': 'เรียนผู้เล่น เนื่องจากปัญหาเครือข่าย เหรียญ {coin} ของคุณบนแพลตฟอร์ม {platform} ยังไม่ได้รับการเคลียร์ โปรดรออีกสักครู่',
  'home.exitdownpopup.联系客服': 'ติดต่อฝ่ายบริการลูกค้า',

  'home.share.加入我们的社区': 'เข้าร่วมชุมชนของเรา',

  'home.icons.支持的充值平台': 'แพลตฟอร์มการชำระเงินที่รองรับ',
  'home.icons.赞助和博彩责任': 'พันธมิตร',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': 'ด้วยแนวทางที่เน้นผู้เล่นเป็นหลัก 7win.game สามารถตอบสนองนักพนันหลายล้านคนทั่วโลก 7win.game ให้ความสำคัญกับชุมชนของตน โดยมุ่งเน้นให้มั่นใจว่าจะมีประสบการณ์การพนันที่ยั่งยืนและสนุกสนานไม่รู้จบ การใช้และการเข้าถึง 7win.game ของคุณแสดงว่าคุณเข้าใจและตกลงที่จะผูกพันตามเนื้อหาของข้อกำหนดในการให้บริการและนโยบายการเล่นเกมที่รับผิดชอบของเรา',

  'home.selectsession.门槛': 'เงื่อนไข',
  'home.selectsession.初级场': 'ห้องผู้เริ่มต้น',
  'home.selectsession.中级场': 'ห้องระดับกลาง',
  'home.selectsession.高级场': 'ห้องขั้นสูง',
  'home.selectsession.精英场': 'ห้องเอลิท',
  'home.selectsession.余额不足': 'ยอดเงินคงเหลือไม่เพียงพอ',
  'home.selectsession.只有充值金才能玩': 'อนุญาตเฉพาะเหรียญที่เติมเงินได้เท่านั้น',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': 'ยอดเหรียญเติมเงินไม่เพียงพอสำหรับเข้าสู่ห้องนี้',
  'home.selectsession.当前场次条件是xx': 'เงื่อนไขของห้องคือ {min}-{max}, คุณไม่ตรงตามเงื่อนไข!',
  'home.selectsession.当前余额不足': 'ยอดเงินไม่เพียงพอ โปรดเติมเงิน',
  'home.entergame.用户余额不足': 'ยอดเงินคงเหลือของผู้ใช้ไม่เพียงพอ',
  'home.entergame.上分失败': 'การเพิ่มคะแนนล้มเหลว',
  'home.entergame.三方游戏进入提示': 'ยอดเงินฝากของคุณปัจจุบันคือ {balance}, จำนวนเงินที่สามารถโอนไปยัง {platform_name} ได้คือ {coin}, อัตราแลกเปลี่ยน {rate}:1',
  'home.entergame.三方游戏不能进入提示': 'คุณต้องเติมเงินเพื่อโอนไปยัง {platform_name}',

}
