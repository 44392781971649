export default {
  'home.minbanner.activity1.标题': 'ƯU ĐÃI TÂN THỦ',
  'home.minbanner.activity1.描述': 'LÊN TÓI',
  'home.minbanner.activity1.全部': 'TẶNG',
  'home.minbanner.activity3.标题': 'Điểm danh',
  'home.minbanner.activity3.描述': 'Rút bao nhiêu tặng bấy nhiêu',
  'home.minbanner.activity4.标题': 'Trở thành VIP Nhận Thưởng Lớn',

  'home.game.lobby': 'Tất cả',
  'home.game.hot': 'Hot',
  'home.game.slots': 'Slots',
  'home.game.BY': 'Bắn cá',
  'home.game.casino': 'Game bài',
  'home.game.SX': 'Live Casino',
  'home.game.TY': 'Thể Thao',
  'home.game.NEW': 'Mới Nhất',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': 'Xem tất cả',

  'home.exitdownpopup.提示': 'Nhắc nhở',
  'home.exitdownpopup.内容': 'Kính gửi người chơi, do sự cố mạng, số tiền vàng {coin} của bạn tạm thời không được kết đoán trên nền tảng {platform}. Vui lòng kiên nhẫn chờ đợi trong giây lát.',
  'home.exitdownpopup.联系客服': 'Liên hệ CSKH',

  'home.share.加入我们的社区': 'Tham gia cộng đồng của chúng tôi',

  'home.icons.支持的充值平台': 'Nền tảng nạp tiền được hỗ trợ',
  'home.icons.赞助和博彩责任': 'Đối tác',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': `Dưới phương châm lấy người chơi làm trung tâm, 7win.game đã thành công trong việc đáp ứng nhu cầu của hàng triệu người chơi cá cược trên toàn thế giới. 7win.game luôn đặt cộng đồng của mình lên hàng đầu, đảm bảo mang lại trải nghiệm cá cược bất tận và đầy hấp dẫn. Việc bạn sử dụng và truy cập 7win.game đồng nghĩa với việc bạn hoàn toàn hiểu và đồng ý bị ràng buộc về mặt pháp lý bởi các nội dung của Điều khoản Dịch vụ và Chính sách Chơi có Trách nhiệm của chúng tôi.`,

  'home.selectsession.门槛': 'Điều kiện',
  'home.selectsession.初级场': 'Tập sự',
  'home.selectsession.中级场': 'Chuyên gia',
  'home.selectsession.高级场': 'Cao thủ',
  'home.selectsession.精英场': 'Tinh anh',
  'home.selectsession.余额不足':'Số dư không đủ',
  'home.selectsession.只有充值金才能玩':'Chỉ tiền nạp mới được chơi game',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': 'Số dư tiền nạp không đáp ứng ngưỡng tham gia cho sảnh hiện tại.',
  'home.selectsession.当前场次条件是xx': 'Điều kiện sảnh hiện tại là {min}-{max}, bạn không đáp ứng điều kiện hiện tại!',
  'home.selectsession.当前余额不足': 'Số dư hiện tại không đủ, vui lòng nạp tiền',
  'home.entergame.用户余额不足': 'Số dư người dùng không đủ',
  'home.entergame.上分失败': 'Lên điểm thất bại',
  'home.entergame.三方游戏进入提示': 'Số dư nạp tiền hiện tại là {balance},số tiền {coin} sẽ chuyển vào  {platform_name},Tỷ lệ đăng ký {rate}:1',
  'home.entergame.三方游戏不能进入提示': 'Tiền nạp mới vào {platform_name}',

}