// @ts-nocheck
import { getCountry } from "@/api/user";
import { getConfig,getLoginInfo,getUserInfo,saveConfig } from "./function";



// 上报到安卓
const reportToApk = (data:any) => {
    try {
        window?.Android?.sendMessageToAndroid(JSON.stringify(data));
    }catch(error){
        console.log('不在APK环境',error);  
    };
}

// APK初始化
const apkInit = async() => {
    if(!getConfig()){
        await getCountry().then((res:any)=>{
            saveConfig(res.data.data.country);
        });
    };

    let { appKey } = getConfig();

    let data  = {
        cmd: 'init',
        code: '0',
        type: '0',
        data: {
            appKey: appKey + ''
        }
    }

    reportToApk(data);
}

// 登录
const apkLogin = () => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { uid } = getLoginInfo();
        let user_info = getUserInfo();

        // 登录方式
        let login_method = 'phone_number';
        if(user_info?.userInfo?.platAccount?.includes('FB')){
            login_method = 'facebook';  
        }else if(user_info?.userInfo?.platAccount?.includes('GL')){
            login_method = 'google';
        };

        // 是否代理
        let is_from_agent = apk_init.data.fid ? 'true' : 'false';

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'login',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                login_method: login_method + '',
                is_from_agent: is_from_agent + ''
            }
        };

        reportToApk(data);
    };
}

// 注册
const apkRegister = () => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { uid } = getLoginInfo();
        let user_info = getUserInfo();

        // 是否代理
        let is_from_agent = apk_init.data.fid ? 'true' : 'false';

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'register',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                reg_method: '',
                is_from_agent: is_from_agent + ''
            }
        };

        reportToApk(data);
    };
}

// 首充
const apkFirstRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'first_purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 多次充值
const apkRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'purchase_all',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 24小时内充值
const apk24hourRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'first_day_purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 第2天充值
const apk2dayRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'D2Purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 第3天充值
const apk3dayRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'D3Purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 第7天充值
const apk7dayRecharge = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'D7Purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 第14天充值
const apk14dayRecharge = (amount:any) =>{
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'D14Purchase',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                af_revenue: amount + '',    // 充值金额
                af_currency: coinUtil + '', // 充值币种
                purchase_type: ''
            }
        };

        reportToApk(data);
    };
}

// 提现
const apkWithdawal = (amount:any) => {
    let apk_init:any =  undefined;

    if(sessionStorage.getItem('apk_init')){
        apk_init = JSON.parse(sessionStorage.getItem('apk_init'));
    };

    if(apk_init){
        let { coinUtil } = getConfig();
        let { uid } = getLoginInfo();

        let data = {
            cmd: 'adjust_event',
            code: '0',
            type: '0',
            data: {
                eventName: 'withdraw',
                type: '',
                UID: uid + '',
                fb_fbc: apk_init.data.fbc + '',
                fb_fbp: apk_init.data.fbp + '',
                withdraw_sum: amount + '',            // 提现金额
                withdraw_currency: coinUtil + '',     // 提现币种
                withdraw_type: ''
            }
        };

        reportToApk(data);
    };
}

// 打开分享链接WEB
const apkOpenWeb = (web_url:any) => {
    let data = {
        cmd: 'openWeb',
        code: '0',
        type: '0',
        data: {
            model: '1',
            webUrl: web_url
        }
    };

    reportToApk(data);
}



export {
    apkInit,
    apkLogin,
    apkRegister,
    apkFirstRecharge,
    apkRecharge,
    apk24hourRecharge,
    apk2dayRecharge,
    apk3dayRecharge,
    apk7dayRecharge,
    apk14dayRecharge,
    apkWithdawal,
    apkOpenWeb
}