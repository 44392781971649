import header from "./ID/header";
import navbar from "./ID/navbar";
import login from "./ID/login";
import home from "./ID/home";
import mine from "./ID/mine";
import acting from "./ID/acting";
import wallet from "./ID/wallet";
import activity from "./ID/activity";
import register_gift from "./ID/register_gift";
import daily_checkin from "./ID/daily_checkin";
import newcomer_gift from "./ID/newcomer_gift";
import notice from "./ID/notice";
import public_components from "./ID/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
