export default {
  'acting.代理': 'Agen',
  'acting.详情': 'Detail',
  'acting.奖励': 'Hadiah',
  'acting.佣金': 'Komisi',
  'acting.规则': 'Aturan',

  'acting.generalboard.邀请朋友来获取': 'Undang teman untuk mendapatkan',
  'acting.generalboard.人': 'orang',
  'acting.generalboard.邀请奖励': 'Hadiah Undangan',
  'acting.generalboard.高达': 'Hingga',
  'acting.generalboard.佣金奖励': 'Hadiah Komisi',
  'acting.generalboard.描述': 'Dapatkan {amount} untuk setiap teman yang Anda undang, plus komisi hingga 90% dari taruhan mereka. Nikmati komisi yang konsisten, apakah mereka menang atau kalah. Mulai hasilkan sekarang!',
  'acting.generalboard.邀请链接': 'Tautan Undangan',
  'acting.generalboard.分享到社交平台': 'Bagikan ke platform sosial',
  'acting.generalboard.拉新表现': 'Kinerja Perekrutan Baru',
  'acting.generalboard.今日拉新': 'Perekrutan Baru Hari Ini',
  'acting.generalboard.本周拉新': 'Perekrutan Baru Minggu Ini',
  'acting.generalboard.直属拉新表现': 'Kinerja Perekrutan Langsung',
  'acting.generalboard.非直属拉新表现': 'Kinerja Perekrutan Tidak Langsung',
  'acting.generalboard.下级详情': 'Detail Bawahan',
  'acting.generalboard.下级总人数': 'Jumlah Total Bawahan',
  'acting.generalboard.直属下级人数': 'Jumlah Bawahan Langsung',
  'acting.generalboard.非直属下级人数': 'Jumlah Bawahan Tidak Langsung',
  'acting.generalboard.总奖励': 'Total Hadiah',
  'acting.generalboard.总佣金': 'Total Komisi',
  'acting.generalboard.分享描述': 'Jadilah agen, dapatkan jutaan setiap bulan! Hadiah undangan {amount}, komisi hingga 90%!',
  'acting.generalboard.tip.复制成功': 'Salin Berhasil',

  'acting.reward.邀请里程碑': 'Tonggak Undangan',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Hadiah dapat diklaim setelah mencapai jumlah teman yang diundang',
  'acting.reward.邀请': 'Undang',
  'acting.reward.人': 'orang',
  'acting.reward.领取': 'Klaim',
  'acting.reward.直属下级人数': 'Jumlah Bawahan Langsung',
  'acting.reward.邀请奖励': 'Hadiah Undangan',
  'acting.reward.直属下级绑定成功可获得': 'Dapatkan setelah berhasil menghubungkan bawahan langsung',
  'acting.reward.今日邀请': 'Undangan Hari Ini',
  'acting.reward.总邀请': 'Total Undangan',
  'acting.reward.今日奖励': 'Hadiah Hari Ini',
  'acting.reward.邀请总奖励': 'Total Hadiah Undangan',
  'acting.reward.今日邀请人数': 'Jumlah Undangan Hari Ini',
  'acting.reward.邀请总人数': 'Jumlah Total Undangan',
  'acting.reward.用户ID': 'ID Pengguna',
  'acting.reward.昵称': 'Nama Panggilan',
  'acting.reward.奖励': 'Hadiah',
  'acting.reward.展示前50条信息': 'Menampilkan 50 Informasi Pertama',
  'acting.reward.暂无记录': 'Tidak Ada Catatan',
  'acting.reward.领取成功': 'Klaim Berhasil',

  'acting.commission.本周佣金信息': 'Informasi Komisi Minggu Ini',
  'acting.commission.下级总充值': 'Total Top Up Bawahan',
  'acting.commission.直属下级佣金': 'Komisi Bawahan Langsung',
  'acting.commission.非直属下级佣金': 'Komisi Bawahan Tidak Langsung',
  'acting.commission.未领取佣金': 'Komisi yang Belum Diterima',
  'acting.commission.领取佣金': 'Klaim Komisi',
  'acting.commission.领取记录': 'Catatan Klaim',
  'acting.commission.佣金总和': 'Total Komisi',
  'acting.commission.领取时间': 'Waktu Klaim',
  'acting.commission.直属佣金': 'Komisi Langsung',
  'acting.commission.非直属佣金': 'Komisi Tidak Langsung',
  'acting.commission.总和': 'Total',
  'acting.commission.展示前50条信息': 'Menampilkan 50 Informasi Pertama',
  'acting.commission.暂无记录': 'Tidak Ada Catatan',
  'acting.commission.领取成功': 'Klaim Berhasil',
}