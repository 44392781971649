/**
 * 浅色主题的css变量
 */
export const LightVars = {
  '--color-bg-1': '#fff',
  '--color-bg-2': '#eaecf3',
  '--color-bg-3': '#f5f6f9',
  '--color-bg-4': '#fff',
  '--color-bg-5': '#fff',
  '--color-text-1': '#000',
  '--color-text-2': '#808d95',
  '--color-text-3': '#4d5051',
  '--border-color-1': '#ddd',
  '--color-shadow': '#a3a3a318',
  '--color-mask': 'rgba(220,220,220, .8)',
}

/**
 * 深色主题的css变量
 */
export const DarkVars = {
  '--color-bg-1': '#14133F',
  '--color-bg-2': '#201F3D',
  '--color-bg-3': '#2d3035',
  '--color-bg-4': '#1e2024',
  '--color-bg-5': '#000',
  '--color-text-1': '#fff',
  '--color-text-2': '#9089B6',
  '--color-text-3': '#4d5051',
  '--border-color-1': '#2d3035',
  '--color-shadow': 'rgb(80,80,80,.1)',
  '--color-mask': 'rgba(0, 0, 0, .8)',
}