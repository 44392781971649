export default {
  'daily_checkin.popup.标题': 'Điểm danh',
  'daily_checkin.popup.描述': 'Rút bao nhiêu tặng bấy nhiêu',
  'daily_checkin.popup.按钮': 'Mở thưởng',

  'daily_checkin.标题': 'Điểm danh',
  'daily_checkin.今日奖励': 'Thưởng hôm nay',
  'daily_checkin.领取': 'Nhận',
  'daily_checkin.免费': 'Miễn phí',
  'daily_checkin.免费描述': 'Khi cược đạt {amount} trong cùng ngày, bạn có thể nhận thưởng điểm danh free vào ngày hôm sau.',
  'daily_checkin.第一天': 'Ngày1',
  'daily_checkin.第二天': 'Ngày2',
  'daily_checkin.第三天': 'Ngày3',
  'daily_checkin.第四天': 'Ngày4',
  'daily_checkin.第五天': 'Ngày5',
  'daily_checkin.第六天': 'Ngày6',
  'daily_checkin.第七天': 'Ngày7',
  'daily_checkin.充值': 'Nạp tiền',
  'daily_checkin.动态描述': 'Rút tiền không cần cá cược',
  'daily_checkin.选择充值方式': 'Chọn cách nạp',
  'daily_checkin.下订单失败': 'Đặt đơn thất bại',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': 'Đặt hàng thất bại, vui lòng thử thanh toán số tiền lớn hoặc các kênh thanh toán khác',
  'daily_checkin.正在维护': 'Đang bảo trì',
 'daily_checkin.领取成功': 'Nhận thành công'

}