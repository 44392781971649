export default {
  'activity.活动': 'Acara',
  'activity.立即参与': 'Ikuti Sekarang',
  'activity.活动时间': 'Waktu Acara',
  'activity.活动未开启': 'Acara Belum Dimulai',

  'activity.activity1.标题': 'Penawaran Baru',
  'activity.activity1.描述': 'Hingga',
  'activity.activity1.全部': 'Diberikan',
  'activity.activity1.活动描述': 'Isi ulang dan dapatkan hingga {rate}%',

  'activity.activity2.活动描述': 'Jadilah agen! Dapatkan jutaan setiap bulan!',

  'activity.activity3.标题': 'Check-in Harian',
  'activity.activity3.描述': 'Apa yang Anda tarik, Anda dapatkan',
  'activity.activity3.活动描述': 'Hadiah check-in harian yang terus berlanjut',

  'activity.activity4.标题': 'Jadilah VIP dan Menangkan Hadiah Besar',
  'activity.activity4.活动描述': 'VIP dapatkan hadiah jutaan',

  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': 'Koin Bank Terikat',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': 'Koin Registrasi',
  'activity.coinname.12006': 'Koin TG',
  'activity.coinname.12007': 'Koin Level Up',
  'activity.coinname.13001': 'Rekrut Agen Baru',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': 'Undian',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': 'Koin Check-in',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': 'Koin Acara Fuka',
  'activity.coinname.90001': 'Koin Check-in Gratis',
  'activity.coinname.90002': 'Koin Bonus Isi Ulang',
  'activity.coinname.90003': 'Hadiah Jackpot',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': 'Penawaran Baru',
  'activity.coinname.20001': 'Pengguna Lama Kembali',
  'activity.coinname.20002': 'Hadiah Pembaruan Versi',
  'activity.coinname.13002': 'Komisi Agensi',
  'activity.coinname.13003': 'Koin rebate VIP',
  'activity.coinname.chips': 'Saldo',
  'activity.coinname.rechargeChips': 'Saldo Isi Ulang',
}
