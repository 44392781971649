import header from "./PT/header";
import navbar from "./PT/navbar";
import login from "./PT/login";
import home from "./PT/home";
import mine from "./PT/mine";
import acting from "./PT/acting";
import wallet from "./PT/wallet";
import activity from "./PT/activity";
import register_gift from "./PT/register_gift";
import daily_checkin from "./PT/daily_checkin";
import newcomer_gift from "./PT/newcomer_gift";
import notice from "./PT/notice";
import public_components from "./PT/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
