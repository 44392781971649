export default {
  "mine.退出登录": "Logout",
  "mine.用户ID": "User ID",
  "mine.XP到下一个等级": "XP to the Next Level",
  "mine.vip": "VIP",
  "mine.充值": "Recharge",
  "mine.下注": "Bet",

  "mine.menus.充值": "Recharge",
  "mine.menus.提现": "Withdraw",
  "mine.menus.账户明细": "Account",
  "mine.menus.代理": "Affiliate",
  "mine.menus.每日签到": "Daily Sign-In",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "Bet Records",
  "mine.menus.邮件通知": "Email",
  "mine.menus.注册送": "Register Bonus",
  "mine.menus.tip.活动未开启": "Activity Not Started",

  "mine.userdetail.用户ID": "User ID",
  "mine.userdetail.总输赢": "Total Win/Loss",
  "mine.userdetail.总下注": "Total Bet",
  "mine.userdetail.仅计算当月数据": "Only calculate data for the current month",
  "mine.userdetail.最喜欢玩的3款游戏": "Top 3 Favorite Games",
  "mine.userdetail.用户信息": "User Information",
  "mine.userdetail.绑定手机": "Bind Phone",
  "mine.userdetail.绑定邮箱": "Bind Email",
  "mine.userdetail.更换": "Change",
  "mine.userdetail.绑定": "Bind",

  "mine.replacename.用户名修改": "Change UserName",
  "mine.replacename.用户名": "Username",
  "mine.replacename.请输入用户名": "Please Enter Username",
  "mine.replacename.确认": "Confirm",
  "mine.replacename.tip.请输入用户名": "Please Enter Username",

  "mine.replaceemail.绑定邮箱": "Bind Email",
  "mine.replaceemail.邮箱": "Email",
  "mine.replaceemail.请输入邮箱": "Please Enter Email",
  "mine.replaceemail.确认": "Confirm",
  "mine.replaceemail.tip.请输入邮箱": "Please Enter Email",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "Please Enter a Valid Email Address",

  "mine.betrecord.下注记录": "Bet Records",
  "mine.betrecord.最多展示前100条下注详情": "Showing Up to 100 Bet Records",
  "mine.betrecord.暂无下注记录": "No Bet Records",
  "mine.betrecord.下注号": "Bet Number",
  "mine.betrecord.时间": "Time",
  "mine.betrecord.下注金额": "Bet Amount",
  "mine.betrecord.输赢": "Win/Loss",
  
  "mine.email.邮件通知": "Email Notification",
  "mine.email.已领取": "Claimed",
  "mine.email.未领取": "Unclaimed",
  "mine.email.收起": "Collapse",
  "mine.email.展开": "Expand",
  "mine.email.暂无邮件": "No email available",
  "mine.email.type.VIP返利提取成功": "VIP Rebate",
  "mine.email.value.VIP返利提取成功": "Successfully Claimed Amount {amount}",
  "mine.email.type.幸运签到": "Daily Sign-In",
  "mine.email.value.幸运签到": "Awarded {amount} Has Been Credited",
  "mine.email.type.NEWCOMER SPECIAL": "Register Bonus",
  "mine.email.value.NEWCOMER SPECIAL": "Thank you for participating in the Newcomer Special Offer! The bonus amount of {amount} has been locked! Recharge to unlock the bonus! Withdraw unlocked bonus without turnover requirements! For more details, please see the event rules!",
  "mine.email.type.提款成功": "Withdrawal successful",
  "mine.email.value.提款成功": "Congratulations, you have successfully withdrawn {amount}. The bank usually transfers the funds within 24 hours. Please be patient! If you have not received the payment after 24 hours, please contact customer service!",
  "mine.email.type.提款失败": "Withdrawal failed",
  "mine.email.value.提款失败": "We are sorry, your withdrawal request failed. It may be due to an incorrect card number or maintenance on the payment channel. Please contact customer service to verify.",
  "mine.email.type.提现扣除活动赠送的金币": "Deduction of bonus coins from withdrawal",
  "mine.email.value.提现扣除活动赠送的金币": "Deduction of bonus coins from withdrawal: {amount}",
  "mine.email.tip.领取成功": "Claim Successful",

  "mine.accountdetail.账户明细": "Account Details",
  "mine.accountdetail.可提现": "Withdrawable",
  "mine.accountdetail.金额": "Amount",
  "mine.accountdetail.充值金币": "Recharge Coins",
  "mine.accountdetail.签到金币": "Sign-In Coins",
  "mine.accountdetail.充值金详情": "Recharge Coin Details",
  "mine.accountdetail.签到金详情": "Sign-In Coin Details",
  "mine.accountdetail.提现": "Withdraw",
  "mine.accountdetail.流水": "Wagering",
  "mine.accountdetail.余额": "Balance",
  "mine.accountdetail.无流水要求": "No wagering Requirement",
  "mine.accountdetail.流水倍数": "Wagering Multiplier * {rate}",
  "mine.accountdetail.加载中": "Loading",
  "mine.accountdetail.desc.13014": "The coins cannot be withdrawn. After completing 0.1 times turnover, you can participate in the event and withdrawal!",
  
  "mine.vip.VIP详情": "VIP Details",
  "mine.vip.成为VIP您可获得以下奖励": "Become a VIP to Receive the Following Rewards",
  "mine.vip.VIP奖金": "VIP Bonus",
  "mine.vip.提现福利": "Withdrawal Benefits",
  "mine.vip.下注返利": "Bet Rebate",
  "mine.vip.XP到下一个等级": "XP to the Next Level",
  "mine.vip.充值": "Recharge",
  "mine.vip.提现": "Withdraw",
  "mine.vip.下注": "Bet",
  "mine.vip.成为VIP可获取更多福利": "Become a VIP to Enjoy More Benefits!",
  "mine.vip.完成以下任意任务即可成为VIP": "Complete Any of the Following Tasks to Become a VIP",
  "mine.vip.充值xx以上": "Recharge More Than {amount}",
  "mine.vip.完成一次充值+一次提现": "Complete One Recharge + One Withdrawal",
  "mine.vip.代理收入达到xx以上": "Agent Income Reaches More Than {amount}",
  "mine.vip.维持当前VIP要求": "Maintain Current VIP Requirements",
  "mine.vip.下注金额超过xx": "Bet Amount Exceeds {symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "Current VIP Enjoys the Following Benefits",
  "mine.vip.升级奖金": "Upgrade Bonus",
  "mine.vip.可获得xx": "Can Receive {symbol}{amount}",
  "mine.vip.领取": "Claim",
  "mine.vip.已领取": "Claimed",
  "mine.vip.本周充值返利": "This Week's Recharge Rebate",
  "mine.vip.最小充值": "Minimum Recharge",
  "mine.vip.去充值": "Recharge",
  "mine.vip.提现返利": "Withdrawal Benefits",
  "mine.vip.每日最高可提现": "Daily Maximum Withdrawal",
  "mine.vip.游戏下注返利": "Game Bet Rebate",
  "mine.vip.slot": "Slot",
  "mine.vip.棋牌": "Card Games",
  "mine.vip.视讯": "Live casino",
  "mine.vip.体育": "Sports",

}
