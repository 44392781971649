export default {
  'acting.代理': 'Agente',
  'acting.详情': 'Detalhes',
  'acting.奖励': 'Recompensa',
  'acting.佣金': 'Comissão',
  'acting.规则': 'Regras',

  'acting.generalboard.邀请朋友来获取': 'Convide amigos para ganhar',
  'acting.generalboard.人': 'pessoas',
  'acting.generalboard.邀请奖励': 'Recompensa de Convite',
  'acting.generalboard.高达': 'Até',
  'acting.generalboard.佣金奖励': 'Recompensa de Comissão',
  'acting.generalboard.描述': 'Ganhe {amount} para cada amigo que você convidar, além de até 90% de comissão sobre suas apostas. Aproveite as comissões consistentes, ganhando ou perdendo. Comece a ganhar agora!',
  'acting.generalboard.邀请链接': 'Link de Convite',
  'acting.generalboard.分享到社交平台': 'Compartilhar em redes sociais',
  'acting.generalboard.拉新表现': 'Desempenho de Novos Convites',
  'acting.generalboard.今日拉新': 'Novos Convites Hoje',
  'acting.generalboard.本周拉新': 'Novos Convites Esta Semana',
  'acting.generalboard.直属拉新表现': 'Desempenho de Convites Diretos',
  'acting.generalboard.非直属拉新表现': 'Desempenho de Convites Indiretos',
  'acting.generalboard.下级详情': 'Detalhes de Subordinados',
  'acting.generalboard.下级总人数': 'Número Total de Subordinados',
  'acting.generalboard.直属下级人数': 'Número de Subordinados Diretos',
  'acting.generalboard.非直属下级人数': 'Número de Subordinados Indiretos',
  'acting.generalboard.总奖励': 'Recompensa Total',
  'acting.generalboard.总佣金': 'Comissão Total',
  'acting.generalboard.分享描述': 'Seja um agente, ganhe milhões por mês! Recompensa de convite {amount}, comissão de até 90%!',
  'acting.generalboard.tip.复制成功': 'Cópia bem-sucedida',

  'acting.reward.邀请里程碑': 'Marcos de Convite',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Receba a recompensa correspondente após atingir o número necessário de amigos convidados',
  'acting.reward.邀请': 'Convidar',
  'acting.reward.人': 'pessoas',
  'acting.reward.领取': 'Resgatar',
  'acting.reward.直属下级人数': 'Número de Subordinados Diretos',
  'acting.reward.邀请奖励': 'Recompensa de Convite',
  'acting.reward.直属下级绑定成功可获得': 'Receba ao conectar subordinados diretos com sucesso',
  'acting.reward.今日邀请': 'Convites Hoje',
  'acting.reward.总邀请': 'Total de Convites',
  'acting.reward.今日奖励': 'Recompensa de Hoje',
  'acting.reward.邀请总奖励': 'Recompensa Total de Convite',
  'acting.reward.今日邀请人数': 'Número de Convites Hoje',
  'acting.reward.邀请总人数': 'Número Total de Convites',
  'acting.reward.用户ID': 'ID do Usuário',
  'acting.reward.昵称': 'Apelido',
  'acting.reward.奖励': 'Recompensa',
  'acting.reward.展示前50条信息': 'Exibindo as primeiras 50 informações',
  'acting.reward.暂无记录': 'Sem Registros',
  'acting.reward.领取成功': 'Resgate bem-sucedido',

  'acting.commission.本周佣金信息': 'Informações de Comissão Desta Semana',
  'acting.commission.下级总充值': 'Total de Recarga dos Subordinados',
  'acting.commission.直属下级佣金': 'Comissão de Subordinados Diretos',
  'acting.commission.非直属下级佣金': 'Comissão de Subordinados Indiretos',
  'acting.commission.未领取佣金': 'Comissão não Resgatada',
  'acting.commission.领取佣金': 'Resgatar Comissão',
  'acting.commission.领取记录': 'Registro de Resgates',
}