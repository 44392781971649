// @ts-nocheck

import { createApp, onErrorCaptured } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'
import 'vant/lib/index.css'
import 'swiper/css'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import './style.css';
import i18n from './locale'
import Vant from '../node_modules/vant/lib';
import VueLazyload from 'vue-lazyload';



// 关闭全局console
if(process.env.VUE_APP_CUSTOM_ENV === 'production'){
    console.log = function () {};
}

if('serviceWorker' in navigator){
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }).catch(error => {
            console.error('ServiceWorker registration failed: ', error);
        });
    });
}

window.onNativeMessage = function(message:string) {
    // 初始化
    if(message.cmd === 'init'){
        sessionStorage.setItem('apk_init', JSON.stringify(message));
    };
};

createApp(App).use(store).use(router).use(i18n).use(VueLazyload,{
    loading: require('@/assets/image/loading.png'),
    error: require('@/assets/image/errero.png'),
    attempt: 1
}).use(Vant).mount('#app');