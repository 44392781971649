export default {
  "mine.退出登录": "ออกจากระบบ",
  "mine.用户ID": "รหัสผู้ใช้",
  "mine.XP到下一个等级": "อัพเกรดสู่ระดับถัดไป",
  "mine.vip": "VIP",
  "mine.充值": "เติมเงิน",
  "mine.下注": "เดิมพัน",

  "mine.menus.充值": "เติมเงิน",
  "mine.menus.提现": "ถอนเงิน",
  "mine.menus.账户明细": "บัญชี",
  "mine.menus.代理": "ตัวแทน",
  "mine.menus.每日签到": "เช็คอินรายวัน",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "วางเดิมพัน",
  "mine.menus.邮件通知": "อีเมล",
  "mine.menus.注册送": "โบนัสสมัคร",
  "mine.menus.tip.活动未开启": "กิจกรรมยังไม่เริ่ม",

  "mine.userdetail.用户ID": "รหัสผู้ใช้",
  "mine.userdetail.总输赢": "ชนะ/แพ้ทั้งหมด",
  "mine.userdetail.总下注": "เดิมพันทั้งหมด",
  "mine.userdetail.仅计算当月数据": "คำนวณเฉพาะข้อมูลของเดือนนี้",
  "mine.userdetail.最喜欢玩的3款游戏": "3 เกมโปรด",
  "mine.userdetail.用户信息": "ข้อมูลผู้ใช้",
  "mine.userdetail.绑定手机": "ผูกมัดโทรศัพท์",
  "mine.userdetail.绑定邮箱": "ผูกมัดอีเมล",
  "mine.userdetail.更换": "เปลี่ยน",
  "mine.userdetail.绑定": "ผูกมัด",

  "mine.replacename.用户名修改": "การแก้ไขชื่อผู้ใช้",
  "mine.replacename.用户名": "ชื่อผู้ใช้",
  "mine.replacename.请输入用户名": "กรุณาใส่ชื่อผู้ใช้",
  "mine.replacename.确认": "ยืนยัน",
  "mine.replacename.tip.请输入用户名": "กรุณาใส่ชื่อผู้ใช้",

  "mine.replaceemail.绑定邮箱": "ผูกมัดอีเมล",
  "mine.replaceemail.邮箱": "อีเมล",
  "mine.replaceemail.请输入邮箱": "กรุณาใส่อีเมล",
  "mine.replaceemail.确认": "ยืนยัน",
  "mine.replaceemail.tip.请输入邮箱": "กรุณาใส่อีเมล",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "กรุณาใส่อีเมลที่ถูกต้อง",

  "mine.betrecord.下注记录": "บันทึกการเดิมพัน",
  "mine.betrecord.最多展示前100条下注详情": "แสดงรายละเอียดการเดิมพันสูงสุด 100 รายการ",
  "mine.betrecord.暂无下注记录": "ไม่มีบันทึกการเดิมพัน",
  "mine.betrecord.下注号": "หมายเลขเดิมพัน",
  "mine.betrecord.时间": "เวลา",
  "mine.betrecord.下注金额": "จำนวนเงินเดิมพัน",
  "mine.betrecord.输赢": "ชนะ/แพ้",
  
  "mine.email.邮件通知": "การแจ้งเตือนทางอีเมล",
  "mine.email.已领取": "รับแล้ว",
  "mine.email.未领取": "ยังไม่ได้รับ",
  "mine.email.收起": "ย่อ",
  "mine.email.展开": "ขยาย",
  "mine.email.暂无邮件": "ไม่มีอีเมล",
  "mine.email.type.VIP返利提取成功": "เงินคืน VIP",
  "mine.email.value.VIP返利提取成功": "รับเงินคืนสำเร็จ {amount}",
  "mine.email.type.幸运签到": "เช็คอินรายวัน",
  "mine.email.value.幸运签到": "ได้รับเหรียญรางวัล {amount}",
  "mine.email.type.NEWCOMER SPECIAL": "โบนัสสมัคร",
  "mine.email.value.NEWCOMER SPECIAL": "ขอบคุณที่เข้าร่วมกิจกรรมสำหรับผู้ใช้ใหม่! จำนวนเงินโบนัส {amount} ถูกล็อคไว้! เติมเงินเพื่อปลดล็อกโบนัส! ถอนเงินที่ปลดล็อกได้โดยไม่มีข้อกำหนดการหมุนเวียน! ดูกฎเพิ่มเติมในรายละเอียดกิจกรรม!",
  "mine.email.type.提款成功": "ถอนเงินสำเร็จ",
  "mine.email.value.提款成功": "ขอแสดงความยินดี คุณได้ทำการถอนเงินเรียบร้อยแล้ว {amount} หากสถานะการถอนสำเร็จ แต่คุณยังไม่ได้รับเงินจริง ธนาคารมักจะโอนเงินภายใน 24 ชั่วโมง โปรดอดทน! หากคุณยังไม่ได้รับเงินหลังจาก 24 ชั่วโมงโปรดเข้าสู่เกมและติดต่อฝ่ายบริการลูกค้า!",
  "mine.email.type.提款失败": "ถอนเงินล้มเหลว",
  "mine.email.value.提款失败": "ขออภัย การถอนเงินของคุณล้มเหลว อาจเกิดจากหมายเลขบัตรผิดพลาดหรือช่องทางการชำระเงินอยู่ระหว่างการบำรุงรักษา กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อตรวจสอบ",
  "mine.email.type.提现扣除活动赠送的金币": "หักเหรียญโบนัสออกจากการถอนเงิน",
  "mine.email.value.提现扣除活动赠送的金币": "หักเหรียญโบนัสจากการถอนเงิน: {amount}",
  "mine.email.tip.领取成功": "รับสำเร็จ",

  "mine.accountdetail.账户明细": "รายละเอียดบัญชี",
  "mine.accountdetail.可提现": "ถอนเงินได้",
  "mine.accountdetail.金额": "จำนวน",
  "mine.accountdetail.充值金币": "เหรียญเติมเงิน",
  "mine.accountdetail.签到金币": "เหรียญเช็คอิน",
  "mine.accountdetail.充值金详情": "รายละเอียดเหรียญเติมเงิน",
  "mine.accountdetail.签到金详情": "รายละเอียดเหรียญเช็คอิน",
  "mine.accountdetail.提现": "ถอนเงิน",
  "mine.accountdetail.流水": "หมุนเวียน",
  "mine.accountdetail.余额": "ยอดคงเหลือ",
  "mine.accountdetail.无流水要求": "ไม่มีข้อกำหนดการหมุนเวียน",
  "mine.accountdetail.流水倍数": "อัตราการหมุนเวียน * {rate}",
  "mine.accountdetail.加载中": "กำลังโหลด",
"mine.accountdetail.desc.13014": "ไม่สามารถถอนเหรียญได้ เมื่อทำเทิร์นโอเวอร์ครบ 0.1 เท่าแล้ว คุณสามารถเข้าร่วมกิจกรรมและถอนเงินได้!",
  
  "mine.vip.VIP详情": "รายละเอียด VIP",
  "mine.vip.成为VIP您可获得以下奖励": "เป็น VIP เพื่อรับรางวัลดังต่อไปนี้",
  "mine.vip.VIP奖金": "โบนัส VIP",
  "mine.vip.提现福利": "สิทธิพิเศษในการถอนเงิน",
  "mine.vip.下注返利": "เงินคืนจากการเดิมพัน",
  "mine.vip.XP到下一个等级": "อัพเกรดสู่ระดับถัดไป",
  "mine.vip.充值": "เติมเงิน",
  "mine.vip.提现": "ถอนเงิน",
  "mine.vip.下注": "เดิมพัน",
  "mine.vip.成为VIP可获取更多福利": "เป็น VIP เพื่อรับสิทธิประโยชน์เพิ่มเติม!",
  "mine.vip.完成以下任意任务即可成为VIP": "ทำภารกิจต่อไปนี้เพื่อเป็น VIP",
  "mine.vip.充值xx以上": "เติมเงินเกิน {amount}",
  "mine.vip.完成一次充值+一次提现": "ทำการเติมเงินหนึ่งครั้ง + ถอนเงินหนึ่งครั้ง",
  "mine.vip.代理收入达到xx以上": "รายได้จากตัวแทนถึงเกิน {amount}",
  "mine.vip.维持当前VIP要求": "รักษาสถานะ VIP ปัจจุบัน",
  "mine.vip.下注金额超过xx": "จำนวนเงินเดิมพันเกิน {symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "VIP ปัจจุบันได้รับสิทธิพิเศษดังต่อไปนี้",
  "mine.vip.升级奖金": "โบนัสเลื่อนระดับ",
  "mine.vip.可获得xx": "รับได้ {symbol}{amount}",
  "mine.vip.领取": "รับ",
  "mine.vip.已领取": "รับแล้ว",
  "mine.vip.本周充值返利": "เงินคืนเติมเงินสัปดาห์นี้",
  "mine.vip.最小充值": "เติมเงินขั้นต่ำ",
  "mine.vip.去充值": "ไปเติมเงิน",
  "mine.vip.提现返利": "สิทธิพิเศษในการถอนเงิน",
  "mine.vip.每日最高可提现": "ถอนเงินสูงสุดต่อวัน",
  "mine.vip.游戏下注返利": "เงินคืนจากการเดิมพันเกม",
  "mine.vip.slot": "สล็อต",
  "mine.vip.棋牌": "ไพ่",
  "mine.vip.视讯": "คาสิโนสด",
  "mine.vip.体育": "กีฬา",
}
