import { getConfig } from "@/utils/function";

const game = {
  namespaced: true,
  state () {
    return {
      game_list: undefined,
      play_game_url: undefined,
      current_game: undefined
    }
  },
  mutations: {
    // 设置游戏列表
    setGameList(state:any, game_list:any) {
      state.game_list = game_list;
    },
    // 设置游戏地址
    setPlayGameUrl(state:any, play_game_url:any) {
      state.play_game_url = play_game_url;
    },
    // 设置当前游戏
    setCurrentGame(state:any, current_game:any) {
      state.current_game = current_game;
    },
    // 重置游戏地址
    resetPlayGameUrl(state:any) {
      state.play_game_url = undefined;
      state.current_game = undefined;
    }
  },
  getters: {
    // 获取对应游戏分类列表
    getGameList(state:any) {
      let {appKey} = getConfig();

      // 筛选出重复的游戏，切保留下标靠后的最新游戏数据
      let result:any = [];
      let seenIds:any = {};
      for (let i = state.game_list?.length - 1; i >= 0; i--) {
        let currentItem = state.game_list[i];
        // 增加判断 app_key 是否等于 appKey
        if (!seenIds[currentItem.id] && currentItem.app_key === appKey) {
            result.unshift(currentItem);
            seenIds[currentItem.id] = true;
        }
      }
      state.game_list = result;



      // 筛选出status为1的游戏
      var enabledGames = state.game_list?.filter((game:any) => game.status === 1 && game.version_list?.includes('Web'));



      // 筛选重复的game_id进行剔除
      // let uniqueGameIds:any = [];
      // enabledGames = enabledGames?.filter((item:any) => {
      //   if (item.parent_id) {
      //       return false;
      //   };
      //   if (!uniqueGameIds.includes(item.game_id)) {
      //     uniqueGameIds.push(item.game_id);
      //     return true;
      //   }
      //   return false;
      // });

      

      // 按照category分类
      const categorizedGames = enabledGames?.reduce((acc:any, game:any) => {
          // 检查是否需要添加到hot分类
          if (game.is_hot === 1) {
              if (!acc['hot']) {
                  acc['hot'] = [];
              }
              acc['hot'].push(game);
          }

          // 添加到对应的category分类
          if (!acc[game.category]) {
              acc[game.category] = [];
          }

          acc[game.category].push(game);

          return acc;
      }, {});

      // 如果hot分类不存在，创建一个空的hot分类
      if(categorizedGames && !categorizedGames['hot']){
        categorizedGames['hot'] = [];
      }

      // 按照game_sort排序
      for (const category in categorizedGames) {
        categorizedGames[category].sort((a:any, b:any) => b.game_sort - a.game_sort);
      }

      return categorizedGames;
    }
  }
}

export default game;