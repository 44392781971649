export default {
    'login.banner.text1': 'Welcome to join',
    'login.banner.text2': '7WIN.GAME',
    'login.banner.text3': 'Log in to start your gaming journey',

    'login.login': 'Login',
    'login.telnumber': 'Phone Number',
    'login.password': 'Password',
    'login.button': 'Login',
    'login.forgot': 'Forgot Password?',
    'login.register.text': "Don't have an account?",
    'login.register': 'Register Now',
    'login.toast.请输入正确的手机号码': 'Please enter a valid phone number',
    'login.toast.请输入密码': 'Please enter a password',
    'login.toast.密码不正确或者用户不存在': 'Incorrect password or user does not exist',

    'register.register': 'Register',
    'register.telnumber': 'Phone Number',
    'register.password': 'Password',
    'register.confirmpassword': 'Confirm Password',
    'register.agree': 'I Agree to the',
    'register.agreement': 'User Agreement',
    'register.sendcode': 'Send Verification Code',
    'register.haveaccount': 'Already have an account?',
    'register.login': 'Log in Now',
    'register.code': 'Verification Code',
    'register.nocode': 'Did not receive the code?',
    'register.signin': 'Sign In',
    'register.toast.验证码已发送': 'Verification code has been sent',
    'register.toast.请同意用户协议': 'Please agree to the User Agreement',
    'register.toast.请填写完整信息': 'Please fill out all the information',
    'register.toast.请输入正确的手机号': 'Please enter a valid phone number',
    'register.toast.密码不能少于6位': 'Password cannot be less than 6 characters',
    'register.toast.两次密码不一致': 'Passwords do not match',

    'find.forgot': 'Forgot Password',
    'find.telnumber': 'Phone Number',
    'find.sendcode': 'Send Verification Code',
    'find.haveaccount': 'Already have an account?',
    'find.login': 'Log in Now',
    'find.password': 'Password',
    'find.confirmpassword': 'Confirm Password',
    'find.resetpassword': 'Reset Password',
    'find.code': 'Verification Code',
    'find.nocode': 'Did not receive the code?',
    'find.toast.请输入正确的手机号码': 'Please enter a valid phone number',
    'find.toast.验证码已发送': 'Verification code sent',
    'find.toast.该号码未注册': 'This number is not registered',
    'find.toast.频繁发送短信': 'SMS sending too frequently',
    'find.toast.密码不能少于6位': 'Password cannot be less than 6 characters',
    'find.toast.两次密码不一致': 'Passwords do not match',
    'find.toast.请输入验证码': 'Please enter the verification code',
    'find.toast.密码重置成功': 'Password reset successfully',
    'find.toast.验证码不正确': 'Incorrect verification code',



    'agreement.title': 'User Agreement',
    'agreement.text': `
        Definitions
        7WIN.GAME is referred to as "we" or "us"
        Players are referred to as "you" or "players"
        "Website" refers to players of 7WIN.GAME that utilize it through desktop, mobile or other platforms
        1. Overview
        • 1.1. This User Agreement applies to the use of games accessed through 7WIN.GAME.
        • 1.2. This User Agreement takes effect immediately after you complete the registration process (including ticking to accept this User Agreement and successfully creating an account). By using any part of the Website after creating an account, you agree to this User Agreement.
        • 1.3. You must read the entire User Agreement carefully before creating an account. If you do not agree to any of the terms of this User Agreement, you must not create an account or continue to use the Website.
        • 1.4. We reserve the right to modify this User Agreement at any time without prior notice. If we make such modifications, we may take appropriate measures to draw your attention to such changes (such as by email or placing a prominent notice on the Website with the revised User Agreement), but it is up to you to check any modifications, updates and/or modifications at your own discretion. Your continued use of the Services and 7WIN.GAME Website after any modification to the User Agreement will be deemed as your acceptance and agreement to be bound by such modifications, updates and/or such amendments.
        • 1.5. This User Agreement may be published in multiple languages ​​for reference and ease of access for players. The English version is the sole legal basis for the relationship between you and us and in the event of any discrepancy in translation, the English version of the User Agreement shall prevail.
        2. BINDING NOTICE
        2.1. By agreeing to be bound by this User Agreement, you also agree to be bound by the 7WIN.GAME Rules and Privacy Policy which are incorporated into this User Agreement by reference. In the event of any inconsistency, this User Agreement shall prevail. You represent and warrant that:
        • 2.1.1. You are at least (a) 18 years of age or (b) other legal age or age of majority as determined by any law applicable to you, whichever is greater;
        • 2.1.2. You have full capacity to enter into a legally binding agreement with us and you are not subject to any form of limited legal capacity;
        • 2.1.3. All information you provide to us during the term of this Agreement is true, complete and accurate, and you must promptly notify us of any changes to such information;
        • 2.1.4. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings you receive from us;
        • 2.1.5. You understand that when using our services, you may lose funds deposited into your member account and agree that you are fully responsible for any such losses;
        • 2.1.6. You are authorized to use online casino services in the jurisdiction in which you reside;
        • 2.1.7. For deposits and withdrawals from your member account, you may only use electronic currency that is valid and legally yours;
        • 2.1.8. You understand that the value of cryptocurrencies may vary greatly depending on market value;
        • 2.1.9. The computer software, computer graphics, website and user interface that we provide to you are owned by 7WIN.GAME or its affiliates and are protected by copyright laws. You may use the Software only for your own personal entertainment purposes in accordance with all rules established by us, the User Agreement and all applicable laws, rules and regulations;
        • 2.1.10. You understand that cryptocurrencies are not considered legal tender or currency and therefore on the Site they are treated as virtual currencies with no intrinsic value.
        • 2.1.11. You confirm that you are not an officer, director, employee, consultant or agent of 7WIN.GAME, nor an employee of any company related to 7WIN.GAME, nor a relative or spouse of any of the foregoing;
        • 2.1.12. You have not been diagnosed or classified as a compulsive or problem gambler. We are not responsible if such gambling problems arise while using our Services, but will do our best to provide relevant support. We reserve the right to impose a cooling-off period if we deem such action beneficial.
        • 2.1.13. You accept and acknowledge that we have the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automatic registration and sign-up, game technology games and screenshots. These steps may include, but are not limited to, checking the player's device attributes, geolocation detection and IP masking, transaction and blockchain analysis;
        • 2.1.14. You accept our right to terminate and/or change any game or activity offered on the Website and to refuse and/or limit bets.
        • 2.1.15. You accept that we have the right to ban/block multiple accounts and freely control the assets in these accounts.
        • 2.1.16. You understand that there may be errors or defects in the software and you agree not to profit from them. In addition, you agree to report any errors or incompleteness to 7WIN.GAME immediately. If you fail to fulfill your obligations under these Terms, 7WIN.GAME is entitled to full compensation for all costs related to errors or incompleteness, including any costs incurred due to erroneous/incomplete and unsuccessful messages to users.
        • 2.1.17. You understand that 7WIN.GAME has the right to perform a "KYC" (Know Your Customer) verification process. If we determine that you have provided false or misleading information, access to your user account may be blocked or closed.
        2.2. If 7WIN.GAME, at its sole discretion, deems it apparent that any of the following circumstances have occurred, we reserve the right to declare any bet void in whole or in part:
        • 2.2.1. You or someone associated with you may directly or indirectly influence the outcome of an event to gain an illegal advantage.
        • 2.2.2. You and/or someone associated with you directly or indirectly circumvent the regulations of 7WIN.GAME.
        • 2.2.3. The outcome of an event is directly or indirectly influenced by criminal activity.
        • 2.2.4. Bets that would not otherwise have been accepted are accepted when the website is affected by technical issues.
        • 2.2.5. Bets have been offered, placed and/or accepted due to an error, security breach, technical error, force majeure or other reason.
        • 2.2.6. If a player's deposit fee is too low and is marked as "not enough to forward" by blockchain or similar websites, 7WIN.GAME reserves the right to confiscate the winnings if 7WIN.GAME, at its sole discretion, claims that the player's transactions and behavior are fraudulent in nature.
        3. Limited Territory
        • 3.1. Blacklisted regions: China, Netherlands, Dutch Caribbean Islands, Hungary, Australia, Ontario (Canada), Curacao, France, United States and/or any other restricted regions under national or state laws. Please note that it is strictly prohibited to play 7WIN.GAME games in the above blacklisted countries. Your personal data is for the purpose of fulfilling its duties and providing you with the best support and services. You agree to such disclosure.
        4. General Betting Rules
        • 4.1. Bets can only be placed by registered account holders.
        • 4.2. Bets can only be placed via the Internet.
        • 4.3. You may place bets only if you have a sufficient balance in your 7WIN.GAME account.
        • 4.4. Once placed, bets will be subject to the version of the User Agreement that is valid and available on the Website at the time of accepting the bet.
        • 4.5. Any payouts on winning bets will be credited to your account, including the stake multiplied by the odds.
        • 4.6. 7WIN.GAME reserves the right to adjust bet payments deposited into your 7WIN.GAME account if 7WIN.GAME determines, in its sole discretion, that such bet payments are in error.
        • 4.7. Once a bet is placed and accepted, you will not be able to modify, reverse or cancel it.
        • 4.8. You can view a list of all bets and their status and details on the Website.
        • 4.9. When you place a bet, you acknowledge that you have read and fully understand all User Agreements set out on the Website relating to betting.
        • 4.10. 7WIN.GAME manages your account and calculates available funds, pending funds, bet amounts, and winnings. These amounts are deemed final and are deemed accurate unless proven otherwise.
        • 4.11. You are solely responsible for the amounts betted.
        • 4.12. Winnings will be paid into your account once the final results are confirmed.
        5. Bonuses and Promotions
        • 5.1. 7WIN.GAME reserves the right to immediately cancel any promotion, bonus or award (including but not limited to deposit bonuses, refer a friend bonuses and loyalty programs) if we believe that the bonus has been incorrectly set up or abused. We reserve the right to refuse any withdrawal request and deduct the amount from your account if said bonus has already been paid out. Whether a bonus is considered to be incorrectly set up or abused will be determined solely by 7WIN.GAME.
        • 5.2. If you use a deposit bonus, no withdrawals will be accepted on the initial deposit until you have met the requirements set out in the Deposit Bonus User Agreement.
        • 5.3. If any terms of the offer or promotion are breached or there is any evidence that a customer or a group of customers has placed a series of bets as a result of a deposit bonus, advance payment, free bet, risk free bet or any other promotional offer, regardless of the outcome, whether individually or as part of a group, 7WIN.GAME reserves the right to reclaim the bonus factored into such offer and have absolute discretion to settle bets at the correct odds, cancel free bet bonuses and risk free bets or cancel any bets supported by the deposit bonus. In addition, 7WIN.GAME reserves the right to charge customers an administration fee up to the value of the deposit bonus, free bet bonus, risk free bet or additional payment to cover administration costs. We further reserve the right to require any customer to provide satisfactory customer identification before any bonus, free bet or bonus fee, risk free bet or reward is credited to the customer's account.
        • 5.4. All 7WIN.GAME offers are intended for casual players and 7WIN.GAME may, at its sole discretion, limit customer eligibility to participate in all or part of any promotion.
        • 5.5. 7WIN.GAME reserves the right to modify, cancel, revoke or refuse any promotion at its sole discretion.
        • 5.6. Bonuses may only be claimed once per person/account, family, household, address, email address, IP address and environment where computers are shared (university, fraternity, school, public library, workplace, etc.). If evidence of abuse/fraud is found, the Operator reserves the right to close your account and confiscate any available funds.
        • 5.7. You acknowledge and understand that separate user agreements exist in relation to promotions, bonuses and special offers and are in addition to this User Agreement. This User Agreement is set out on the relevant content page of the Website or provided to you in person, as the case may be. In the event of a conflict between the provisions of such promotions, bonuses and special offers and the provisions of this User Agreement, the provisions of such promotions, bonuses and special offers shall prevail.
        • 5.8. We may require you to make a deposit of a certain amount before you can wager any free/bonus funds we deposit into your account.
        • 5.9. You agree that certain promotions may be subject to restrictions and/or withdrawal requirements, which must be met before you can withdraw funds deposited in the promotion. Such terms will be formally published and provided as part of the promotion. If you choose to withdraw before meeting the applicable wagering requirements, we will deduct the full bonus amount and any winnings associated with the use of the bonus amount before approving any withdrawal.
        6. Online Support
        6.1. As part of your use of the Website, we may provide you with a live chat facility managed and controlled by us. We reserve the right to review the conversation and keep a record of all statements made in the facility. Your use of the chat facility must be for entertainment and social purposes.
        6.2. We reserve the right to remove the chat room feature or immediately terminate your membership account and refund your account balance if you:
        • (a) make any obscene or grossly offensive remarks, including expressions of bigotry, racism, hatred or vulgarity;
        • (b) make abusive, defamatory, harassing or offensive remarks;
        • (c) use the chat tool to advertise, promote or otherwise relate to any other online entity;
        • (d) make untrue and/or malicious and/or damaging statements about 7WIN.GAME or any other Internet site connected to this Site;
        • (e) use the chat tool to collude, engage in illegal conduct or encourage conduct that we deem inappropriate. Any suspicious conversations will be reported to the relevant authorities.
        6.3. Online support is used as a form of communication between us and you and may not be copied or shared with any forum or third party.
        7. Limitation of Liability
        • 7.1. You enter the Website and participate in the Games at your own risk. The Website and the Games are provided without warranty of any kind, whether express or implied.
        • 7.2. Without prejudice to the generality of the foregoing, we, our directors, employees, partners, service providers.
        • 7.3. No warranty is given that the Software, Games and Website are fit for purpose.
        • 7.4. No warranty is given that the Software, Games and Website are error-free.
        • 7.5. No warranty is given that the Software, Games and Website will be accessible without interruption.
        • 7.6. No liability is accepted for any loss, cost, expense or damage, whether direct, indirect, special, consequential, incidental or otherwise, arising out of the use of the Website or participation in the Games.
        • 7.7. You understand and acknowledge that in the event of a malfunction in the Games or their interoperability, any bets placed at the time of such malfunction will be void. Funds collected from a malfunctioning game will be deemed void and any subsequent game rounds involving such funds will also be deemed void, regardless of which game was played using such funds.
        • 7.8. You hereby agree to fully indemnify and hold us, our directors, employees, partners and service providers harmless from all costs, expenses, losses, damages, claims and liabilities that may arise from your use of the Website or participation in the Games.
        • 7.9. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Website, regardless of the cause of action (whether under contract, breach of warranty or otherwise), shall not exceed €100.
        8. Breach, Penalties and Termination
        • 8.1. If you breach any of the terms of this User Agreement, or we have reasonable grounds to suspect that you have breached these terms, we reserve the right not to open, suspend or close your member account or withhold your winnings and apply them to fund any losses caused by you.
        • 8.2. You acknowledge that 7WIN.GAME will make the final decision as to whether you have breached 7WIN.GAME's rules, terms or conditions, which may result in your suspension or permanent ban from our website.
        9. Self-Exclusion
        • 9.1. By requesting a Self-Exclusion period, you agree to be bound by the following terms and conditions, which will come into effect from the time the Coordinator implements the selected Self-Exclusion period.
        • 9.2. You can self-exclude for 1, 3, 6, 12 months or permanently. Self-Exclusion requests must be made through Live Support.
        • 9.3. Once you have self-excluded, you will not be able to access your account or withdraw funds during this period.
        • 9.4. If you have excluded your account and there are pending bets in your account, bets already placed will remain valid and paid out based on official results.
        • 9.5. After the Self-Exclusion period has ended, you will be able to withdraw winnings from eligible bets. 7WIN.GAME will not cancel or void any bets placed before the Self-Exclusion was effected.
        • 9.6. Once you have self-excluded, you will not be able to change or amend a shorter period, nor cancel your Self-Exclusion, until the Self-Exclusion period you have selected has passed.
        • 9.7. If you wish to extend your Self-Exclusion period, please contact our Customer Service Department.
        • 9.8. Once your self-exclusion period is over, you may reinstate your account by contacting 7WIN.GAME’s in-game customer service.
        • 9.9. By self-excluding, you agree that:
        o a) You will not create another account during this period.
        o b) You will not deposit or attempt to deposit funds into your 7WIN.GAME account.
        o c) You may not place bets on the site during this period.
        o d) This is a voluntary action on your part and 7WIN.GAME is not responsible for any losses you may incur in any way during the self-exclusion period.


        Privacy Policy
        You hereby acknowledge and accept that we may collect and use your personal data if we deem it necessary to enable you to access and use the Website and participate in the Games.
        We confirm that we are subject to the Data Protection Act when collecting your personal information as provided in the preceding paragraph. We will protect your personal information and respect your privacy in accordance with best business practices and applicable laws.
        We will use your personal data to enable you to participate in the Games and carry out activities related to your participation in the Games. We may also use your personal data to inform you of changes, new services and promotions that we think may be of interest to you. If you do not wish to receive such direct marketing emails, you can opt-out of the service.
        Your personal data will not be disclosed to third parties unless required by law. Personal data may be disclosed to Surname as 7WIN.GAME business partners or suppliers or service providers may be responsible for certain features or general operations of the Website. 7WIN.GAME employees may have access to your personal data in order to perform their duties and provide you with the best support and service. You consent to such disclosure.
        We will retain all information provided as personal data. You have the right to access the personal data we hold about you. No data will be destroyed unless required by law or unless it is no longer necessary to retain the information held for relationship purposes.
        In order to make your visit to the website more convenient, track visits to the website and improve the service, we collect a small piece of information sent from your browser, called cookies. If you wish, you can disable cookie collection. However, you must be aware that disabling cookies may severely limit or completely prohibit your use of this website.
        Cookie Policy
        1. What are cookies?
        • A cookie is a very small piece of information in the form of a text file that is placed on an internet user's computer. It is created by a website server (basically the computer that runs the website) and can be used by that server whenever a user visits the website. Cookies can be thought of as an identification tag for internet users that can tell a website when a user returns. Cookies do not harm your computer and we do not store any personally identifiable information about you on any cookie.
        2. Why do we use cookies on 7WIN.GAME?
        • 7WIN.GAME uses two types of cookies: cookies set by us and cookies set by third parties (i.e. other websites or services). 7WIN.GAME cookies allow us to maintain your account login status during your visit and tailor the information displayed on the website to your preferences.
        3. What cookies do we use on 7WIN.GAME?
        Here is a list of the main cookies set by 7WIN.GAME and the purpose of each cookie:
        • _fp - stores browser fingerprint. Lifespan: forever.
        • _t - stores timestamp of the user's first visit to the website during the current browsing session. Required for unique visit statistics. Lifespan: browsing session.
        • _r - stores the http referrer URL for the current browsing session. Required to track external traffic sources. Lifespan: browsing session.
        • _c - stores an identifier for the agent program. Required for agent statistics. Lifespan: forever.
        • Cookies set by third parties for wildcard domains: *.7WIN.GAME
        • Google Analytics: _ga, _gat, _gid
        • Zendesk: __zlcmid
        • Cloudflare: __cfuid
        • Note that some browsers (such as Chrome on Mac) keep background processes running even when no tabs are open, as session cookies may be set between sessions.
        • There are also cookies set by third-party scripts for their domains.
        4. How do I manage my cookies on 7WIN.GAME?
        • If you wish to stop accepting cookies, you can do so through the privacy settings option in your browser.
        5. Personal Data Protection Policy
        • 7WIN.GAME's mission is to keep your data safe, and we do this in a number of different ways. We offer our customers high security standards, such as encryption of data in motion on public networks, encryption of data in databases, audit standards, and denial of service chat and online chat mitigation.
        6. Server Protection Policy
        • All servers are fully encrypted;
        • All backups are encrypted;
        • Firewall, VPN access;
        • Access to servers is only allowed through VPN;
        • All http/s services are run on Cloudflare;
        • Connect to nodes via VPN;
        • SSH port forwarding tunnel;
        • Services are only allowed through VPN;
        • Servers have firewalls that only allow SSH ports;
        • Alerts about important services.
        • Data Breach Notification
        • When 7WIN.GAME discovers a personal data breach, we will notify the relevant users in accordance with the GDPR time limits.
        7. International Data Transfers
        • We disclose personal data to third parties only when necessary to provide high-quality services or to respond to lawful requests from authorities.
        • We share the following data with third-party systems:
        • Zendesk Corporation – If a user sends a message to the live chat or an email to the support inbox, the username and email information will be transmitted.
        • Despite our best efforts, sometimes problems occur. Our team will make every effort to resolve your issue as quickly as possible. To provide you with faster support, you can join us in the Telegram group by clicking the button above.
        • If an error occurs, please provide the following information:
        o Login name
        o Date and time of the event
        Game code or table name (if available)
        Error screenshot (if available)
        We appreciate your help and the error messages you provide, as your reports can help us improve.
        Collection and use of your personal data
        Categories of collected data
        Personal data
        While using our Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
        Email
        Name
        Usage data
        Usage data
        Usage data is automatically collected when you use the Services.
        Usage data may include information such as your device's Internet Protocol address (e.g. IP address), browser type, browser version, and the pages of our Services that you visit, the date and time of your visit, and the time spent on those pages. , unique device identifiers and other diagnostic data.
        When you access the Services through a mobile device, we may automatically collect certain information, including, but not limited to, the type of mobile device you use, your mobile device's unique ID, your mobile device's IP address, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
        We may also collect information sent by your browser when you visit our Services or access the Services through a mobile device.
        Information from third-party social networking services
        7WIN.GAME allows you to create an account and log in to use the Services through the following third-party social networking services:
        • Google
        • Facebook
        • Telegram
        • Metamask
        8. Network 3.0
        If you decide to register through a third-party social networking service or allow us to access a third-party social networking service, we may collect personal data associated with your third-party social networking service account, such as your name, email address, your activities. Or your contacts associated with that account.
        You may also choose to share additional information with 7WIN.GAME through your third-party social networking service account. If you choose to provide such information and personal data during the registration process or otherwise, you grant 7WIN.GAME the right to use, share and store such information in a manner consistent with this Privacy Policy.
        Deleting Personal Data
        If 7WIN.GAME no longer has a legal reason to continue processing or storing your personal data, you can request the deletion of your personal data. Please note that this right is not guaranteed - if 7WIN.GAME needs to comply with a legal obligation regarding the storage of your data, 7WIN.GAME is unlikely to comply with your request. You can contact 7WIN.GAME Customer Service to request the deletion of your personal data.
        Register and Log In
        You must be at least 18 years old to register. If you would like to add an email address, please ensure that the email address you enter is correct so that it can be used for account KYC verification later.
        You can log in at any time. For increased security, we recommend adding two-step authentication. Learn more about Google Authenticator.
        If you need to change your registration email, we are sorry, but we are unable to change this information. If you still want to change your username and/or registration email, we recommend that you close your current account and register a new one.

        7WIN.GAME Privacy Policy
        The 7WIN.GAME Privacy Policy is intended to describe the 7WIN.GAME Service (as defined in the 7WIN.GAME Terms of Use) and our privacy practices where these principles are displayed or referenced.
        This Privacy Policy sets out how 7WIN.GAME will manage the personal data we collect as a result of your membership of 7WIN.GAME, your use of our website located at https://7WIN.GAME, and any pages or websites under the 7WIN.GAME brand, including any mobile or tablet applications owned and/or operated by us or where we transact with you. We are committed to ensuring that the personal information we collect about you is protected, used, stored and disclosed in accordance with applicable privacy laws and this Privacy Policy.
        Personal Data Collected from You
        When you apply for trade credit, activate our services, register for services on the 7WIN.GAME website, connect to our services, contact us (including social networks), participate in online surveys, or otherwise interact with 7WIN.GAME, we may collect a variety of information, including:
        1. Account Information. Your 7WIN.GAME username and related account details, including email address, registered device, account status, and age.
        2. Device Information. Data that identifies your device, such as your device serial number, or data about your device, such as browser type.
        3. Contact Information. Data such as name, email address, physical address, phone number, or other contact information.
        4. Payment Information. Data about your billing address and payment method, such as bank details, credit, debit, or other payment card information.
        5. Fraud Prevention Information. Data used to help identify and prevent fraud, including device reliability and KYC information.
        6. Usage Data. Data about your activities and use of our services, such as service launches, including browsing history; product interactions; crash, performance, and other diagnostic data; and other usage data.
        7. Identification Data. In some jurisdictions, we may request government-issued identification in limited circumstances, including to activate our services for you, for commercial credit renewal purposes, reservation management, or as required by law.
        8. Other Information You Provide to Us. Details, such as your communications with Apple, including interactions with customer support and connections through social media channels.
        In some cases, 7WIN.GAME may collect "sensitive" personal information. (including information about race and nationality, political opinions, religious beliefs and other beliefs of a similar nature, trade union membership and information about sex life or sexual orientation), or health information, and information related to alleged or proven criminal information where permitted by law.
        Use of Personal Data
        We may combine and use the information we hold about you (including information received on and off our services) to understand how you use and interact with our services, and to understand who you are connected to and interested in providing, personalizing and improving our services. Services. We may also use the information we hold about you globally for the following purposes:
        1. Provide, maintain, improve and develop features, content and related services.
        2. Analyze your content and other information.
        3. Fulfill your requests and use with your permission.
        4. Help advertisers and application/website operators connect and place relevant advertisements in their applications and websites.
        5. Combine and deliver targeted marketing ads (regardless of the device or use of our services), and provide targeted marketing ads based on device activity, estimated interests, and your target information.
        6. Contact you and provide account-related information or send marketing information based on your preferences.
        7. Link your activities across our different services and different devices, and link all accounts you may use across different 7WIN.GAME services. We can link activities and accounts under one login.
        8. Conduct or support marketing promotions.
        9. Conduct research and support innovation.
        10. Provide analysis and reports on the use and trends of our services and advertisements to external parties (including partners, application/website operators, advertisers, applications, third parties, and the public), including display and reporting to partners, advertising performance and user experience information. These analyses and reports may include aggregated or anonymous information.
        11. Provide services, advertisements, search results, etc. that are consistent with your established goals based on your target information.
        12. Combine the information we have about you with information we get from our business partners or other companies (such as your activity on other websites and applications).
        13. Detect and prevent fraud, abuse or illegal activity.
        Sharing and disclosure of personal data
        1. Disclosure to other data controllers
        Our company will share your personal information within the 7WIN.GAME chain of companies to achieve the above purposes.
        We will also disclose your personal information to other third-party data controllers with your consent (if required by applicable law), or in order to provide you with any services (such as third-party integrations). Third-party data controllers may also use the services to collect and process your personal information. If you access the 7WIN.GAME service using an email address associated with a business domain, our company may provide your personal information to that business.
        2. Disclosure of information for fraud prevention and security purposes
        We will disclose personal information to companies that help us operate our business to detect, prevent or address fraud, deception, illegal activity, abuse of the 7WIN.GAME service, and security or technical issues.
        In addition, if the company believes in good faith that access, use, preservation or disclosure of information is reasonably necessary to detect, prevent or avoid fraud or fraudulent activities, or illegal, abuse of services and software, or there are security or technical issues, or to the extent reasonably necessary to protect the rights, property or safety of employees, 7WIN.GAME users, children or the public as permitted by law and regulations, our company will disclose personal information to other companies, organizations, government agencies or individuals outside of our territory.
        3. Disclosure to Data Processors
        For the purposes described above, we will also disclose your personal information to companies that process personal information on our behalf to assist us in conducting our business. These companies include customer support service providers (including providers that record or store communications), analytics technology providers (including session reconstruction partners), payment processing services, fraud monitoring. Prevention, detection and security prevent fraud or illegal activities or abuse of our services, email, social media and other marketing platforms, and service providers and hosts. We have entered into agreements with these companies as required by applicable law and require these companies to protect your personal information in accordance with these privacy principles.
        4. Other Data Disclosures
        We may also disclose your personal information in the following circumstances:
        ● When you consent to the disclosure;
        ● If we believe in good faith that we need to provide information pursuant to a subpoena, court order or other applicable law or legal process, or in response to an emergency involving a risk of death or serious physical injury;
        ● If the Company merges with or is acquired by another company, sells the 7WIN.GAME website, application or business unit, or if all or a substantial portion of the Company's assets are acquired by another buyer or corporate regulator. The Company's advisors and any potential purchaser's advisors will be among the assets transferred to the new ownership.
        ● Personal Data Protection
        7WIN.GAME uses administrative, technical and physical safeguards to protect your personal data, taking into account the nature of the personal data and its processing and the threats posed. 7WIN.GAME continually strives to improve these safeguards to help ensure the security of your personal data.
        ● External Links to 7WIN.GAME Websites
        The 7WIN.GAME website may provide Internet links to other websites. You may also visit other websites by clicking on links provided on our website. However, our website privacy policy does not apply to linked websites. You must refer to the privacy policy of the linked website.
        ● Cookies
        When you visit https://7WIN.GAME and any page or website under the 7WIN.GAME brand, 7WIN.GAME may set and access cookies on your computer. Cookies are used to provide basic information to our system to provide the service you requested. You can delete cookies from your internet browser settings at any time.
        ● Changes to Privacy Policy
        We may make changes to our privacy policy in the future, however, the most current version of the policy will govern our processing of your personal data and will always be available to you. If we make material changes to this policy, we will notify you at our discretion by update or email (as applicable). By continuing to access or use our services, you agree to be bound by the terms of our privacy policy.
        7WIN.GAME is committed to protecting the privacy of your personal information online. If you have any questions or comments about our management of your personal information, please contact the in-game customer service. If you have any questions about this privacy statement, you can also report to us through the in-game customer service.
        We are online 24 hours a day to help you solve your problems. We can accept your inquiries in accordance with the law. We may deny requests that are unreasonable or inconsistent with legal requirements, including requests that would be extremely impractical, require disproportionate technical effort, or may put us at risk of abetting fraud.
    `,

    'login.tip.账号在其它地方被登录': 'The account has been logged in on another device',
};