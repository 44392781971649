export default {
  'activity.活动': 'Activity',
  'activity.立即参与': 'Join Now',
  'activity.活动时间': 'Event Time',
  'activity.活动未开启': 'Event Not Started',

  'activity.activity1.标题': 'New User Special Offer',
  'activity.activity1.描述': 'Up to',
  'activity.activity1.全部': 'Bonus',
  'activity.activity1.活动描述': 'Recharge with a bonus of up to {rate}%',

  'activity.activity2.活动描述': 'Become an agent! Earn millions per month!',

  'activity.activity3.标题': 'Daily Sign-In',
  'activity.activity3.描述': 'Get as much as you draw',
  'activity.activity3.活动描述': 'Daily sign-in with constant rewards',

  'activity.activity4.标题': 'Become a VIP to Receive Big Prizes',
  'activity.activity4.活动描述': 'VIP members receive luxurious gifts worth millions',

  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': 'Bank Bound Rewards',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': 'Registration Rewards',
  'activity.coinname.12006': 'TG Rewards',
  'activity.coinname.12007': 'Challenge Rewards',
  'activity.coinname.13001': 'Agent Referral',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': 'Lucky Draw',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': 'Sign-in Rewards',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': 'Lucky Card Event Rewards',
  'activity.coinname.90001': 'Free Sign-in Rewards',
  'activity.coinname.90002': 'Recharge Bonus',
  'activity.coinname.90003': 'Jackpot Rewards',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': 'New User Special Offer',
  'activity.coinname.20001': 'Returning User',
  'activity.coinname.20002': 'Update Version Reward',
  'activity.coinname.13002': 'Agency commission',
  'activity.coinname.13003': 'VIP Rebate Rewards',
  'activity.coinname.chips': 'Balance',
  'activity.coinname.rechargeChips': 'Recharge Balance',
}
