import header from "./US/header";
import navbar from "./US/navbar";
import login from "./US/login";
import home from "./US/home";
import mine from "./US/mine";
import acting from "./US/acting";
import wallet from "./US/wallet";
import activity from "./US/activity";
import register_gift from "./US/register_gift";
import daily_checkin from "./US/daily_checkin";
import newcomer_gift from "./US/newcomer_gift";
import notice from "./US/notice";
import public_components from "./US/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
