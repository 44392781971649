export default {
  'register_gift.popup.标题': 'ƯU ĐÃI TÂN THỦ',
  'register_gift.popup.描述': 'LÊN TỚI',
  'register_gift.popup.详情': 'Chi tiết',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': 'Tiền vàng đã mở khóa rút tiền không cần cá cược!',
  'register_gift.popup.赠送': 'TẶNG',
  'register_gift.popup.立即充值': 'Nạp ngay',
  'register_gift.popup.首次充值': 'Nạp lần 1',
  'register_gift.popup.二次充值': 'Nạp lần 2',
  'register_gift.popup.三次充值': 'Nạp lần 3',
  'register_gift.popup.四次充值': 'Nạp lần 4',

  'register_gift.标题': 'ƯU ĐÃI TÂN THỦ',
  'register_gift.首次充值': 'Nạp lần 1',
  'register_gift.送全部注册金币': 'Thưởng: {amount}tiền đăng ký tặng',
  'register_gift.赠送金额': 'Số tiền tặng',
  'register_gift.玩法': 'Cách chơi',
  'register_gift.玩法规则1': '1.Nạp bất kỳ số tiền nào để nhận {rate}% số dư Tiền thưởng Người Mới',
  'register_gift.玩法规则1.1': '1.Nạp bất kỳ số tiền nào để {rate}% số tiền nạp tiền',
  'register_gift.玩法规则2': '2.Sau khi nạp tiền, tiền thưởng sẽ không thể sử dụng cho đến khi được mở khóa với Lượng Cược Nạp Tiền',
  'register_gift.玩法规则3': '3.Quy tắc mở khóa:lượng cược tiển nạp',
  'register_gift.玩法规则4': '4.Tiền Đã Mở Khóa Rút Tiền Không Cần Lượng Cược',
  'register_gift.举例': 'Ví dụ',
  'register_gift.赠送金币为': 'Tiền thưởng',
  'register_gift.当前充值金流水': 'Lượng cược nạp tiền hiện tại',
  'register_gift.领取解锁金币后将重新计算': 'sẽ tính lại sau khi mở khóa',
  'register_gift.可解锁金币': 'Được nhận',
  'register_gift.领取后赠送金币余额': 'Tiền thưởng đã nhận',
  'register_gift.立即充值': 'Nạp ngay',

  'register_gift.锁定金币总余额': 'Tổng tiền đã khóa',
  'register_gift.充值金流水': 'Lượng cược tiền nạp',
  'register_gift.锁定金币': 'Đã khóa',
  'register_gift.速率': 'Tỷ lệ',
  'register_gift.已领取金币': 'Đã nhận',
  'register_gift.可领取金币': 'Nhận được',
  'register_gift.领取金币': 'Nhận',
  'register_gift.首充赠送金币': 'Thưởng lần đầu nạp',
  'register_gift.二充赠送金币': 'Thưởng nạp lần 2',
  'register_gift.三充赠送金币': 'Thưởng nạp lần 3',
  'register_gift.四充赠送金币': 'Thưởng nạp lần 4',

  'register_gift.第x次充值': 'Nạp lần {num}',
  'register_gift.送充值金额': 'Tặng số tiền nạp',
  'register_gift.充值金额': 'Số tiền nạp',

  'register_gift.恭喜你已获得所有新手奖励': 'Chúc mừng bạn đã nhận tất cả thưởng tân thủ',
  'register_gift.如何玩': 'HƯỚNG DẪN',
  'register_gift.充值金币流水可解锁赠送金币': 'Lượng cược của tiền nạp để mở khóa tiền được tặng',
  'register_gift.解锁金币计算公式': 'Cách mở khóa tiền xu',
  'register_gift.充值金流水*当前解锁金币速率': 'Lượng cược tiền nạp * Tỷ giá mở khóa',
  'register_gift.解锁速率': 'Tỷ lệ mở khóa',
  'register_gift.解锁优先级': 'Ưu tiên mở khóa',
  'register_gift.查看规则': 'Xem quy tắc',
  'register_gift.充值金流水越高,解锁金币越多': 'Lượng cược nạp tiền càng cao, bạn càng mở khóa nhiều tiền xu!',

  'register_gift.header.锁定金额': 'Đã khóa',
  'register_gift.header.可解锁': 'Được nhận',
  'register_gift.header.领取': 'Nhận',

}