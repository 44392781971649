export default {
    'notice.充值成功': 'Isi ulang berhasil',
    'notice.领取奖励成功': 'Berhasil diterima',
    'notice.您未参与才活动': 'Anda belum mengikuti acara ini',
    'notice.已经领取过了': 'Sudah diterima',
    'notice.提款申请成功，请等待': 'Permintaan penarikan berhasil, harap tunggu',
    'notice.密码错误': 'Kata sandi salah',
    'notice.验证码已发送': 'Kode verifikasi telah dikirim',
    'notice.提款失败': 'Penarikan gagal',
    'notice.验证码有误或已过期': 'Kode verifikasi salah atau telah kedaluwarsa',
    'notice.该手机号已使用，请更换': 'Nomor telepon ini sudah digunakan, silakan ganti',
    'notice.您当前账号已绑定过手机号': 'Akun Anda saat ini sudah terhubung dengan nomor telepon',
    'notice.渠道维护请重新选择': 'Saluran dalam pemeliharaan, silakan pilih ulang',
    'notice.此充值方式不能参与活动': 'Metode isi ulang ini tidak dapat digunakan untuk berpartisipasi dalam acara',
    'notice.小于此渠道最低充值金额': 'Di bawah jumlah minimum isi ulang saluran ini',
    'notice.中间件配置不存在禁止登录': 'Konfigurasi middleware tidak ada, login dilarang',
    'notice.正在维护': 'Saluran {channel} sedang dalam pemeliharaan',
    'notice.领取新版注册送金币': 'Berhasil menerima {amount} koin emas',
    'notice.领取新版注册送金币最后一次领取': 'Berhasil menerima {amount} koin emas, Anda telah menerima semua hadiah, acara akan ditutup',
    'notice.appKey不一致禁止登录': 'appKey tidak cocok, login dilarang',
    'notice.账号在其它地方被登录': 'Akun Anda telah masuk di tempat lain',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': 'Maaf, permintaan penarikan Anda gagal, mungkin karena kesalahan nomor kartu atau pemeliharaan saluran. Silakan hubungi dukungan pelanggan.',
    'notice.提款金额不能大于': 'Jumlah penarikan tidak boleh lebih dari {amount}',
    'notice.游戏暂未开放': 'Permainan belum dibuka',
    'notice.账号被其它设备登录': 'Sistem telah mendeteksi bahwa akun Anda mungkin mendaftar pada beberapa perangkat! Jika Anda tidak menyadari hal ini, silakan mengubah sandi rekening Anda segera!',
    'notice.该银行卡号绑定在其他账号中': 'Nomor kartu bank terhubung ke rekening lain'
}
