export default {
  'wallt.充值': 'Isi Ulang',
  'wallt.提现': 'Penarikan',

  'wallt.recharge.充值方式': 'Metode Isi Ulang',
  'wallt.recharge.充值金额': 'Jumlah Isi Ulang',
  'wallt.recharge.确认充值': 'Confirmar Recarga',
  'wallt.recharge.请输入银行账户': 'Por favor, insira a conta bancária',
  'wallt.recharge.请输入开户名': 'Por favor, insira o nome do titular da conta',
  'wallt.recharge.请输入手机号': 'Por favor, insira o número de telefone',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': 'Insira sua conta Momo real para concluir o pagamento',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': 'Preencha sua conta de pagamento com precisão. Informações incorretas podem resultar na perda de fundos transferidos',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': 'Preencher corretamente as informações bancárias pode aumentar a taxa de sucesso da recarga',
  'wallt.recharge.汇率': 'Taxa de câmbio',
  'wallt.recharge.参与活动': 'Ikut Acara',
  'wallt.recharge.注册送': 'Bonus Pendaftaran',
  'wallt.recharge.签到': 'Check-in',
  'wallt.recharge.当前参与活动': 'Sedang Mengikuti Acara',
  'wallt.recharge.充值额度': 'Jumlah Isi Ulang',
  'wallt.recharge.赠送额度': 'Bonus yang Diberikan',
  'wallt.recharge.您还不能参加此活动': 'Anda belum dapat mengikuti acara ini',
  'wallt.recharge.下订单失败': 'Pesanan gagal',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': 'Pesanan gagal, coba pembayaran besar atau saluran lain!',
  'wallt.recharge.当前充值渠道正在维护': 'Saluran isi ulang sedang dalam pemeliharaan',
  'wallt.recharge.当前银行正在维护': '当前银行正在维护',
  'wallt.recharge.您当前不能参与活动确认要充值吗': 'Anda tidak bisa mengikuti acara ini, tetap ingin mengisi ulang?',
  'wallt.recharge.选择银行卡': 'Pilih Kartu Bank',
  'wallt.recharge.充值': 'Pilih Jumlah Isi Ulang',
  'wallt.recharge.手续费': 'Biaya Pemrosesan',
  'wallt.recharge.需要扣除手续费': 'Metode isi ulang ini memerlukan biaya pemrosesan',
  'wallt.recharge.实际到账': 'Jumlah Diterima',
  'wallt.recharge.你不能参与任何活动,还要遭扣除手续费': 'Metode ini tidak mendukung acara apa pun! Jika jumlah yang Anda bayar lebih dari yang diharuskan, selisihnya tidak akan dikembalikan.',
  'wallt.recharge.确认': 'Konfirmasi',
  'wallt.recharge.二维码描述': 'Jika tidak dapat menggunakan kode QR, salin informasi di bawah untuk melakukan transfer',
  'wallt.recharge.银行名称': 'Nama Bank',
  'wallt.recharge.账户': 'Nomor Rekening',
  'wallt.recharge.账户名称': 'Nama Akun',
  'wallt.recharge.转账金额': 'Jumlah Transfer',
  'wallt.recharge.复制': 'Salin',
  'wallt.recharge.注意': 'Catatan',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': 'Por favor, recarregue o valor correspondente em USDT! Valores incorretos de recarga resultarão na falha em creditar moedas do jogo!',
  'wallt.recharge.您有一笔订单未支付': 'Você tem um pedido não pago de {amount} USDT, por favor, conclua o pagamento primeiro!',
  'wallt.recharge.规则1': '1. Pastikan Anda memasukkan informasi yang benar. Informasi yang salah tidak akan diproses otomatis.',
  'wallt.recharge.规则2': '2. Jika tidak dapat menggunakan kode QR, salin detail pembayaran.',
  'wallt.recharge.规则3': '3. Jangan refresh halaman selama proses transfer.',
  'wallt.recharge.认证': 'Sertifikasi PCI DSS',
  'wallt.recharge.认证描述': 'Memenuhi standar keamanan internasional untuk keamanan data keuangan',
  'wallt.rechargechannel.网关': 'Gateway',
  'wallt.rechargechannel.银行转账': 'Transfer Bank',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Isi Ulang Kartu',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': 'Scan QR Bank',

  'wallt.withdraw.绑定银行卡': 'Hubungkan Kartu Bank',
  'wallt.withdraw.更换银行卡': 'Ganti Kartu Bank',
  'wallt.withdraw.提现信息': 'Informasi Penarikan',
  'wallt.withdraw.最高可提现金额': 'Jumlah Maksimal Penarikan',
  'wallt.withdraw.当前选择金额': 'Jumlah Terpilih',
  'wallt.withdraw.服务费': 'Biaya Layanan',
  'wallt.withdraw.收到金额': 'Jumlah Diterima',
  'wallt.withdraw.手续费': 'Biaya Pemrosesan',
  'wallt.withdraw.当前提现金额大于最大可提现金额': 'Jumlah penarikan lebih besar dari jumlah maksimal yang dapat ditarik',
  'wallt.withdraw.提现金额': 'Jumlah Penarikan',
  'wallt.withdraw.当前提现渠道正在维护': 'Saluran penarikan sedang dalam pemeliharaan',
  'wallt.withdraw.确定': 'Konfirmasi',
  'wallt.withdraw.当前金额正在维护': 'Jumlah ini sedang dalam pemeliharaan',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': 'Jumlah penarikan tidak sesuai dengan batas maksimal',
  'wallt.withdraw.请选择银行': 'Pilih Bank',
  'wallt.withdraw.请输入您的姓名': 'Masukkan Nama Anda',
  'wallt.withdraw.请输入您的银行卡号': 'Masukkan Nomor Kartu Bank Anda',
  'wallt.withdraw.请输入您的钱包地址': 'Por favor, insira o endereço da sua carteira',
  'wallt.withdraw.请输入您的电子邮件': 'Masukkan Email Anda',
  'wallt.withdraw.请输入提款密码': 'Masukkan Kata Sandi Penarikan',
  'wallt.withdraw.请确认提款密码': 'Konfirmasi Kata Sandi Penarikan',
  'wallt.withdraw.请检查表单是否填写完整': 'Pastikan semua informasi telah terisi dengan benar',
  'wallt.withdraw.请输入正确的邮箱': 'Masukkan Email yang Benar',
  'wallt.withdraw.密码不能少于6位': 'Kata sandi tidak boleh kurang dari 6 karakter',
  'wallt.withdraw.两次密码输入不一致': 'Kata sandi tidak cocok',
  'wallt.withdraw.提款密码': 'Kata Sandi Penarikan',
  'wallt.withdraw.忘记密码': 'Lupa Kata Sandi',
  'wallt.withdraw.请输入密码': 'Masukkan Kata Sandi',
  'wallt.withdraw.更换银行卡需要验证交易密码': 'Ganti kartu bank memerlukan verifikasi kata sandi transaksi',
  'wallt.withdraw.密码错误': 'Kata sandi salah',
  'wallt.withdraw.服务器内部错误': 'Kesalahan Server Internal',
  'wallt.withdraw.短信验证': 'Verifikasi SMS',
  'wallt.withdraw.手机号': 'Nomor Telepon',
  'wallt.withdraw.请输入验证码': 'Masukkan Kode Verifikasi',
  'wallt.withdraw.发送': 'Kirim',
  'wallt.withdraw.验证码发送成功': 'Kode verifikasi berhasil dikirim',
  'wallt.withdraw.验证码错误': 'Kode verifikasi salah',
  'wallt.withdraw.发送验证码': 'Kirim Kode Verifikasi',
  
  'wallt.transrecord.交易记录': 'Riwayat Transaksi',
  'wallt.transrecord.充值': 'Isi Ulang',
  'wallt.transrecord.提现': 'Penarikan',
  'wallt.transrecord.没有数据': 'Tidak ada data',
  'wallt.transrecord.没有更多数据了': 'Tidak ada data lebih lanjut',
  'wallt.transrecord.支付中': 'Dalam Proses Pembayaran',
  'wallt.transrecord.成功': 'Berhasil',
  'wallt.transrecord.失败': 'Gagal',
  'wallt.transrecord.处理中': 'Sedang Diproses',
  'wallt.transrecord.类型': 'Jenis',
  'wallt.transrecord.金额': 'Jumlah',
  'wallt.transrecord.渠道': 'Saluran',
  'wallt.transrecord.状态': 'Status',

}
