export default {
  'daily_checkin.popup.标题': 'เช็คอินรายวัน',
  'daily_checkin.popup.描述': 'จับได้เท่าไรให้เท่านั้น',
  'daily_checkin.popup.按钮': 'จับรางวัล',

  'daily_checkin.标题': 'เช็คอินรายวัน',
  'daily_checkin.今日奖励': 'รางวัลวันนี้',
  'daily_checkin.领取': 'รับ',
  'daily_checkin.免费': 'ฟรี',
  'daily_checkin.免费描述': 'ยอดหมุนเวียนวันนี้ถึง {amount} รับเหรียญเช็คอินฟรีในวันถัดไป',
  'daily_checkin.第一天': 'วันที่ 1',
  'daily_checkin.第二天': 'วันที่ 2',
  'daily_checkin.第三天': 'วันที่ 3',
  'daily_checkin.第四天': 'วันที่ 4',
  'daily_checkin.第五天': 'วันที่ 5',
  'daily_checkin.第六天': 'วันที่ 6',
  'daily_checkin.第七天': 'วันที่ 7',
  'daily_checkin.充值': 'เติมเงิน',
  'daily_checkin.动态描述': 'ไม่มีข้อกำหนดยอดหมุนเวียนในการถอนเงิน',
  'daily_checkin.选择充值方式': 'เลือกวิธีการเติมเงิน',
  'daily_checkin.下订单失败': 'การสั่งซื้อล้มเหลว',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': 'การสั่งซื้อล้มเหลว กรุณาลองใช้การชำระเงินจำนวนมากหรือช่องทางการชำระเงินอื่น',
  'daily_checkin.正在维护': 'อยู่ระหว่างการบำรุงรักษา',
  'daily_checkin.领取成功': 'รับความสำเร็จ'
}
