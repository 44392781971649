export default {
  'acting.代理': 'Đại lý',

  'acting.详情': 'Chi tiết',
  'acting.奖励': 'Phần thưởng',
  'acting.佣金': 'Hoa hồng',
  'acting.规则': 'Quy tắc',

  'acting.generalboard.邀请朋友来获取': 'Mời bạn bè để nhận',
  'acting.generalboard.人': 'Người',
  'acting.generalboard.邀请奖励': 'Thưởng mời',
  'acting.generalboard.高达': 'Lên tới',
  'acting.generalboard.佣金奖励': 'Thưởng hoa hồng',
  'acting.generalboard.描述': 'Mời bạn bè {amount} /người, còn nhận thêm hoa hồng lên đến 90% cho tiền cược của các bạn bè. Tận hưởng hoa hồng ổn định, dù họ thắng hay thua. Bắt đầu kiếm tiền ngay!',
  'acting.generalboard.邀请链接': 'Link mời',
  'acting.generalboard.分享到社交平台': 'Chia sẻ lên nền tảng xã hội',
  'acting.generalboard.拉新表现': 'Biểu hiện mời bạn bè',
  'acting.generalboard.今日拉新': 'Mời hôm nay',
  'acting.generalboard.本周拉新': 'Mời tuần này',
  'acting.generalboard.直属拉新表现': 'Biểu hiện mời F1',
  'acting.generalboard.非直属拉新表现': 'Biểu hiện mời ngoài F1',
  'acting.generalboard.下级详情': 'Chi tiết cấp dưới',
  'acting.generalboard.下级总人数': 'Tổng số cấp dưới',
  'acting.generalboard.直属下级人数': 'Số người F1',
  'acting.generalboard.非直属下级人数': 'Số người ngoài F1',
  'acting.generalboard.总奖励': 'Tổng thưởng',
  'acting.generalboard.总佣金': 'Tổng hoa hồng',
  'acting.generalboard.分享描述': 'Trở thành đại lý và kiếm hàng triệu mỗi tháng! Phần thưởng mời {amount}, hoa hồng lên tới 90%!',
  'acting.generalboard.tip.复制成功': 'Copy thành công',

  'acting.reward.邀请里程碑': 'Mốc thành tích mời',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Sau khi mời đủ số lượng bạn bè yêu cầu, bạn có thể nhận được phần thưởng tương ứng.',
  'acting.reward.邀请': 'Mời',
  'acting.reward.人': 'Người',
  'acting.reward.领取': 'Nhận',
  'acting.reward.直属下级人数': 'Số người F1',
  'acting.reward.邀请奖励': 'Thưởng mời',
  'acting.reward.直属下级绑定成功可获得': 'Bind thành công người F1 để nhận',
  'acting.reward.今日邀请': 'Mời hôm nay',
  'acting.reward.总邀请': 'Tổng số mời',
  'acting.reward.今日奖励': 'Thưởng hôm nay',
  'acting.reward.邀请总奖励': 'Tổng thưởng mời',
  'acting.reward.今日邀请人数': 'Tổng số mời hôm nay',
  'acting.reward.邀请总人数': 'Tổng số mời',
  'acting.reward.用户ID': 'ID',
  'acting.reward.昵称': 'Biệt danh',
  'acting.reward.奖励': 'Thưởng',
  'acting.reward.展示前50条信息': 'Hiển thị 50 tin nhắn đầu tiên',
  'acting.reward.暂无记录': 'Chưa có lịch sử nào',
  'acting.reward.领取成功': 'Nhận thành công',

  'acting.commission.本周佣金信息': 'Thông tin hoa hồng tuần này',
  'acting.commission.下级总充值': 'Tổng nạp cấp dưới',
  'acting.commission.直属下级佣金': 'Hoa hồng F1',
  'acting.commission.非直属下级佣金': 'Hoa hồng ngoài F1',
  'acting.commission.未领取佣金': 'Chưa nhận hoa hồng',
  'acting.commission.领取佣金': 'Nhận hoa hồng',
  'acting.commission.领取记录': 'Lịch sử nhận',
  'acting.commission.佣金总和': 'Tổng hoa hồng',
  'acting.commission.领取时间': 'Thời gian nhận',
  'acting.commission.直属佣金': 'Hoa hồng F1',
  'acting.commission.非直属佣金': 'Hoa hồng ngoài F1',
  'acting.commission.总和': 'Tổng',
  'acting.commission.展示前50条信息': 'Hiển thị 50 tin nhắn đầu tiên',
  'acting.commission.暂无记录': 'Chưa có lịch sử nào',
  'acting.commission.领取成功': 'Nhận thành công',
}