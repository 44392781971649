export default {
    'login.banner.text1': 'Bem-vindo ao',
    'login.banner.text2': '7WIN.GAME',
    'login.banner.text3': 'Faça login para começar sua jornada de jogos',

    'login.login': 'Entrar',
    'login.telnumber': 'Número de Telefone',
    'login.password': 'Senha',
    'login.button': 'Entrar',
    'login.forgot': 'Esqueceu a senha?',
    'login.register.text': 'Não tem uma conta?',
    'login.register': 'Registre-se agora',
    'login.toast.请输入正确的手机号码': 'Por favor, insira um número de telefone válido',
    'login.toast.请输入密码': 'Por favor, insira a senha',
    'login.toast.密码不正确或者用户不存在': 'Senha incorreta ou usuário não encontrado',

    'register.register': 'Registrar-se',
    'register.telnumber': 'Número de Telefone',
    'register.password': 'Senha',
    'register.confirmpassword': 'Confirmar Senha',
    'register.agree': 'Eu concordo com',
    'register.agreement': 'Termos de Uso',
    'register.sendcode': 'Enviar Código de Verificação',
    'register.haveaccount': 'Já tem uma conta?',
    'register.login': 'Entrar agora',
    'register.code': 'Código de Verificação',
    'register.nocode': 'Não recebeu o código de verificação',
    'register.signin': 'Entrar',
    'register.toast.验证码已发送': 'Código de verificação enviado',
    'register.toast.请同意用户协议': 'Por favor, concorde com os Termos de Uso',
    'register.toast.请填写完整信息': 'Por favor, preencha todas as informações',
    'register.toast.请输入正确的手机号': 'Por favor, insira um número de telefone válido',
    'register.toast.密码不能少于6位': 'A senha deve ter pelo menos 6 caracteres',
    'register.toast.两次密码不一致': 'As senhas não coincidem',

    'find.forgot': 'Esqueceu a senha',
    'find.telnumber': 'Número de Telefone',
    'find.sendcode': 'Enviar Código de Verificação',
    'find.haveaccount': 'Já tem uma conta?',
    'find.login': 'Entrar agora',
    'find.password': 'Senha',
    'find.confirmpassword': 'Confirmar Senha',
    'find.resetpassword': 'Redefinir Senha',
    'find.code': 'Código de Verificação',
    'find.nocode': 'Não recebeu o código de verificação',
    'find.toast.请输入正确的手机号码': 'Por favor, insira um número de telefone válido',
    'find.toast.验证码已发送': 'Código de verificação enviado',
    'find.toast.该号码未注册': 'Este número não está registrado',
    'find.toast.频繁发送短信': 'Envio de SMS frequente',
    'find.toast.密码不能少于6位': 'A senha deve ter pelo menos 6 caracteres',
    'find.toast.两次密码不一致': 'As senhas não coincidem',
    'find.toast.请输入验证码': 'Por favor, insira o código de verificação',
    'find.toast.密码重置成功': 'Senha redefinida com sucesso',
    'find.toast.验证码不正确': 'Código de verificação incorreto',

    'agreement.title': '用户协议',
    'agreement.text': `
        定义7WIN.GAME 被称为“我们”或“我们”
        玩家被称为“您”或“玩家”
        “网站”是指通过桌面、移动或其他平台进行利用的 7WIN.GAME 玩家
        1. 概述
        • 1.1。本用户协议适用于通过 7WIN.GAME 访问的游戏的使用。
        • 1.2。本用户协议在您完成注册过程（包括勾选接受本用户协议并成功创建帐户）后立即生效。创建帐户后使用本网站的任何部分，即表示您同意本用户协议。
        • 1.3。在创建帐户之前，您必须仔细阅读整个用户协议。如果您不同意本用户协议的任何条款，您不得创建帐户或继续使用本网站。
        • 1.4。我们保留随时修改本用户协议的权利，恕不另行通知。如果我们进行此类修改，我们可能会采取适当措施提请您注意这些更改（例如通过电子邮件或在网站上放置显着通知，以及修订后的用户协议），但您应自行决定检查任何修改、更新和/或修改。您在对用户协议进行任何修改后继续使用服务和 7WIN.GAME 网站将被视为您接受并同意受此类修改、更新和/或此类修改的约束。
        • 1.5。本用户协议可能会以多种语言发布，以供参考并方便玩家访问。英文版本是您与我们之间关系的唯一法律依据，如果翻译有任何差异，则以用户协议的英文版本为准。
        2. 约束性声明
        2.1.通过同意受本用户协议的约束，您还同意受通过引用纳入本用户协议的 7WIN.GAME 规则和隐私政策的约束。如有任何不一致，以本用户协议为准。您声明并保证：
        • 2.1.1。您已年满 (a) 18 岁或 (b) 其他法定年龄或适用于您的任何法律确定的成年年龄，以较大者为准；
        • 2.1.2。您具有与我们签订具有法律约束力的协议的完全能力，并且您不受任何形式的有限法律行为能力的限制；
        • 2.1.3。您在本协议期限内向我们提供的所有信息均真实、完整、准确，此类信息如有变更，您必须及时通知我们；
        • 2.1.4。对于您从我们这里获得的任何奖金，您全权负责报告和核算根据相关法律适用于您的任何税款；
        • 2.1.5。您了解，使用我们的服务时，您有可能损失存入会员账户的资金，并同意您对任何此类损失承担全部责任；
        • 2.1.6。您被授权在您居住的司法管辖区使用在线赌场服务；
        • 2.1.7。对于您的会员账户的存款和取款，您只能使用有效且合法属于您的电子货币；
        • 2.1.8。您了解加密货币的价值可能会根据市场价值而有很大差异；
        • 2.1.9。我们向您提供的计算机软件、计算机图形、网站和用户界面归7WIN.GAME或其联营公司所有，并受版权法保护。您只能根据我们制定的所有规则、用户协议以及所有适用的法律、规则和法规将本软件用于您自己的个人娱乐用途；
        • 2.1.10。您了解加密货币不被视为法定货币或货币，因此在网站上它们被视为没有内在价值的虚拟货币。
        • 2.1.11。您确认您不是 7WIN.GAME 的高级职员、董事、雇员、顾问或代理人，也不是任何与 7WIN.GAME 有关的公司的雇员，也不是上述任何人的亲属或配偶；
        • 2.1.12。您未被诊断或归类为强迫性或问题赌徒。如果在使用我们的服务时出现此类赌博问题，我们不承担任何责任，但会尽力提供相关支持。如果我们认为此类行动有益，我们保留采取冷静期的权利。
        • 2.1.13。您接受并承认我们有权检测和阻止使用禁止的技术，包括但不限于欺诈交易检测、自动注册和注册、游戏技术游戏和截图。这些步骤可能包括但不限于检查玩家的设备属性、地理位置检测和 IP 屏蔽、交易和区块链分析；
        • 2.1.14。您接受我们终止和/或改变网站上提供的任何游戏或活动以及拒绝和/或限制投注的权利。
        • 2.1.15。您接受我们有权禁止/阻止多个账户并自由控制这些账户中的资产。
        • 2.1.16。您了解软件中可能存在的错误或缺陷，您同意不从中获利。此外，您同意立即向 7WIN.GAME 报告任何错误或不完整之处。如果您未能履行本条款规定的义务，7WIN.GAME有权就与错误或不完整相关的所有费用获得全额赔偿，包括因错误/不完整和不成功消息而产生的任何费用。用户。
        • 2.1.17。您了解7WIN.GAME有权执行“KYC”（了解您的客户）验证流程。如果我们确定您提供了虚假或误导性信息，则可能会阻止或关闭您的用户帐户的访问权限。
        2.2.如果 7WIN.GAME 自行决定认为明显发生以下任何情况，我们保留宣布投注部分或全部无效的权利：
        • 2.2.1。您或与您有关的人可能会直接或间接影响事件的结果以获得非法优势。
        • 2.2.2。您和/或与您有关的人直接或间接规避7WIN.GAME的规定。
        • 2.2.3。事件的结果直接或间接受到犯罪活动的影响。
        • 2.2.4。原本不会被接受的投注，但在网站受到技术问题影响时被接受。
        • 2.2.5。由于错误、安全漏洞、技术错误、不可抗力或其他原因，投注已因该错误而被提供、下注和/或接受。
        • 2.2.6。如果玩家的存款费用太低，并被区块链或类似网站标记为“费用不足以转发”，则 7WIN.GAME 保留没收奖金的权利，如果 7WIN.GAME 自行决定声称该玩家的交易和行为玩家本质上是欺诈的。
        3. 有限领土
        • 3.1。列入黑名单的地区：中国、荷兰、荷兰加勒比群岛、匈牙利、澳大利亚、安大略省（加拿大）、库拉索岛、法国、美国和/或国家或州法律规定的任何其他限制地区。请注意，严禁在上述黑名单国家/地区玩 7WIN.GAME 游戏。您的个人数据是为了履行其职责并为您提供最好的支持和服务。您同意此类披露。
        4. 一般投注规则
        • 4.1。投注只能由注册账户持有人进行。
        • 4.2。只能通过互联网进行投注。
        • 4.3。仅当您的 7WIN.GAME 帐户中有足够的余额时，您才可以进行投注。
        • 4.4。投注一旦结束，将受接受投注时网站上有效且可用的用户协议版本的约束。
        • 4.5。获胜投注的任何支出都将记入您的帐户，包括本金乘以赔率。
        • 4.6。如果7WIN.GAME自行判断存入7WIN.GAME帐户的投注付款有误，则7WIN.GAME保留调整存入7WIN.GAME帐户的投注付款的权利。
        • 4.7。一旦下注并接受，您将无法修改、撤销或取消。
        • 4.8。您可以在网站上查看所有投注及其状态和详细信息的列表。
        • 4.9。当您下注时，您承认您已阅读并完全理解本网站上规定的与下注相关的所有用户协议。
        • 4.10。 7WIN.GAME 管理您的帐户并计算可用资金、待处理资金、投注金额以及奖金。除非另有证明，否则这些金额被视为最终金额并被视为准确。
        • 4.11。您对所下注的金额负全部责任。
        • 4.12。最终结果确认后，奖金将支付到您的帐户。
        5. 奖金和促销
        • 5.1。如果我们认为奖金设置不正确或被滥用，7WIN.GAME 保留立即取消任何促销、奖金或奖励（包括但不限于存款奖金、邀请好友奖金和忠诚度计划）的权利。如果所述奖金已经支付，我们保留拒绝任何提款请求并从您的帐户中扣除金额的权利。奖金是否被视为错误设置或滥用将仅由 7WIN.GAME 决定。
        • 5.2。如果您使用存款奖金，在您达到存款奖金用户协议中规定的要求之前，将不接受初始存款的提取。
        • 5.3。如果违反优惠或促销的任何条款，或者有任何证据表明客户或一组客户因存款奖金、预付款、免费投注、无风险投注或任何其他促销优惠而进行一系列投注，无论结果如何，无论结果如何，无论是个人还是作为团体的一部分，7WIN.GAME 保留收回此类优惠的因素奖金的权利，并拥有绝对酌情权以准确赔率结算投注，取消免费投注奖金和无风险投注或取消由存款奖金支持的任何投注。此外，7WIN.GAME保留向客户收取高达存款奖金、免费投注奖金、无风险投注或额外付款以支付管理费用价值的管理费的权利。我们进一步保留要求任何客户在将任何奖金、免费投注或奖金费用、无风险投注或奖励存入客户账户之前提供足以让我们满意的客户身份的权利。
        • 5.4。所有 7WIN.GAME 优惠均面向休闲玩家，7WIN.GAME 可自行决定限制客户参与全部或部分任何促销活动的资格。
        • 5.5。 7WIN.GAME保留自行决定修改、取消、撤销或拒绝任何促销活动的权利。
        • 5.6。每个人/帐户、家庭、家庭、地址、电子邮件地址、IP 地址和共享计算机的环境（大学、兄弟会、学校、公共图书馆、工作场所等）只能领取一次奖金。如果发现滥用/欺诈的证据，运营商保留关闭您的帐户并没收任何可用资金的权利。
        • 5.7。您承认并理解，存在与促销、奖金和特别优惠相关的单独的用户协议，并且是对本用户协议的补充。本用户协议视情况在本网站相关内容页面中阐明或亲自提供给您。如果此类促销、奖金和特别优惠的规定与本用户协议的规定发生冲突，则以此类促销、奖金和特别优惠的规定为准。
        • 5.8。我们可能会要求您先下注一定金额的存款，然后才能使用我们存入您帐户的任何免费/奖金进行下注。
        • 5.9。您同意某些促销活动可能受到限制和/或提款要求的约束，在提取促销活动中存入的资金之前必须满足这些要求。此类条款将作为促销活动的一部分正式发布和提供。如果您在满足适用的下注要求之前选择提款，我们将在批准任何提款之前扣除全部奖金金额以及与使用奖金金额相关的任何奖金。
        6. 在线支持
        6.1.作为您使用本网站的一部分，我们可能会为您提供由我们管理和控制的实时聊天工具。我们保留审查对话并保留在设施中发表的所有声明的记录的权利。您使用聊天工具必须用于娱乐和社交目的。
        6.2.如果您出现以下情况，我们保留删除聊天室功能或立即终止您的会员帐户并退还您的帐户余额的权利：
        • (a) 发表任何淫秽或严重冒犯性的言论，包括表达偏执、种族主义、仇恨或粗俗；
        • (b) 发表辱骂、诽谤、骚扰或攻击性言论；
        • (c) 使用聊天工具进行广告、促销或以其他方式与任何其他在线实体相关；
        • (d) 就 7WIN.GAME 或与本网站相连的任何其他互联网网站发表不真实和/或恶意和/或损害 7WIN.GAME 的声明；
        • (e) 使用聊天工具进行串通、从事非法行为或鼓励我们认为不当的行为。任何可疑的对话都将报告给有关当局。
        6.3.在线支持用作我们和您之间的沟通形式，不得复制或与任何论坛或第三方共享。
        7. 责任限制
        • 7.1。您进入网站并参与游戏的风险由您自行承担。本网站和游戏不提供任何形式的保证，无论是明示的还是暗示的。
        • 7.2。在不损害前述规定的一般性的情况下，我们、我们的董事、员工、合作伙伴、服务提供商。
        • 7.3。不保证软件、游戏和网站适合其用途。
        • 7.4。不保证软件、游戏和网站没有错误。
        • 7.5。不保证软件、游戏和网站可以不间断地访问。
        • 7.6。对于因使用本网站或参与游戏而产生的任何损失、成本、费用或损害，无论是直接的、间接的、特殊的、后果性的、附带的或其他的，概不负责。
        • 7.7。您理解并承认，如果游戏或其互操作性出现故障，则在出现此类故障时进行的任何投注都将无效。从故障游戏中收集到的资金将被视为无效，涉及该资金的任何后续游戏轮次也将被视为无效，无论使用该资金玩的是哪个游戏。
        • 7.8。您特此同意对因您使用本网站或参与游戏而可能产生的所有成本、费用、损失、损害、索赔和责任向我们、我们的董事、员工、合作伙伴和服务提供商进行全额赔偿并使其免受损害。
        • 7.9。在法律允许的范围内，我们因您使用本网站而产生的或与之相关的最大责任，无论诉讼原因如何（无论是合同规定、违反保证还是其他方式），均不得超过 100 欧元。
        8. 违规、处罚和终止
        • 8.1。如果您违反本用户协议的任何条款，或者我们有合理的理由怀疑您违反了这些条款，我们保留不开设、暂停或关闭您的会员账户或扣留您的奖金并用于资助任何款项的权利。由您造成的损失。
        • 8.2。您承认7WIN.GAME将最终决定您是否违反了7WIN.GAME的规则、条款或条件，从而导致您被暂停或永久禁止加入我们的网站。
        9. 自我排除
        • 9.1。请求自我禁入期即表示您同意受以下条款和条件的约束，这些条款和条件将从协调人实施所选的自我禁入期之时起生效。
        • 9.2。您可以自我禁入 1、3、6、12 个月或永久。自我排除请求必须通过在线支持提出。
        • 9.3。一旦您进行自我排除，在此期间您将无法访问您的账户或提取资金。
        • 9.4。如果您已排除您的帐户，而您的帐户中有待处理的投注，则已下的投注将仍然有效并根据官方结果支付。
        • 9.5。自我禁入期结束后，您可以提取合格投注中的奖金。 7WIN.GAME 不会取消或取消在自我禁制受到影响之前所下的任何投注。
        • 9.6。一旦您进行自我禁入，您将无法更改或修改更短的期限，也无法取消您的自我禁入，直到您选择的自我禁入时间过去。
        • 9.7。如果您希望延长自我禁入期，请联系我们的客户服务部门。
        • 9.8。一旦您的自我禁入期结束，您可以通过联系 7WIN.GAME 的游戏内客户服务来恢复您的帐户。
        • 9.9。通过自我排除，您同意：
        o a) 在此期间您不会创建其他帐户。
        o b) 您不会将资金存入或尝试存入 7WIN.GAME 帐户。
        o c) 在此期间您不得在网站上进行投注。
        o d) 这是您自愿发起的行为，7WIN.GAME 对您在自我禁入期间可能以任何方式遭受的任何损失不承担任何责任。
        隐私政策
        您特此承认并接受，如果我们认为有必要，我们可能会收集和使用您的个人数据，以便您能够访问和使用本网站并参与加入游戏。
        我们确认，在按照前款规定收集您的个人信息时，我们受到《数据保护法》的约束。我们将根据最佳商业实践和适用法律保护您的个人信息并尊重您的隐私。
        我们将使用您的个人数据，以便您能够参与游戏并开展与您参与游戏相关的活动。我们还可能使用您的个人数据来通知您我们认为您可能感兴趣的变更、新服务和促销活动。如果您不希望收到此类直接营销邮件，您可以选择退出该服务。
        除非法律要求，您的个人数据不会透露给第三方。由于 7WIN.GAME 业务合作伙伴或供应商或服务提供商可能对网站的某些功能或一般运营负责，因此可能会向 Surname 披露个人数据。 7WIN.GAME 员工可以访问您的个人数据，以履行其职责并为您提供最好的支持和服务。您同意此类披露。
        我们将保留所有提供的信息作为个人数据。您有权访问我们持有的有关您的个人数据。除非法律要求或除非出于关系目的不再需要保留所持有的信息，否则不会销毁任何数据。
        为了使您对网站的访问更加方便、跟踪对网站的访问并改进服务，我们收集从您的浏览器发送的一小部分信息，称为 cookie。如果您愿意，您可以禁用 cookie 收集。但是，您必须注意，禁用 cookie 可能会严重限制或完全禁止您对本网站的使用。
        Cookie 政策
        1.什么是cookies？
        • cookie 是一种非常小的文本文件形式的信息，放置在互联网用户的计算机上。它由网站服务器（基本上是运行网站的计算机）创建，并且每当用户访问网站时该服务器都可以使用它。 Cookie 可以被认为是互联网用户的识别标签，它可以告诉网站用户何时返回。 Cookie 不会损害您的计算机，并且我们不会在任何 Cookie 上存储有关您的任何个人身份信息。
        2.为什么我们在7WIN.GAME上使用cookie？
        • 7WIN.GAME 使用两种类型的 cookie：我们设置的 cookie 和第三方（即其他网站或服务）设置的 cookie。 7WIN.GAME cookie 允许我们在您访问期间维护您的帐户登录状态，并根据您的偏好调整网站上显示的信息。
        3.我们在7WIN.GAME上使用哪些cookie？
        以下是 7WIN.GAME 设置的主要 cookie 列表以及每个 cookie 的用途：
        • _fp - 存储浏览器指纹。寿命：永远。
        • _t - 存储用户在当前浏览会话期间首次访问网站的时间戳。唯一访问统计所需的。寿命：浏览会话。
        • _r - 存储当前浏览会话的http 引荐来源网址。需要跟踪外部流量来源。寿命：浏览会话。
        • _c - 存储代理程序的标识符。代理统计所需。寿命：永远。
        • 第三方为通配符域设置的Cookie：*.7WIN.GAME
        • 谷歌分析：_ga、_gat、_gid
        • Zendesk：__ zlcmid
        • Cloudflare：__ cfuid
        • 请注意，某些浏览器（例如 Mac 上的 Chrome）即使在没有打开任何选项卡的情况下也会保持后台进程运行，因为会话之间可能会设置会话 cookie。
        • 还有第三方脚本为其域设置的cookie。
        4. 我如何管理我在7WIN.GAME上的cookie？
        • 如果您希望停止接受cookie，您可以通过浏览器中的隐私设置选项来执行此操作。
        5.个人资料保护政策
        • 7WIN.GAME 的使命是保证您的数据安全，为此我们通过多种不同方式保护您的数据。我们为客户提供高安全标准，例如公共网络上的动态数据加密、数据库中的数据加密、审计标准以及拒绝服务聊天和在线聊天缓解。
        6.服务器保护策略
        • 所有服务器均完全加密；
        • 所有备份均已加密；
        • 防火墙、VPN 访问；
        • 只允许通过VPN 访问服务器；
        • 所有http/s 服务均在Cloudflare 上运行；
        • 通过VPN 连接到节点；
        • SSH 端口转发隧道；
        • 服务仅允许通过VPN 进行；
        • 服务器有防火墙，只允许SSH端口；
        • 有关重要服务的警报。
        • 数据泄露通知
        • 当7WIN.GAME 发现个人数据泄露时，我们将根据GDPR 时限通知相关用户。
        7.国际数据传输
        • 我们仅在必要时向第三方披露个人数据，以提供高质量服务或响应当局的合法要求。
        • 我们与第三方系统共享以下数据：
        • Zendesk 公司– 如果用户向实时聊天发送消息或向支持收件箱发送电子邮件，则用户名和电子邮件信息将被传输。
        • 尽管我们尽力做到最好，但有时还是会出现问题。我们的团队将尽一切努力尽快解决您的问题。为了更快地为您提供支持，您可以点击上方按钮进入电报群加入我们。
        • 如果出现错误，请提供以下信息：
        o 登录名
        o 事件发生的日期和时间
        游戏代码或牌桌名称（如果有）
        错误屏幕截图（如果有）
        我们非常感谢您的帮助和您提供的错误消息，因为您的报告可以帮助我们改进。
        您的个人数据的收集和使用
        收集数据的分类
        个人资料
        在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您的个人身份信息。个人身份信息可能包括但不限于：
        电子邮件
        姓名
        使用数据
        使用数据
        使用服务时会自动收集使用数据。
        使用数据可能包括您设备的互联网协议地址（例如 IP 地址）、浏览器类型、浏览器版本以及您访问的我们服务的页面、您访问的日期和时间以及在这些页面上花费的时间等信息。 、唯一设备标识符和其他诊断数据。
        当您通过移动设备访问服务时，我们可能会自动收集某些信息，包括但不限于您使用的移动设备类型、您的移动设备的唯一 ID、您的移动设备的 IP 地址、您的移动操作系统、您使用的移动互联网浏览器的类型、唯一设备标识符和其他诊断数据。
        当您访问我们的服务或通过移动设备访问服务时，我们还可能收集您的浏览器发送的信息。
        来自第三方社交网络服务的信息
        7WIN.GAME允许您通过以下第三方社交网络服务创建帐户并登录以使用服务：
        • Google
        • Facebook
        • Telegram
        • Metamask
        8.网络3.0
        如果您决定通过第三方社交网络服务注册或允许我们访问第三方社交网络服务，我们可能会收集与您的第三方社交网络服务帐户相关的个人数据，例如您的姓名、电子邮件地址、您的活动。或与该帐户关联的您的联系人。
        您还可以选择通过您的第三方社交网络服务帐户与 7WIN.GAME 共享其他信息。如果您选择在注册过程中或其他情况下提供此类信息和个人数据，则您授予 7WIN.GAME 以符合本保密手册的方式使用、共享和存储此类信息的权利。
        删除个人数据
        如果 7WIN.GAME 不再有合法理由继续处理或存储您的个人数据，您可以请求删除您的个人数据。请注意，这项权利不受保证 - 如果 7WIN.GAME 需要遵守有关存储您的数据的法律义务，则 7WIN.GAME 不太可能满足您的请求。您可以联系7WIN.GAME客户服务请求删除您的个人数据。
        注册并登录
        您必须年满 18 岁才能注册。如果您想添加邮箱地址，请确保您输入的邮箱地址正确，以便后续用于账户KYC验证。
        您可以随时登录。为了提高安全性，我们建议添加两步身份验证。了解有关谷歌身份验证器的更多信息。
        如果您需要更改注册电子邮件，我们很抱歉，但我们无法更改此信息。如果您仍想更改用户名和/或注册电子邮件，我们建议您关闭当前帐户并注册一个新帐户。
        7WIN.GAME隐私政策
        7WIN.GAME隐私政策旨在描述7WIN.GAME服务（如7WIN.GAME使用条款中所定义）以及我们显示或引用这些原则的隐私惯例。
        本隐私政策规定了 7WIN.GAME 将如何管理我们因您成为 7WIN.GAME 会员、使用我们位于 https://7WIN.GAME 的网站以及 7WIN.GAME 下的任何页面或网站而收集的个人数据。 7WIN.GAME 品牌，包括我们拥有和/或运营的或我们与您进行交易时的任何手机或平板电脑应用程序。我们致力于确保我们收集的有关您的个人信息根据适用的隐私法和本隐私政策得到保护、使用、存储和披露。
        从您那里收集的个人数据
        当您申请贸易信用、激活我们的服务、在 7WIN.GAME 网站上注册服务、连接到我们的服务、联系我们（包括社交网络）、参与在线调查或与 7WIN.GAME 互动时，我们可能会收集各种信息的信息，包括：
        1.账户信息。您的 7WIN.GAME 用户名和相关帐户详细信息，包括电子邮件地址、注册设备、帐户状态和年龄。
        2. 设备信息。可识别您的设备的数据，例如您的设备序列号，或有关您的设备的数据，例如浏览器类型。
        3. 联系信息。姓名、电子邮件地址、实际地址、电话号码或其他联系信息等数据。
        4. 付款信息。有关您的帐单地址和付款方式的数据，例如银行详细信息、信用卡、借记卡或其他支付卡信息。
        5. 欺诈预防信息。用于帮助识别和防止欺诈的数据，包括设备可靠性和 KYC 信息。
        6. 使用数据。有关您的活动和使用我们服务的数据，例如服务启动，包括浏览历史记录；产品交互；崩溃、性能和其他诊断数据；和其他使用数据。
        7. 识别数据。在某些司法管辖区，我们可能会在有限的情况下要求政府颁发身份证明，包括为您激活我们的服务、出于商业信用续订目的、预订管理或法律要求。
        8.您向我们提供的其他信息。详细信息，例如您与 Apple 的沟通，包括与客户支持的互动以及通过社交媒体渠道的联系。
        在某些情况下，7WIN.GAME可能会收集“敏感”个人信息。 （包括有关种族和国籍、政治观点、宗教信仰和其他类似性质的信仰、工会的信息。
        会员资格和有关性生活或性取向的信息），或健康信息，以及在法律允许的情况下与指控或证实犯罪信息有关的信息。
        个人数据的使用
        我们可能会结合和使用我们持有的有关您的信息（包括在我们的服务中和之外收到的信息），以了解您如何使用我们的服务并与之互动，并了解您与谁有联系以及对提供、个性化和改进我们的服务感兴趣。服务。我们还可能将我们在全球范围内持有的有关您的信息用于以下目的：
        1. 提供、维护、改进和开发功能、内容及相关服务。
        2. 分析您的内容和其他信息。
        3. 满足您的要求并在您的许可下使用。
        4. 帮助广告商和应用程序/网站运营商建立联系，在他们的应用程序和网站中投放相关广告。
        5. 组合并投放有针对性的营销广告（无论使用何种设备或使用我们的服务），并根据设备活动、估计兴趣和您的目标信息提供有针对性的营销广告。
        6. 根据您的喜好与您联系并提供账户相关信息或发送营销信息。
        7. 链接您在我们不同服务和不同设备上的活动，并链接您在不同 7WIN.GAME 服务中可能使用的所有帐户。我们可以将活动和帐户链接到一个登录名下。
        8. 开展或支持营销推广活动。
        9. 开展研究并支持创新。
        10. 向外部各方（包括合作伙伴、应用程序/网站运营商、广告商、应用程序、第三方和公众）提供我们的服务和广告的使用情况和趋势的分析和报告，包括向合作伙伴展示和报告、广告表现和用户体验信息。这些分析和报告可能包括汇总或匿名信息。
        11、根据您的目标信息，提供与您既定目标相符的服务、广告、搜索结果等内容。
        12. 将我们掌握的有关您的信息与我们从业务合作伙伴或其他公司获得的信息（例如您在其他网站和应用程序上的活动）结合起来。
        13. 发现并防止欺诈、滥用或非法活动。
        个人数据的共享和披露
        1. 向其他数据控制者披露
        为实现上述目的，我公司将在同属7WIN.GAME链的公司内共享您的个人信息。
        我们还会在您同意的情况下向其他第三方数据控制者披露您的个人信息（如果适用法律有要求），或者为了向您提供任何服务（例如第三方集成）。第三方数据控制者也可能使用服务来收集和处理您的个人信息。如果您使用与企业域名关联的电子邮件地址访问 7WIN.GAME 服务，我们公司可能会向该企业提供您的个人信息。
        2.出于预防欺诈和安全考虑而披露信息
        我们将向帮助我们运营业务的公司披露个人信息，以检测、防止或解决欺诈、欺骗、非法活动、滥用 7WIN.GAME 服务以及安全或技术问题。
        此外，如果公司善意地认为访问、使用、保存或披露信息对于检测、防止或避免欺诈或欺诈活动是合理必要的，或者是非法的、滥用服务和软件，或者存在安全或技术问题，或在法律法规允许的合理必要范围内，为保护员工、7WIN.GAME用户、儿童或公众的权利、财产或安全，我公司将向境外的其他公司、组织、政府机构或个人披露个人信息我们。
        3. 向数据处理者披露
        出于上述目的，我们还将向代表我们处理个人信息的公司披露您的个人信息，以协助我们开展业务。这些公司包括客户支持服务提供商（包括记录或存储通信的提供商）、分析技术提供商（包括会话重建合作伙伴）、支付处理服务、欺诈监控。预防、检测和安全可防止欺诈或非法活动或滥用我们的服务、电子邮件、社交媒体和其他营销平台以及服务提供商和主机。我们已按照适用法律的要求与这些公司签订协议，并要求这些公司根据这些隐私原则保护您的个人信息。
        4、其他数据披露
        我们还可能在以下情况下披露您的个人信息：
        ● 当您同意披露时；
        ● 如果我们真诚地认为我们需要根据传票、法院命令或其他适用法律或法律程序的要求提供信息，或者为了应对涉及死亡或严重人身伤害风险的紧急情况；
        ● 如果公司与另一家公司合并或被另一家公司收购，出售 7WIN.GAME 网站、应用程序或业务部门，或者如果公司的全部或大部分资产被另一家公司收购，您的信息可能会被披露给潜在的潜在客户。买家或公司监管机构。该公司的顾问和任何潜在购买者的顾问都将在资产中转移到新的所有权。
        ● 个人数据保护
        7WIN.GAME 使用管理、技术和物理保护措施来保护您的个人数据，同时考虑到个人数据及其处理的性质以及所造成的威胁。 7WIN.GAME 不断努力改进这些保护措施，以帮助确保您的个人数据安全。
        ● 7WIN.GAME网站的外部链接
        7WIN.GAME 网站可能提供其他网站的互联网链接。您也可以通过我们网站提供的链接点击访问其他网站。但是，我们的网站隐私政策不适用于链接网站。您必须参阅链接网站的隐私政策。
        ● 饼干
        当您访问 https://7WIN.GAME 以及 7WIN.GAME 品牌下的任何页面或网站时，7WIN.GAME 可能会在您的计算机上设置和访问 cookie。 Cookie 用于向我们的系统提供基本信息，以提供您请求的服务。您可以随时从您的互联网浏览器设置中删除 Cookie。
        ● 隐私政策的变更
        我们将来可能会对我们的隐私政策进行更改，但是，最新版本的政策将管辖我们对您个人数据的处理，并且将始终可供您使用。如果我们对本政策做出重大变更，我们将自行决定通过更新或电子邮件（如适用）通知您。通过继续访问或使用我们的服务，您同意受我们的隐私政策条款的约束。
        7WIN.GAME致力于保护您在线个人信息的隐私。如果您对我们对您个人信息的管理有任何疑问或意见，请联系游戏内客服。如果您对本隐私声明有任何疑问，您也可以通过游戏内客服向我们举报。
        我们24小时在线，为您解决问题。我们可以依法接受您的询问。我们可能会拒绝不合理或不符合法律要求的请求，包括非常不切实际、需要不成比例的技术努力或可能使我们面临纵容欺诈风险的请求。
    `,

    'login.tip.账号在其它地方被登录': '账号在其它地方被登录',
};