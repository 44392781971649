// @ts-nocheck



/**
   * Number工具
   */
 export let LobbyNumberUtils = /** @class */ (function () {
    function LobbyNumberUtils() {
    
    }
    /**
     * 随机值范围
     */
    LobbyNumberUtils.randomInt = function (min, max) {
        if (0 >= max - min)
            return 0;
        var a = max - min;
        return Math.floor(Math.random() * a) + min;
    };
    LobbyNumberUtils.inRange = function (a, min, max) {
        return min <= a && a <= max;
    };
    LobbyNumberUtils.div = function (n, d) {
        return Math.floor(n / d);
    };
    LobbyNumberUtils.getZeroTimeStamp = function () {
        var date = new Date();
        var dateStr = date.getUTCFullYear() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCDate();
        return new Date(dateStr).getTime() / 1000;
    };
    LobbyNumberUtils.getTimeStamp = function () {
        var c = new Date().getTime() / 1000;
        return parseInt(c.toString());
    };
    LobbyNumberUtils.getAngle = function (px1, py1, px2, py2) {
        //两点的x、y值
        var x = px2 - px1;
        var y = py2 - py1;
        var hypotenuse = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        //斜边长度
        var cos = x / hypotenuse;
        var radian = Math.acos(cos);
        //求出弧度
        var angle = 180 / (Math.PI / radian);
        //用弧度算出角度        
        if (y < 0) {
            angle = -angle;
        }
        else if ((y == 0) && (x < 0)) {
            angle = 180;
        }
        return angle;
    };
    LobbyNumberUtils.getCurrencyUnitByLen = function (len) {
        var str = "";
        switch (len) {
            case 2:
                str = "百";
                break;
            case 3:
                str = "千";
                break;
            case 4:
                str = "万";
                break;
            case 5:
                str = "十万";
                break;
            case 6:
                str = "百万";
                break;
            case 7:
                str = "千万";
                break;
            case 8:
                str = "亿";
                break;
            case 9:
                str = "十亿";
                break;
            case 10:
                str = "百亿";
                break;
            case 11:
                str = "千亿";
                break;
            case 12:
                str = "兆";
                break;
        }
        return str;
    };
    return LobbyNumberUtils;
}());

