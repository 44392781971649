import { getGameList,getNotLoginGameList } from '@/api/home';
import { getConfig,base64EncodeUnicode,base64DecodeUnicode } from '@/utils/function';
import { ref } from 'vue';
import store from '@/store/index';

var mstore:any = store;
var game_list_timer:any = ref(null);

// 判断code
export function getGameListCode() {
    let { country,appKey } = getConfig();
    
    let game_list_info = JSON.parse(base64DecodeUnicode(localStorage.getItem('game_list_info') as string));
    
    getGameList({
        app_key: appKey,
        code: country,
        size: 50,
        version: game_list_info?.version ? game_list_info?.version : '0'
    }).then((res:any) => {
        console.log('判断游戏列表Code', res.data.data);
        let { list,code,version } = res.data.data;

        if(game_list_info?.code !== code){
            console.log('变轨了');
            localStorage.setItem('game_list_info',base64EncodeUnicode(JSON.stringify({code,version:'0'})));
            mstore.commit('game/setGameList',[]);
        }

        localStorage.setItem('game_list_info',base64EncodeUnicode(JSON.stringify({code,version})));

        if(list.length > 0){
            let game_list = mstore.state.game.game_list;
            if(game_list){
                game_list.push(...list);
                mstore.commit('game/setGameList',game_list);
            }else{
                mstore.commit('game/setGameList',list);
            };
        };

        getGameListHandle();

        if(game_list_timer.value){ 
            clearInterval(game_list_timer.value);
            game_list_timer.value = null;
        };
        game_list_timer.value = setInterval(()=>{
            getGameListHandleLoop();
        },1000 * 60 * 5);
    });
}

// 正常大厅拉取游戏列表
export function getGameListHandle() {
    let { country,appKey } = getConfig();
    
    let game_list_info = JSON.parse(base64DecodeUnicode(localStorage.getItem('game_list_info') as string));
    
    getGameList({
        app_key: appKey,
        code: game_list_info?.code ? game_list_info?.code : country,
        size: 50,
        version: game_list_info?.version ? game_list_info?.version : '0'
    }).then((res:any) => {
        console.log('正常：获取游戏列表', res.data.data);

        let { list,code,version } = res.data.data;
        localStorage.setItem('game_list_info',base64EncodeUnicode(JSON.stringify({code,version})));
 
        let game_list = mstore.state.game.game_list;
        if(game_list){
            game_list.push(...list);
            mstore.commit('game/setGameList',game_list);
        }else{
            mstore.commit('game/setGameList',list);
        }

        if(list.length > 0){
            getGameListHandle();
        };
    });
}

// 轮询获取游戏列表
export function getGameListHandleLoop() {
    let { country,appKey } = getConfig();
    
    let game_list_info = JSON.parse(base64DecodeUnicode(localStorage.getItem('game_list_info') as string));
    
    getGameList({
        app_key: appKey,
        code: country,
        size: 50,
        version: game_list_info?.version ? game_list_info?.version : '0'
    }).then((res:any) => {
        console.log('轮询：获取游戏列表', res.data.data);
        let { list,code,version } = res.data.data;

        if(game_list_info?.code !== code || game_list_info?.code !== country){
            console.log('变轨了');
            localStorage.setItem('game_list_info',base64EncodeUnicode(JSON.stringify({code,version:'0'})));
            mstore.commit('game/setGameList',[]);
            getGameListHandle();
            return;
        }

        localStorage.setItem('game_list_info',base64EncodeUnicode(JSON.stringify({code,version})));
        
        let game_list = mstore.state.game.game_list;
        if(game_list){
            game_list.push(...list);
            let new_game_list = game_list.map((itemA:any) => {
                const itemB = list.find((item:any) => item.game_id === itemA.game_id);
                return itemB ? itemB : itemA;
            });
            mstore.commit('game/setGameList',new_game_list);
        }else{
            mstore.commit('game/setGameList',list);
        }
    });
}



// 获取未登录的游戏列表
export function getUnLoginGameList() {
    let { country,appKey } = getConfig();
    
    getNotLoginGameList({
        app_key: appKey,
        code: country,
    }).then((res:any) => {
        mstore.commit('game/setGameList',res.data.data.list);
    });
}