export default {
    'notice.充值成功': 'Nạp tiền đã đến',
    'notice.领取奖励成功': 'Đã nhận thành công',
    'notice.您未参与才活动': 'Chưa tham gia sự kiện này',
    'notice.已经领取过了': 'Đã nhận rồi',
    'notice.提款申请成功，请等待': 'Lệnh rút tiền thành công, vui lòng đợi',
    'notice.密码错误': 'Sai mật khẩu',
    'notice.验证码已发送': 'Đã gửi mã xác minh',
    'notice.提款失败': 'Rút tiền thất bại',
    'notice.验证码有误或已过期': 'Mã xác minh không đúng hoặc hết hạn',
    'notice.该手机号已使用，请更换': 'Số điện thoại này đã được sử dụng, vui lòng thay đổi',
    'notice.您当前账号已绑定过手机号': 'Tài khoản hiện tại của bạn đã bị ràng buộc với số điện thoại',
    'notice.渠道维护请重新选择': 'Hãy chọn lại do kênh đang bảo trì',
    'notice.此充值方式不能参与活动': 'Phương thức nạp tiền này không thể tham gia sự kiện',
    'notice.小于此渠道最低充值金额': 'Ít hơn số tiền nạp tối thiểu của kênh này',
    'notice.中间件配置不存在禁止登录': 'Cấu hình trung gian kiện không tồn tại và việc đăng nhập bị cấm.',
    'notice.正在维护': 'Kênh {channel} đang bảo trì',
    'notice.领取新版注册送金币': 'Đã thành công nhận {amount} tiền vàng',
    'notice.领取新版注册送金币最后一次领取': '{amount} tiền vàng đã được nhận thành công. Sau khi bạn nhận được tất cả phần thưởng, sự kiện sẽ kết thúc.',
    'notice.appKey不一致禁止登录': 'AppKey không nhất quán cấm đăng nhập',
    'notice.账号在其它地方被登录': 'Tài khoản được đăng nhập ở nơi khác',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': 'Xin lỗi, do sai số thẻ ngân hàng hoặc kênh rút đang bảo trì gây ra rút tiền thất bại, hãy bạn vào game liên hệ với CSKH để kiểm tra',
    'notice.提款金额不能大于': 'Số tiền rút không thể lớn hơn {amount}',
    'notice.游戏暂未开放': 'Trò chơi vẫn chưa mở',
    'notice.账号被其它设备登录': 'Hệ thống phát hiện tài khoản của bạn có thể đã được đăng nhập trên nhiều thiết bị! Nếu bạn không biết điều này, hãy thay đổi mật khẩu tài khoản của bạn kịp thời!',
    'notice.该银行卡号绑定在其他账号中': 'Số thẻ ngân hàng này được gắn với các tài khoản khác'
};