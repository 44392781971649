import { computed } from 'vue';
import i18n from '@/locale/index';

const { t } = i18n.global;

export const menuNotice:any = computed(() => {
    return {
        '充值成功': t('notice.充值成功'),
        '领取奖励成功': t('notice.领取奖励成功'),
        '您未参与才活动': t('notice.您未参与才活动'),
        '已经领取过了': t('notice.已经领取过了'),
        '提款申请成功，请等待': t('notice.提款申请成功，请等待'),
        '密码错误': t('notice.密码错误'),
        '验证码已发送': t('notice.验证码已发送'),
        '提款失败': t('notice.提款失败'),
        '验证码有误或已过期': t('notice.验证码有误或已过期'),
        '该手机号已使用，请更换': t('notice.该手机号已使用，请更换'),
        '您当前账号已绑定过手机号': t('notice.您当前账号已绑定过手机号'),
        '渠道维护请重新选择': t('notice.渠道维护请重新选择'),
        '此充值方式不能参与活动': t('notice.此充值方式不能参与活动'),
        '小于此渠道最低充值金额': t('notice.小于此渠道最低充值金额'),
        '中间件配置不存在禁止登录': t('notice.中间件配置不存在禁止登录'),
        '正在维护': '-代码里在特殊处理-',
        '领取新版注册送金币': '-代码里在特殊处理-',
        '领取新版注册送金币最后一次领取': '-代码里在特殊处理-',
        'appKey不一致禁止登录': t('notice.appKey不一致禁止登录'),
        '账号在其它地方被登录': t('notice.账号在其它地方被登录'),
        "非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对": t('notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对'),
        '提款金额不能大于': '-代码里在特殊处理-',
        '游戏暂未开放': t('notice.游戏暂未开放'),
        '账号被其它设备登录': t('notice.账号被其它设备登录'),
        '该银行卡号绑定在其他账号中': t('notice.该银行卡号绑定在其他账号中'),
    }
});