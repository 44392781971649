const user = {
  namespaced: true,
  state () {
    return {
      userinfo: undefined,
      is_show_downapk: false,
      activityinfo: []
    }
  },
  mutations: {
    // 设置用户信息
    setUserInfo(state:any, userinfo:any) {
      state.userinfo = userinfo;
    },
    // 重置用户信息
    resetUserInfo(state:any) {
      state.userinfo = undefined;
    },

    // 设置是否显示下载APK条
    setIsShowDownApk(state:any, is_show_downapk:any) {
      state.is_show_downapk = is_show_downapk;
    },

    // 设置活动信息
    setActivityInfo(state:any, activityinfo:any) {
      state.activityinfo = activityinfo;
    },
    // ID取活动信息
    getActivityInfoById(state:any, id:any) {
      return state.activityinfo.find((item:any) => item.id === id);
    }
  },
}

export default user;