export default {
  'activity.活动': 'Hoạt động',
  'activity.立即参与': 'Tham gia ngay',
  'activity.活动时间': 'Thời gian sự kiện',
  'activity.活动未开启': 'Sự kiện chưa bắt đầu',



  'activity.activity1.标题': 'ƯU ĐÃI TÂN THỦ',
  'activity.activity1.描述': 'LÊN TỚI',
  'activity.activity1.全部': 'TẶNG',
  'activity.activity1.活动描述': 'Nạp tiền để tặng lên tới {rate}%',



  'activity.activity2.活动描述': 'Trở thành đại lý!Tháng kiếm hàng triệu!',



  'activity.activity3.标题': 'Điểm danh',
  'activity.activity3.描述': 'Rút bao nhiêu tặng bấy nhiêu',
  'activity.activity3.活动描述': 'Điểm danh mỗi ngày nhận quà liên tục',



  'activity.activity4.标题': 'Trở thành VIP Nhận Thưởng Lớn',
  'activity.activity4.活动描述': 'VIP nhận hàng triệu quà tặng',



  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': 'Thưởng bind bank',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': 'Thưởng đăng ký',
  'activity.coinname.12006': 'Thưởng TG',
  'activity.coinname.12007': 'Thưởng vượt ải',
  'activity.coinname.13001': 'Đại lý mời cấp dưới',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': 'Rút Thăm',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': 'Thưởng điểm danh',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': 'Thưởng Thẻ Phúc',
  'activity.coinname.90001': 'Thưởng điểm danh free',
  'activity.coinname.90002': 'Thưởng nạp',
  'activity.coinname.90003': 'Jackpot Rewards',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': 'Ưu đãi tân thủ',
  'activity.coinname.20001': 'Quay lại người chơi cũ',
  'activity.coinname.20002': 'Thưởng phiên bản cập nhật',
  'activity.coinname.13002': 'Hoa hồng đại lý',
  'activity.coinname.13003': 'vip trả lại tiền',
  'activity.coinname.chips': 'Số dư',
  'activity.coinname.rechargeChips': 'Số dư nạp'
}