import axios from 'axios';



var url = process.env.VUE_APP_GAME_AMIDDLE_BASEURL;



let instance = axios.create({
    baseURL: url
})

instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;