export default {
  'daily_checkin.popup.标题': 'Check-in Harian',
  'daily_checkin.popup.描述': 'Berapa yang Anda undi, itulah yang Anda dapatkan',
  'daily_checkin.popup.按钮': 'Hasil Undian',

  'daily_checkin.标题': 'Check-in Harian',
  'daily_checkin.今日奖励': 'Hadiah Hari Ini',
  'daily_checkin.领取': 'Klaim',
  'daily_checkin.免费': 'Gratis',
  'daily_checkin.免费描述': 'Dapatkan koin check-in gratis keesokan hari setelah memenuhi omset hari ini sebesar {amount}',
  'daily_checkin.第一天': 'HARI1',
  'daily_checkin.第二天': 'HARI2',
  'daily_checkin.第三天': 'HARI3',
  'daily_checkin.第四天': 'HARI4',
  'daily_checkin.第五天': 'HARI5',
  'daily_checkin.第六天': 'HARI6',
  'daily_checkin.第七天': 'HARI7',
  'daily_checkin.充值': 'Isi Ulang',
  'daily_checkin.动态描述': 'Tidak ada persyaratan omset untuk penarikan',
  'daily_checkin.选择充值方式': 'Pilih Metode Isi Ulang',
  'daily_checkin.下订单失败': 'Pesanan Gagal',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': 'Pesanan gagal, coba pembayaran jumlah besar atau saluran pembayaran lain',
  'daily_checkin.正在维护': 'Sedang dalam Pemeliharaan',
  'daily_checkin.领取成功': 'Berhasil Diklaim'
}
