export default {
  'activity.活动': 'กิจกรรม',
  'activity.立即参与': 'เข้าร่วมทันที',
  'activity.活动时间': 'เวลาของกิจกรรม',
  'activity.活动未开启': 'กิจกรรมยังไม่เริ่ม',

  'activity.activity1.标题': 'ข้อเสนอพิเศษสำหรับผู้ใช้ใหม่',
  'activity.activity1.描述': 'สูงสุด',
  'activity.activity1.全部': 'โบนัส',
  'activity.activity1.活动描述': 'เติมเงินรับโบนัสสูงสุดถึง {rate}%',

  'activity.activity2.活动描述': 'เป็นตัวแทน! รับรายได้หลักล้านต่อเดือน!',

  'activity.activity3.标题': 'เช็คอินรายวัน',
  'activity.activity3.描述': 'จับได้เท่าไรให้เท่านั้น',
  'activity.activity3.活动描述': 'เช็คอินรายวันพร้อมรับของรางวัลไม่หยุด',

  'activity.activity4.标题': 'เป็น VIP รับรางวัลใหญ่',
  'activity.activity4.活动描述': 'สมาชิก VIP รับของขวัญหรูมูลค่าหลายล้าน',

  'activity.coinname.10001': '10001',
  'activity.coinname.11001': '11001',
  'activity.coinname.12001': 'เหรียญผูกกับบัตรธนาคาร',
  'activity.coinname.12002': '12002',
  'activity.coinname.12003': '12003',
  'activity.coinname.12004': 'VIP',
  'activity.coinname.12005': 'เหรียญลงทะเบียน',
  'activity.coinname.12006': 'เหรียญ TG',
  'activity.coinname.12007': 'เหรียญท้าทาย',
  'activity.coinname.13001': 'การแนะนำตัวแทน',
  'activity.coinname.13005': '13005',
  'activity.coinname.13004': 'ลอตเตอรี่',
  'activity.coinname.13006': '13006',
  'activity.coinname.13007': '13007',
  'activity.coinname.13008': '13008',
  'activity.coinname.13009': '13009',
  'activity.coinname.13010': '13010',
  'activity.coinname.13011': 'เหรียญเช็คอิน',
  'activity.coinname.11002': '11002',
  'activity.coinname.13012': 'เหรียญกิจกรรมบัตรนำโชค',
  'activity.coinname.90001': 'เหรียญเช็คอินฟรี',
  'activity.coinname.90002': 'เหรียญโบนัสเติมเงิน',
  'activity.coinname.90003': 'รางวัลแจ็คพอต',
  'activity.coinname.13013': '13013',
  'activity.coinname.13014': 'ข้อเสนอพิเศษสำหรับผู้ใช้ใหม่',
  'activity.coinname.20001': 'ผู้ใช้เก่ากลับมา',
  'activity.coinname.20002': 'รางวัลเวอร์ชันอัปเดต',
  'activity.coinname.13002': 'ค่าคอมมิชชั่นตัวแทน',
  'activity.coinname.13003': 'วีไอพีคืนเงินเหรียญทอง',
  'activity.coinname.chips': 'ยอดคงเหลือ',
  'activity.coinname.rechargeChips': 'ยอดคงเหลือจากการเติมเงิน',
}
