export default {
    'notice.充值成功': 'Recharge Successful',
    'notice.领取奖励成功': 'Claim Successful',
    'notice.您未参与才活动': 'Not Participating in This Event',
    'notice.已经领取过了': 'Already Claimed',
    'notice.提款申请成功，请等待': 'Your withdrawal request has been submitted! It is being processed, please wait patiently!',
    'notice.密码错误': 'Incorrect Password',
    'notice.验证码已发送': 'Verification Code Sent',
    'notice.提款失败': 'Withdrawal Failed',
    'notice.验证码有误或已过期': 'Verification Code Incorrect or Expired',
    'notice.该手机号已使用，请更换': 'This Phone Number Is Already in Use, Please Change',
    'notice.您当前账号已绑定过手机号': 'Your Current Account Has Already Been Linked to a Phone Number',
    'notice.渠道维护请重新选择': 'Channel Maintenance, Please Re-select',
    'notice.此充值方式不能参与活动': 'This Recharge Method Cannot Participate in the Event',
    'notice.小于此渠道最低充值金额': 'Less Than the Minimum Recharge Amount for This Channel',
    'notice.中间件配置不存在禁止登录': 'Login Prohibited',
    'notice.正在维护': 'Channel {channel} is Under Maintenance',
    'notice.领取新版注册送金币': 'Successfully Claimed {amount} Coins',
    'notice.领取新版注册送金币最后一次领取': 'Successfully Claimed {amount} Coins, You Have Claimed All Rewards, the Event Will Close',
    'notice.appKey不一致禁止登录': 'Login Prohibited',
    'notice.账号在其它地方被登录': 'The account has been logged in on another device',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': 'We are very sorry, your withdrawal request failed. It may be due to incorrect card number or channel maintenance. Please contact customer service for verification.',
    'notice.提款金额不能大于': 'The withdrawal amount cannot exceed {amount}',
    'notice.游戏暂未开放': 'The game is not open yet',
    'notice.账号被其它设备登录': 'The system has detected that your account may be logged in on multiple devices! If you are unaware of this, please change your account password promptly!',
    'notice.该银行卡号绑定在其他账号中': 'The bank card number is linked to another account'
};
