export default {
  'daily_checkin.popup.标题': 'Daily Sign-in',
  'daily_checkin.popup.描述': 'Get as much as you draw',
  'daily_checkin.popup.按钮': 'Draw Prize',

  'daily_checkin.标题': 'Daily Sign-in',
  'daily_checkin.今日奖励': 'Today Reward',
  'daily_checkin.领取': 'Claim',
  'daily_checkin.免费': 'Free',
  'daily_checkin.免费描述': 'Reach a turnover of {amount} today to receive free Rewards for the next day',
  'daily_checkin.第一天': 'DAY 1',
  'daily_checkin.第二天': 'DAY 2',
  'daily_checkin.第三天': 'DAY 3',
  'daily_checkin.第四天': 'DAY 4',
  'daily_checkin.第五天': 'DAY 5',
  'daily_checkin.第六天': 'DAY 6',
  'daily_checkin.第七天': 'DAY 7',
  'daily_checkin.充值': 'Recharge',
  'daily_checkin.动态描述': 'No requirement for withdrawal',
  'daily_checkin.选择充值方式': 'Choose Recharge Method',
  'daily_checkin.下订单失败': 'Order Failed',
  'daily_checkin.下订单失败请尝试大额支付或其他支付通道': 'Order failed, please try a larger payment or another payment method',
  'daily_checkin.正在维护': 'Under Maintenance',
 'daily_checkin.领取成功': 'Successfully claimed'
}
