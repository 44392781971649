export default {
  'register_gift.popup.标题': 'GREAT BOUNUS FOR EVERY DESPOSIT',
  'register_gift.popup.描述': 'UP TO',
  'register_gift.popup.详情': 'Detail',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': 'Released Coin Withdrawals With No Wagering Requirements',
  'register_gift.popup.赠送': 'Bonus',
  'register_gift.popup.立即充值': 'Deposit Now',
  'register_gift.popup.首次充值': '1st Deposit',
  'register_gift.popup.二次充值': '2nd Deposit',
  'register_gift.popup.三次充值': '3rd Deposit',
  'register_gift.popup.四次充值': '4th Deposit',

  'register_gift.标题': 'GREAT BOUNUS FOR EVERY DESPOSIT',
  'register_gift.首次充值': '1st Deposit',
  'register_gift.送全部注册金币': 'Bonus:{amount} Banalce of Newcomer Bonus',
  'register_gift.赠送金额': 'Bonus',
  'register_gift.玩法': 'How to play',
  'register_gift.玩法规则1': '1. Deposit any amount to receive {rate}% of The Newcomer Bonus balance',
  'register_gift.玩法规则1.1': '1.Receive {rate}% of deposit amount upon a successful deposit',
  'register_gift.玩法规则2': '2.After deposit, the bonus will not be usable until it is release with the Deposit Wagering',
  'register_gift.玩法规则3': '3.How To Release: Deposit Wagering',
  'register_gift.玩法规则4': '4.Released Coin Withdrawals With No Wagering Requirements',
  'register_gift.举例': 'Example',
  'register_gift.赠送金币为': 'Bonus',
  'register_gift.当前充值金流水': 'Deposit Wagering',
  'register_gift.领取解锁金币后将重新计算': 'Recalculate After Releasing',
  'register_gift.可解锁金币': 'Claim',
  'register_gift.领取后赠送金币余额': 'Bonus Balance After Releasing',
  'register_gift.立即充值': 'Deposit Now',

  'register_gift.锁定金币总余额': 'Unreleased Coins:',
  'register_gift.充值金流水': 'Deposit Wagering',
  'register_gift.锁定金币': 'Unreleased',
  'register_gift.速率': 'Releasing Rate',
  'register_gift.已领取金币': 'Claimed',
  'register_gift.可领取金币': 'Available to Claim',
  'register_gift.领取金币': 'Claim',
  'register_gift.首充赠送金币': '1st Deposit Bonus',
  'register_gift.二充赠送金币': '2nd Deposit Bonus',
  'register_gift.三充赠送金币': '3rd Deposit Bonus',
  'register_gift.四充赠送金币': '4th Deposit Bonus',

  'register_gift.第x次充值': 'The {num}th Recharge',
  'register_gift.送充值金额': 'Bonus: Deposit Amount',
  'register_gift.充值金额': 'Deposit Amount',

  'register_gift.恭喜你已获得所有新手奖励': 'You have received all the rewards',
  'register_gift.如何玩': 'How to play',
  'register_gift.充值金币流水可解锁赠送金币': 'Bonus will not be usable until it is released with Deposit Wagering',
  'register_gift.解锁金币计算公式': 'How To Unlock The Coins',
  'register_gift.充值金流水*当前解锁金币速率': 'Deposit WageringxRelease Rate',
  'register_gift.解锁速率': 'Releasing Rate',
  'register_gift.解锁优先级': 'Unlock Priority',
  'register_gift.查看规则': 'View Rules',
  'register_gift.充值金流水越高,解锁金币越多': 'The Higher Deposit Wagering, The More Coins You Release!',

  'register_gift.header.锁定金额': 'Unreleased',
  'register_gift.header.可解锁': 'Claim',
  'register_gift.header.领取': 'Claim',

}