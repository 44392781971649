import header from "./VN/header";
import navbar from "./VN/navbar";
import login from "./VN/login";
import home from "./VN/home";
import mine from "./VN/mine";
import acting from "./VN/acting";
import wallet from "./VN/wallet";
import activity from "./VN/activity";
import register_gift from "./VN/register_gift";
import daily_checkin from "./VN/daily_checkin";
import newcomer_gift from "./VN/newcomer_gift";
import notice from "./VN/notice";
import public_components from "./VN/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
