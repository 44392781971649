import { getJsUrl,getConfig,saveConfig } from '@/utils/function';
import { getCountry } from "@/api/user";
import { createI18n } from 'vue-i18n';
import CN from './CN';
import US from './US';
import VN from './VN';
import TH from './TH';
import PT from './PT';
import ID from './ID';

export enum LOCALE_VALUES {
    CN = 'CN',
    US = 'US',
    VN = 'VN',
    TH = 'TH',
    PT = 'PT',
    ID = 'ID'
};

var defaultLocale:any = 'US';



function determineLanguage() {
    return new Promise(async(resolve) => {
        if(getJsUrl(location.href)?.language){
            localStorage.setItem('language', getJsUrl(location.href).language);
            resolve(getJsUrl(location.href).language);
        }else{
            if(localStorage.getItem('language')){
                const language = localStorage.getItem('language') as string;
                resolve(language);
            }else if(getConfig()?.language) {
                const country = getConfig()?.country;
                defaultLocale = country;
                resolve(country);
            }else{
                await getCountry().then((res: any) => {
                    saveConfig(res.data.data.country);
                    const country = getConfig()?.country;
                    defaultLocale = country;
                    resolve(country);
                });
            }
        }
    });
};



const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: LOCALE_VALUES.US,
    allowComposition: true,
    messages: {
        CN: process.env.VUE_APP_CUSTOM_ENV === 'production' ? US : CN,
        US,
        VN,
        TH,
        PT,
        ID
    }
});

determineLanguage().then((language) => {
    i18n.global.locale = language;
});

export default i18n;