export default {
  'home.minbanner.activity1.标题': 'Oferta Especial para Novos Usuários',
  'home.minbanner.activity1.描述': 'Até',
  'home.minbanner.activity1.全部': 'Oferecido',
  'home.minbanner.activity3.标题': 'Check-in Diário',
  'home.minbanner.activity3.描述': 'O quanto você sacar, você receberá',
  'home.minbanner.activity4.标题': 'Seja VIP e Ganhe Grandes Prêmios',

  'home.game.lobby': 'Todos',
  'home.game.hot': 'Popular',
  'home.game.slots': 'Slots',
  'home.game.BY': 'Pesca',
  'home.game.casino': 'Jogos de Poker',
  'home.game.SX': 'Cassino ao Vivo',
  'home.game.TY': 'Esportes',
  'home.game.NEW': 'Novo',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': 'Ver Todos',

  'home.exitdownpopup.提示': 'Aviso',
  'home.exitdownpopup.内容': 'Querido jogador, devido a problemas de rede, suas moedas {coin} na plataforma {platform} ainda não foram processadas. Por favor, aguarde um pouco.',
  'home.exitdownpopup.联系客服': 'Fale com o Suporte',

  'home.share.加入我们的社区': 'Junte-se à nossa comunidade',

  'home.icons.支持的充值平台': 'Plataformas de Recarga Suportadas',
  'home.icons.赞助和博彩责任': 'Parceiros',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': 'Com uma abordagem centrada no jogador, o 7win.game consegue satisfazer milhões de apostadores em todo o mundo. O 7win.game prioriza sua comunidade, garantindo uma experiência de jogo duradoura e continuamente divertida. O uso e acesso ao 7win.game indica que você entende e concorda plenamente em estar legalmente vinculado aos Termos de Serviço e à Política de Jogo Responsável.',

  'home.selectsession.门槛': 'Limite',
  'home.selectsession.初级场': 'Iniciante',
  'home.selectsession.中级场': 'Intermediário',
  'home.selectsession.高级场': 'Avançado',
  'home.selectsession.精英场': 'Elite',
  'home.selectsession.余额不足': 'Saldo Insuficiente',
  'home.selectsession.只有充值金才能玩': 'Somente saldo de recarga pode ser usado',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': 'Seu saldo de recarga não atende ao limite de entrada desta sessão',
  'home.selectsession.当前场次条件是xx': 'As condições da sessão atual são {min}-{max}, você não atende aos requisitos!',
  'home.selectsession.当前余额不足': 'Seu saldo é insuficiente, recarregue',
}
