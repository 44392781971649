import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN'
import { LightVars, DarkVars } from '@/config/CssVar';



const setting:any = {
  // @ts-ignore
  namespaced: true,
  state () {
    return {
      isDark: true,
      language: 'CN'
    }
  },
  mutations: {
    // 设置主题
    setTheme (state:any, value: boolean) {
      state.isDark = value
      const html = document.documentElement
      const vars = value ? DarkVars : LightVars
      Object.entries(vars).forEach(([ key, value ]) => {
        html.style.setProperty(key, value)
      })
    },
    // 设置语言
    setLanguage (state:any, value: string) {
      state.language = value
      console.log('设置语言')
    },
    // 根据仓库的语言设置vant组件库的语言
    setVantLanguage (state:any) {
      console.log('设置vant语言')
      if (state.language === 'CN') {
        Locale.use('zh-CN', zhCN);
      } else {
        Locale.use('en-US', enUS);
      }
    }
  }
}

export default setting