import axios from 'axios';
import md5 from 'md5';
import { webSocketInit } from "@/utils/imHandle";
import { getImei,getConfig,getLoginInfo,getZoneInfo,base64EncodeUnicode,base64DecodeUnicode } from "@/utils/function";
import { showToast,showLoadingToast,closeToast } from "vant";
import { ref } from 'vue';
import store from '@/store/index';
import { socketClose } from '@/utils/im';
import i18n from '@/locale/index';



const { t } = i18n.global;
const current_login_num = ref(1);
const mstore:any = store;



// 登录
export const onLogin = () => {
    showLoadingToast({
        message: '',
        forbidClick: true,
        duration: 0
    });

    let is_user = true;

    let { gameid,zoneid,phoneAreaCode,login_url } = getConfig();

    let data = {};

    let user_account = base64DecodeUnicode(localStorage.getItem('user_account') as string);

    let imei = getImei();

    

    if(user_account){   // 用户登录信息，初始化登录
        console.log('用户登录');
        user_account = JSON.parse(base64DecodeUnicode(localStorage.getItem('user_account') as string));

        let msg = {
            account: phoneAreaCode + `${current_login_num.value === 1 ? '0' : ''}` + user_account.account,
            password: user_account.password,
            gameid: gameid,
            imei: imei,
            platid: 131
        };

        data = {
            do: "Pmd.UserRequestPlatTokenByPasswordLoginUserPmd_C",
            data: msg,
            gameid: gameid,
            zoneid: zoneid,
        };
    }else{    // 游客信息，初始化登录
        console.log('游客登录');

        is_user = false;

        data = {
            do: "plat-token-login",
            data: {
                platinfo: {
                    account: null,
                    cacheToken: false,
                    imei: imei,
                    osname: "_",
                    platid: 0,
                    uid: 'guest' + imei,
                }
            },
            gameid: gameid,
            zoneid: zoneid
        }
    }

    console.log('我的IMEI',imei);

    axios.post(login_url + `?imei=${imei}`,JSON.stringify(data)).then((res:any)=>{
        console.log('登录',res);

        if(is_user){
            if(res.data.errno != 0){
                if(current_login_num.value === 1){
                    current_login_num.value = 2;
                    onLogin();
                }else{
                    current_login_num.value = 1;
                    clearUserInfo();
                    showToast(t('login.toast.密码不正确或者用户不存在'));
                }
                return;
            };
        }
        
        localStorage.setItem('login_info',base64EncodeUnicode(JSON.stringify(res.data.data)));
        onLoginZone();
    }).catch(()=>{
        clearUserInfo();
        closeToast();
    });
}

// 登录区服
export const onLoginZone = () => {
    let { gameid,zoneid,login_url } = getConfig();
    let { uid,platlogin,unigame_plat_login,platkey,unigame_plat_key } = getLoginInfo();
    let time = Math.floor(new Date().getTime() / 1000);

    let data = {
        // 默认值 操作类型
        "do": "request-select-zone",
        "data": {},
        // uid 获取密匙接口返回的 uid
        "uid": uid.toString(),
        // 大厅id
        "gameid": gameid,
        // 区服id
        "zoneid": zoneid,
        // 获取密匙接口返回的 
        "unigame_plat_login": platlogin ? platlogin : unigame_plat_login,
        // 当前时间戳
        "unigame_plat_timestamp": time
    }

    let md5key = md5(JSON.stringify(data) + time.toString() + (platkey ? platkey : unigame_plat_key));
    let url = login_url + "?unigame_plat_sign=" + md5key + "&do=" + data.do;

    axios.post(url,JSON.stringify(data)).then((res:any)=>{
        console.log('登录区服',res);
        localStorage.setItem('zone_info',base64EncodeUnicode(JSON.stringify(res.data.data)));
        onCheckLoginInfo();
    }).catch(()=>{
        clearUserInfo();
        closeToast();
    });
}

// 验证登录信息
export const onCheckLoginInfo = () => {
    let { gameid,zoneid } = getConfig();
    let { uid,platlogin,unigame_plat_login,platkey,unigame_plat_key } = getLoginInfo();
    let { gatewayurl } = getZoneInfo();
    let time = Math.floor(new Date().getTime() / 1000);

    let data = {
        // 默认值 操作类型
        "do": "Pmd.WebSocketForwardUserPmd_C",
        "data": {
            "accountid": uid.toString(),
        },
        // uid 获取密匙接口返回的 uid
        "uid": uid.toString(),
        // 大厅id
        "gameid": gameid,
        // 区服id
        "zoneid": zoneid,
        // 获取密匙接口返回的 
        "unigame_plat_login": platlogin ? platlogin : unigame_plat_login,
        // 当前时间戳
        "unigame_plat_timestamp": time,
    }

    let md5key = md5(JSON.stringify(data) + time.toString() + (platkey ? platkey : unigame_plat_key));
    let url = gatewayurl + "?unigame_plat_sign=" + md5key + "&do=" + data.do;

    axios.post(url,JSON.stringify(data)).then((res:any)=>{
        console.log('验证登录信息',res);
        localStorage.setItem('login_check_info',base64EncodeUnicode(JSON.stringify(res.data.data)));
        webSocketInit();
        closeToast();
    }).catch(()=>{
        clearUserInfo();
        closeToast();
    });
}

// 清空用户信息
export const clearUserInfo = () => {
    console.log('清空用户信息,进入游客模式');
    store.commit('user/resetUserInfo');
    store.commit('game/setPlayGameUrl');
    localStorage.removeItem('user_account');
    localStorage.removeItem('login_info');
    localStorage.removeItem('zone_info');
    localStorage.removeItem('login_check_info');
    localStorage.removeItem('p_number');
    closeToast();
    // localStorage.removeItem('uniimei');
    // onLogin();
}


