import header from "./TH/header";
import navbar from "./TH/navbar";
import login from "./TH/login";
import home from "./TH/home";
import mine from "./TH/mine";
import acting from "./TH/acting";
import wallet from "./TH/wallet";
import activity from "./TH/activity";
import register_gift from "./TH/register_gift";
import daily_checkin from "./TH/daily_checkin";
import newcomer_gift from "./TH/newcomer_gift";
import notice from "./TH/notice";
import public_components from "./TH/public_components";


export default {
  ...header,
  ...navbar,
  ...login,
  ...home,
  ...mine,
  ...acting,
  ...activity,
  ...register_gift,
  ...daily_checkin,
  ...newcomer_gift,
  ...public_components,

  ...wallet,
  ...notice
};
